import React from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  InlineNotification,
  Grid,
  Row,
  Column,
  AccordionItem,
  InlineLoading,
} from "carbon-components-react";
import { Download32 } from "@carbon/icons-react";
import { DownloadButton, CustomAccordion as Accordion } from "./styles";
import { CustomColumn, CustomInput } from "../styles";
import { formatDate, formatValue } from "../../functions/helpers";
import { Fields } from "../../helpers/variables";
import {
  handleGetRequest,
  handlePostRequest,
} from "../../helpers/handle-response";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  "@media(max-width: 959px)": {
    root: {
      marginTop: 40,
    },
  },
});
const DialogTitleCustom = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

export const Modal = ({ open, handleOpen, picked, allItems, currentUser }) => {
  const [downloadedFiles, setDownloadedFiles] = React.useState({
    0: false,
    1: false,
    2: false,
  });
  const [status, setStatus] = React.useState({ msg: "", state: "" });
  const [loaders, setLoaders] = React.useState({
    0: false,
    1: false,
    2: false,
    all: false,
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const submit = React.useCallback(
    (downloadedFiles) => {
      const Response = {
        DnlFile1: downloadedFiles[0],
        DnlFile2: downloadedFiles[1],
        DnlFile3: downloadedFiles[2],
        UpdateAck: new Date(),
        UserResponse: "",
      };

      handlePostRequest(
        "https://apiaccountant.plc.hr/api/r?m=RadniciUpdateAck",
        [
          {
            user: "igor",
            reqID: Number(picked.ID),
            ack: JSON.stringify(Response),
          },
        ]
      )
        .then((res) => {
          if (res.data === "True") {
            setStatus({ msg: "Preuzimanje potvrđeno", state: "success" });
            setTimeout(() => {
              setStatus({ msg: "", state: "" });
            }, 2000);
          }
        })
        .catch((err) => console.log(err));
    },
    [picked]
  );

  const downloadFiles = React.useCallback(
    (url, name, num) => {
      setLoaders((loaders) => ({ ...loaders, [num]: true }));

      handleGetRequest(url, currentUser.token, "blob")
        .then((res) => {
          fileDownload(res.data, name);

          setDownloadedFiles((downloadedFiles) => ({
            ...downloadedFiles,
            [num]: true,
          }));
          setLoaders((loaders) => ({ ...loaders, [num]: false }));
          submit({
            ...downloadedFiles,
            [num]: true,
          });
        })
        .catch((err) => console.log(err));
    },
    [currentUser, submit, downloadedFiles]
  );
  const downloadAllFiles = () => {
    setLoaders((loaders) => ({ ...loaders, all: true }));

    axios
      .post(
        `https://www.api.accountant.hr/zip/get-files`,
        {
          fileNames: [
            `${picked.response.File1Ref}`,
            `${picked.response.File2Ref}`,
            `${picked.response.File3Ref}`,
          ],
          zipName: "Prijava_radnika_",
          mjesec: picked.info.Ime,
          godina: picked.info.Prezime,
        },
        {
          headers: {
            Authorization: `JWT ${currentUser.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          axios
            .get(`https://www.api.accountant.hr/private/${res.data.filename}`, {
              headers: {
                Authorization: `JWT ${currentUser.token}`,
              },
              responseType: "arraybuffer",
            })
            .then((response) => {
              console.log(response);
              fileDownload(response.data, res.data.filename);

              setDownloadedFiles({
                0: true,
                1: true,
                2: true,
              });
              setLoaders((loaders) => ({ ...loaders, all: false }));

              submit({
                0: true,
                1: true,
                2: true,
              });
            })
            .catch((err) => console.log(err));
        }
      });
  };

  React.useEffect(() => {
    console.log(picked);
    picked &&
      setDownloadedFiles({
        0: picked.ack.DnlFile1,
        1: picked.ack.DnlFile2,
        2: picked.ack.DnlFile3,
      });
  }, [picked, allItems]);

  return (
    <>
      {picked && picked.hasOwnProperty("info") ? (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleOpen}
          maxWidth="lg"
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitleCustom id="responsive-dialog-title" onClose={handleOpen}>
            {currentUser.companyName}
          </DialogTitleCustom>
          <DialogContent>
            <h5>Zahtjev za prijavu radnika: {picked.Radnik}</h5>
            <h5>
              Datum početka rada: {formatDate(picked.info.DatumPocetkaRada)}
            </h5>
            <br />

            <Grid style={{ marginTop: 20, maxWidth: 750 }}>
              <Row>
                <Column>
                  <Accordion>
                    <AccordionItem title="Podaci o radniku i radnom mjestu">
                      <CustomColumn md={12} lg={12} sm={12}>
                        <h4>Osnovni podaci o radniku</h4>
                        <Grid>
                          <Row>
                            {Object.keys(picked.info).map((key, index) => {
                              if (
                                key === "RadnikID" ||
                                key === "UpdateTime" ||
                                key === "UpdateUser"
                              ) {
                              } else {
                                if (key === "IBANzasticeni") {
                                  return (
                                    <React.Fragment key={index}>
                                      <Column sm={6} lg={6}>
                                        <CustomInput
                                          autoComplete="off"
                                          label={Fields[key]}
                                          disabled
                                          type="text"
                                          variant="filled"
                                          defaultValue={formatValue(
                                            key,
                                            picked.info[key]
                                          )}
                                        />
                                      </Column>
                                      <Column sm={6} lg={12}>
                                        <br />
                                        <h4>Podaci o radnom mjestu</h4>
                                      </Column>
                                    </React.Fragment>
                                  );
                                } else {
                                  return (
                                    <Column sm={6} lg={6} key={index}>
                                      <CustomInput
                                        autoComplete="off"
                                        label={Fields[key]}
                                        disabled
                                        type="text"
                                        variant="filled"
                                        defaultValue={formatValue(
                                          key,
                                          picked.info[key]
                                        )}
                                      />
                                    </Column>
                                  );
                                }
                              }
                              return null;
                            })}
                          </Row>
                        </Grid>
                      </CustomColumn>
                    </AccordionItem>
                  </Accordion>
                </Column>
              </Row>
              <Row>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  <h4>Preuzimanje datoteka: </h4>
                </Column>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  <Button
                    name={"file1"}
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                    style={{ maxWidth: 200, backgroundColor: "#405E87" }}
                    startIcon={
                      loaders[0] ? null : (
                        <Download32
                          size={24}
                          style={{
                            width: "25px",
                            marginRight: "-3px",
                          }}
                          fill="white"
                        />
                      )
                    }
                    onClick={() =>
                      downloadFiles(
                        `https://www.api.accountant.hr/private/${picked.response.File1Ref.replace(
                          /\s/g,
                          ""
                        )}`,
                        picked.response.File1Ref,
                        0
                      )
                    }
                  >
                    {loaders[0] ? (
                      <InlineLoading
                        style={{ justifyContent: "center" }}
                        description="Preuzimanje..."
                        iconDescription="Active loading indicator"
                        status="active"
                        successDelay={1500}
                      />
                    ) : (
                      "Privremena prijava MO"
                    )}
                  </Button>
                </Column>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  <Button
                    name={"file2"}
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                    style={{ maxWidth: 200, backgroundColor: "#405E87" }}
                    startIcon={
                      loaders[1] ? null : (
                        <Download32
                          size={24}
                          style={{
                            width: "25px",
                            marginRight: "-3px",
                          }}
                          fill="white"
                        />
                      )
                    }
                    onClick={() =>
                      downloadFiles(
                        `https://www.api.accountant.hr/private/${picked.response.File2Ref.replace(
                          /\s/g,
                          ""
                        )}`,
                        picked.response.File2Ref,
                        1
                      )
                    }
                  >
                    {loaders[1] ? (
                      <InlineLoading
                        style={{ justifyContent: "center" }}
                        description="Preuzimanje..."
                        iconDescription="Active loading indicator"
                        status="active"
                        successDelay={1500}
                      />
                    ) : (
                      "Konačna prijava MO"
                    )}
                  </Button>
                </Column>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  <Button
                    name={"file3"}
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                    style={{ maxWidth: 200, backgroundColor: "#405E87" }}
                    startIcon={
                      loaders[2] ? null : (
                        <Download32
                          size={24}
                          style={{
                            width: "25px",
                            marginRight: "-3px",
                          }}
                          fill="white"
                        />
                      )
                    }
                    onClick={() =>
                      downloadFiles(
                        `https://www.api.accountant.hr/private/${picked.response.File3Ref.replace(
                          /\s/g,
                          ""
                        )}`,
                        picked.response.File3Ref,
                        2
                      )
                    }
                  >
                    {loaders[2] ? (
                      <InlineLoading
                        style={{ justifyContent: "center" }}
                        description="Preuzimanje..."
                        iconDescription="Active loading indicator"
                        status="active"
                        successDelay={1500}
                      />
                    ) : (
                      "Konačna prijava ZO"
                    )}
                  </Button>
                </Column>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  <Button
                    name={"file3"}
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                    startIcon={
                      loaders.all ? null : (
                        <Download32
                          size={24}
                          style={{
                            width: "25px",
                            marginRight: "-3px",
                          }}
                          fill="white"
                        />
                      )
                    }
                    style={{
                      maxWidth: 200,
                      backgroundColor: "rgb(102, 144, 204)",
                    }}
                    onClick={() => downloadAllFiles()}
                  >
                    {loaders.all ? (
                      <InlineLoading
                        style={{ justifyContent: "center" }}
                        description="Preuzimanje..."
                        iconDescription="Active loading indicator"
                        status="active"
                        successDelay={1500}
                      />
                    ) : (
                      "Preuzmi sve"
                    )}
                  </Button>
                </Column>
                {/*  <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => submit()}
                  >
                    Potvrdite preuzimanje
                  </Button>
                </Column> */}
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  {status.msg.length > 1 ? (
                    <InlineNotification
                      style={{ maxWidth: 400 }}
                      lowContrast
                      hideCloseButton={false}
                      iconDescription="describes the close button"
                      kind={status.state}
                      notificationType="inline"
                      onCloseButtonClick={() =>
                        setStatus({ msg: "", state: "" })
                      }
                      role="alert"
                      statusIconDescription="describes the status icon"
                      title={status.msg}
                    />
                  ) : null}
                </Column>
              </Row>
            </Grid>
          </DialogContent>
          <DialogActions>
            <DownloadButton
              variant="contained"
              onClick={handleOpen}
              color="primary"
            >
              Zatvori
            </DownloadButton>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};
