import React from "react";
import { useQuery } from "react-query";
import { Grid, Row, DataTableSkeleton, Column } from "carbon-components-react";
import { Modal } from "./Modal";
import { sleep } from "../../functions/helpers";
import { handlePostRequest } from "../../helpers/handle-response";
import ApplicationTable from "../ApplicationTable";
import { CardTitle } from "../styles";

const AdminApplicationReview = ({ currentUser, isClient }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);
  // const [data, setData] = React.useState([]);
  // const [organizations, setOrganizations] = React.useState({});
  // const [isFetched, setIsFetched] = React.useState(false);
  const [picked, setPicked] = React.useState({});
  const [flag, setFlag] = React.useState(0);

  const invalidate = (queryName) => {
    setFlag(queryName * Math.random());
  };
  const { data, isLoading } = useQuery(
    ["applications", flag],
    async () => {
      const {
        data,
      } = await handlePostRequest(
        `https://apiaccountant.plc.hr/api/r?m=RadniciAllGets`,
        [{ user: "igor" }]
      );
      const formattedData = JSON.parse(data);

      const formatData = formattedData
        .sort((a, b) => {
          return (
            new Date(b.info.DatumPocetkaRada) -
            new Date(a.info.DatumPocetkaRada)
          );
        })
        .map((item) => {
          return { ...item, Radnik: item.info.Ime + " " + item.info.Prezime };
        });
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );
  const { data: organizations } = useQuery(
    "organizationsApplications",
    async () => {
      await sleep(200);
      const {
        data: orgData,
      } = await handlePostRequest(
        "https://apiaccountant.plc.hr/api/p?m=GetOrganizations",
        [{ user: "igor" }]
      );
      const formattedData = JSON.parse(orgData);

      return data.map((item) => {
        return { ...item, OrgName: formattedData[item.OrganizationID].Naziv };
      });
    },

    {
      enabled: data,
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );
  return (
    <>
      <Grid>
        <Row lg={6} xs={12}>
          <Column>
            <CardTitle maxwidth="320px" variant="h4">
              Pregled prijava zaposlenika
            </CardTitle>
          </Column>
        </Row>
      </Grid>
      <Grid>
        <Row>
          {!isLoading && data && organizations ? (
            <ApplicationTable
              currentUser={currentUser}
              data={organizations}
              setPicked={setPicked}
              handleOpen={handleOpen}
            />
          ) : (
            <div style={{ width: "100%" }}>
              <DataTableSkeleton showToolbar={false} showHeader={false} />
            </div>
          )}
        </Row>
      </Grid>
      {open && picked && organizations && (
        <Modal
          isClient={currentUser && isClient}
          currentUser={currentUser}
          open={open}
          invalidate={invalidate}
          handleOpen={handleOpen}
          picked={picked}
          allItems={organizations}
        ></Modal>
      )}
    </>
  );
};

export default AdminApplicationReview;
