import styled from "styled-components";
import { Column, FormGroup } from "carbon-components-react";
import { TextField, Select, InputLabel } from "@material-ui/core";

export const CustomColumn = styled(Column)`
  background-color: #334d7b;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgb(177, 177, 177);
  border-radius: 5px;
  margin-bottom: 5px !important;
  padding: 15px;
  color: white;
  .MuiIconButton-label {
    svg {
      fill: white;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[name="Bruto1placa"],
  input[name="Prijevoz"] {
    font-family: "IBM Plex Mono", "Menlo", "DejaVu Sans Mono",
      "Bitstream Vera Sans Mono", Courier, monospace;
  }

  .MuiFormLabel-root.Mui-focused {
    color: white;
  }

  label {
    color: white;
  }
  input {
    color: white;
  }
  & > div {
    padding: 0 10px;
  }
  @media (max-width: 770px) {
    margin: 0 25px;
    & > div > div {
      margin: 10px 0;
    }
  }
  .MuiInputLabel-shrink {
    transform: translate(12px, 10px) scale(0.75) !important;
    padding-left: 0 !important;
  }
  .bx--fieldset {
    margin-bottom: 5px;
  }
`;
export const CustomInput = styled(TextField)`
  margin-top: $spacing-03;
  margin: 5px !important;
  width: 100%;
  .MuiInputAdornment-positionEnd {
    .MuiTypography-colorTextSecondary {
      color: white;
    }
  }
`;
export const Error = styled.p`
  margin: 5px 0;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: rgb(244, 67, 54);
`;
export const CustomFormGroup = styled(FormGroup)`
  color: white;
  font-size: 18px;
  padding: 5px 5px;
  .bx--radio-button-group {
    margin-left: 10px;
  }
  .bx--radio-button__label {
    font-size: 16px;
  }

  .bx--radio-button__appearance {
    border-color: white !important;
    &:checked {
      border-color: white !important;
    }
    &::before {
      background-color: white !important;
    }
  }
  .bx--radio-button:focus
    + .bx--radio-button__label
    .bx--radio-button__appearance {
    box-shadow: 0 0 0 1.5px #fff;
    outline: 1px solid transparent;
  }
`;
export const CustomSelect = styled(Select)`
  width: 100%;
  & > div {
    color: white;
  }
  svg {
    fill: white;
  }
`;
export const CustomInputLabel = styled(InputLabel)`
  padding-left: 12px !important;
`;
