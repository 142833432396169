import React from "react";
import { useQuery } from "react-query";
import { Grid, Row, Column, DataTableSkeleton } from "carbon-components-react";
import { sleep } from "../../functions/helpers";
import PdvTable from "../PdvTable";

import { CardTitle } from "../styles";

import {
  handlePostRequest,
  //handleGetRequest,
} from "../../helpers/handle-response";

import "./styles.scss";

const AdminVirmanReview = ({ currentUser }) => {
  const { data, /* isLoading */ } = useQuery(
    "pdvsAdmin",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        "https://apiaccountant.plc.hr/api/v?m=PlacanjaVirmaniAllGets",
        [{ user: "igor" }]
      );
      const parsedData = JSON.parse(data);
      return parsedData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );
  const { data: organizations } = useQuery(
    "organizationsPDV",
    async () => {
      await sleep(200);
      const {
        data: orgData,
      } = await handlePostRequest(
        "https://apiaccountant.plc.hr/api/p?m=GetOrganizations",
        [{ user: "igor" }]
      );
      const parsedData = JSON.parse(orgData);
      return data.map((item) => {
        return { ...item, OrgName: parsedData[item.OrganizationID].Naziv };
      });
    },

    {
      enabled: data,
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );

  return (
    <Grid style={{ maxWidth: 1250 }}>
      <Row>
        <Column>
          <CardTitle variant="h4">Dugovanja</CardTitle>
        </Column>
      </Row>

      <Grid helpercolor="white">
        {organizations ? (
          <PdvTable currentUser={currentUser} data={organizations} />
        ) : (
          <DataTableSkeleton showToolbar={false} showHeader={false} />
        )}
      </Grid>
    </Grid>
  );
};

export default AdminVirmanReview;
