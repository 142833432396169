import React from "react";
import { Button as Btn } from "carbon-components-react";
import styled from "styled-components";
import { AddAlt32, Search32 } from "@carbon/icons-react";

/* function goTo(history, location) {
  return history.push(location);
} */

export const PlaceButtons = ({ history, currentUser, size }) => (
  <>
    {currentUser && currentUser.isClient && (
      <ButtonWrapper>
        {/* <div>
          <AddAlt32 />
        </div> */}
        <Button
          onClick={() => {
            setTimeout(
              () => {
                history.push("/place-nova");
              },
              size.width < 500 ? 350 : 0
            );
          }}
          variant="contained"
        >
          <span>Novi obračun</span>
        </Button>
      </ButtonWrapper>
    )}
    <ButtonWrapper>
      {/*  <div>
        <Search32 />
      </div> */}
      <Button
        onClick={() => {
          setTimeout(
            () => {
              history.push("/place-pregled");
            },
            size.width < 500 ? 350 : 0
          );
        }}
        variant="contained"
      >
        <span>Pregled</span>
      </Button>
    </ButtonWrapper>
  </>
);

export const PrijavaButtons = ({ history, currentUser, size }) => (
  <>
    {currentUser && currentUser.isClient && (
      <>
        <ButtonWrapper>
          {/* <div>
            <AddAlt32 />
          </div> */}
          <Button
            onClick={() => {
              setTimeout(
                () => {
                  history.push("/prijava-nova");
                },
                size.width < 500 ? 350 : 0
              );
            }}
            variant="contained"
          >
            <span>Prijava radnika</span>
          </Button>
        </ButtonWrapper>
      </>
    )}
    <ButtonWrapper>
      {/*  <div>
        <Search32 />
      </div> */}
      <Button
        onClick={() => {
          setTimeout(
            () => {
              history.push("/prijava-pregled");
            },
            size.width < 500 ? 350 : 0
          );
        }}
        variant="contained"
      >
        <span>Pregled prijava</span>
      </Button>
    </ButtonWrapper>
  </>
);
export const OdjavaButtons = ({ history, currentUser, size }) => (
  <>
    {currentUser && currentUser.isClient && (
      <>
        <ButtonWrapper>
          {/* <div>
            <AddAlt32 />
          </div> */}
          <Button
            onClick={() => {
              setTimeout(
                () => {
                  history.push("/odjava");
                },
                size.width < 500 ? 350 : 0
              );
            }}
            variant="contained"
          >
            <span>Odjava radnika</span>
          </Button>
        </ButtonWrapper>
      </>
    )}
    <ButtonWrapper>
      {/*  <div>
        <Search32 />
      </div> */}
      <Button
        onClick={() => {
          setTimeout(
            () => {
              history.push("/odjava-pregled");
            },
            size.width < 500 ? 350 : 0
          );
        }}
        variant="contained"
      >
        <span>Pregled odjava</span>
      </Button>
    </ButtonWrapper>
  </>
);
export const PDV = ({ history, currentUser, size }) => (
  <>
    {currentUser && !currentUser.isClient && (
      <>
        <ButtonWrapper>
          {/* <div>
            <AddAlt32 />
          </div> */}
          <Button
            onClick={() => {
              setTimeout(
                () => {
                  history.push("/novo-dugovanje");
                },
                size.width < 500 ? 350 : 0
              );
            }}
            variant="contained"
          >
            <span>Novi unos</span>
          </Button>
        </ButtonWrapper>
      </>
    )}
    <ButtonWrapper>
      {/*  <div>
        <Search32 />
      </div> */}
      <Button
        onClick={() => {
          setTimeout(
            () => {
              history.push("/davanja-pregled");
            },
            size.width < 500 ? 350 : 0
          );
        }}
        variant="contained"
      >
        <span>Pregled podataka</span>
      </Button>{" "}
    </ButtonWrapper>
  </>
);

export const OtherInquiries = ({ history, currentUser, size }) => (
  <>
    {currentUser && currentUser.isClient && (
      <>
        <ButtonWrapper>
          {/* <div>
            <AddAlt32 />
          </div> */}
          <Button
            onClick={() => {
              setTimeout(
                () => {
                  history.push("/ostali-upiti");
                },
                size.width < 500 ? 350 : 0
              );
            }}
            variant="contained"
          >
            <span>Pošalji upit</span>
          </Button>
        </ButtonWrapper>
      </>
    )}
  </>
);

export const DrivingButtons = ({ history, currentUser, size }) => (
  <>
    {currentUser && currentUser.isClient ? (
      <>
        <ButtonWrapper>
          {/* <div>
            <AddAlt32 />
          </div> */}
          <Button
            onClick={() => {
              setTimeout(
                () => {
                  history.push("/loko-obracuni-novi");
                },
                size.width < 500 ? 350 : 0
              );
            }}
            variant="contained"
          >
            <span>Novi nalog</span>
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          {/*  <div>
            <Search32 />
          </div> */}
          <Button
            onClick={() => {
              setTimeout(
                () => {
                  history.push("/loko-pregled");
                },
                size.width < 500 ? 350 : 0
              );
            }}
            variant="contained"
          >
            <span>Pregled naloga</span>
          </Button>
        </ButtonWrapper>
      </>
    ) : (
      <>
        <ButtonWrapper>
          {/* <div>
            <AddAlt32 />
          </div> */}
          <Button
            onClick={() => {
              setTimeout(
                () => {
                  history.push("/loko-obracuni");
                },
                size.width < 500 ? 350 : 0
              );
            }}
            variant="contained"
          >
            <span>Upload datoteka</span>
          </Button>
        </ButtonWrapper>
      </>
    )}
  </>
);
export const TerenskiButtons = ({ history, currentUser, size }) => (
  <>
    {currentUser &&
    currentUser.isClient &&
    currentUser.CompanyOIB === 1886578177 ? (
      <ButtonWrapper>
        {/* <div>
          <AddAlt32 />
        </div> */}
        <Button
          onClick={() => {
            setTimeout(
              () => {
                history.push("/terenski-dodatak");
              },
              size.width < 500 ? 350 : 0
            );
          }}
          variant="contained"
        >
          <span>Novi obračun</span>
        </Button>
      </ButtonWrapper>
    ) : null}
  </>
);

// const Button = styled(Btn).attrs((props) => ({}))`
//   display: flex;
//   width: 200px;
//   transition: all 0.35s ease;
//   background: #2b5876;
//   position: relative;
//   border: 1px solid #2b5876;
//   flex-shrink: unset;
//   border-radius: 3px;
//   span {
//     position: relative;
//     z-index: 2;
//   }
//   &:hover {
//     background: #2b5876;
//     color: #fff;
//     border: 1px solid #2b5876;
//     box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
//       rgba(0, 0, 0, 0.22) 0px 10px 10px;
//   }
//   &:hover:after {
//     width: 100%;
//   }
//   &:after {
//     position: absolute;
//     content: "";
//     top: 0;
//     left: 0;
//     width: 0;
//     height: 100%;
//     background: linear-gradient(
//       to right,
//       #2b5876,
//       #3E85B5
//     );
//     transition: all 0.35s;
//   }
// `;
const Button = styled.button`
  width: 100%;
  border: 0;
  display: inline-block;
  padding: 0.7em 1.4em;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.15em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  color: #ffffff;
  background-color: #45649b;
  box-shadow: inset 0 -0.6em 0 -0.35em rgba(0, 0, 0, 0.17);
  text-align: center;
  position: relative;
  transition: all 0.2s ease-in-out;
  &:hover {
    outline: none;
    border: 0;
    box-shadow: none;
    transform: translateY(2px);
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  div {
    display: flex;
    align-content: center;
    align-self: center;
    margin-right: 10px;
  }
`;
