import React from "react";
import "./CustomHeader.scss";
import {
  Grid,
  Header,
  HeaderMenuButton,
  HeaderName,
  HeaderNavigation,
  HeaderMenuItem,
  HeaderMenu,
  HeaderGlobalBar,
  SideNavItems,
  HeaderSideNavItems,
} from "carbon-components-react";
import { Drawer } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useOutsideAlerter, useWindowSize } from "../../helpers/hooks";

const defaultMenuValues = {
  menu1: "false",
  menu2: "false",
  menu3: "false",
  prijava: "false",
};
export default function CustomHeader({ logout, currentUser }) {
  const [expanded, setExpanded] = React.useState(defaultMenuValues);
  const [isSideNavExpanded, setIsSideNavExpanded] = React.useState(false);
  const handleSideNav = () => {
    setIsSideNavExpanded(!isSideNavExpanded);
  };
  const size = useWindowSize();

  return (
    <div id="templates">
      <Grid>
        <Header className="Header" aria-label="IBM Platform Name">
          <HeaderMenuButton
            aria-label="Open menu"
            onClick={handleSideNav}
            isActive={isSideNavExpanded}
          />
          <HeaderName element={Link} to="/" prefix="Account">
            Ant
          </HeaderName>
          {currentUser && currentUser.CompanyOIB !== 1886578177 ? (
            <HeaderNavigation aria-label="AccountAnt">
              <CustomHeaderMenu
                currentUser={currentUser}
                expanded={expanded}
                setExpanded={setExpanded}
                otherMenu="prijava"
                links={
                  <ul aria-label="menu1" className="bx--header__menu">
                    <li>
                      {currentUser && currentUser.isClient && (
                        <Link
                          to="/place-nova"
                          onClick={() => setExpanded(!expanded)}
                          className="bx--header__menu-item"
                        >
                          <span className="bx--text-truncate--end">
                            Novi obračun
                          </span>
                        </Link>
                      )}
                    </li>
                    <li>
                      <Link
                        to="/place-pregled"
                        onClick={() => setExpanded(!expanded)}
                        className="bx--header__menu-item"
                      >
                        <span className="bx--text-truncate--end">Pregled</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/evidencija"
                        onClick={() => setExpanded(!expanded)}
                        className="bx--header__menu-item"
                      >
                        <span className="bx--text-truncate--end">
                          Evidencija (šihterica)
                        </span>
                      </Link>
                    </li>
                  </ul>
                }
                menuName="menu1"
                name="Plaće"
              />

              <CustomHeaderMenu
                expanded={expanded}
                currentUser={currentUser}
                setExpanded={setExpanded}
                otherMenu="menu1"
                links={
                  <ul aria-label="prijava" className="bx--header__menu">
                    {currentUser && currentUser.isClient && (
                      <>
                        <li>
                          <Link
                            to="/prijava-nova"
                            onClick={() => setExpanded(!expanded)}
                            className="bx--header__menu-item"
                          >
                            <span className="bx--text-truncate--end">
                              Prijava radnika
                            </span>
                          </Link>
                        </li>
                      </>
                    )}
                    <li>
                      <Link
                        to="/prijava-pregled"
                        onClick={() => setExpanded(!expanded)}
                        className="bx--header__menu-item"
                      >
                        <span className="bx--text-truncate--end">
                          Pregled prijava
                        </span>
                      </Link>
                    </li>
                    {currentUser && currentUser.isClient && (
                      <li>
                        <Link
                          to="/odjava"
                          onClick={() => setExpanded(!expanded)}
                          className="bx--header__menu-item"
                        >
                          <span className="bx--text-truncate--end">
                            Odjava radnika
                          </span>
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link
                        to="/odjava-pregled"
                        onClick={() => setExpanded(!expanded)}
                        className="bx--header__menu-item"
                      >
                        <span className="bx--text-truncate--end">
                          Pregled odjava
                        </span>
                      </Link>
                    </li>
                  </ul>
                }
                menuName="prijava"
                name="Prijava / Odjava"
              />
              <CustomHeaderMenu
                expanded={expanded}
                currentUser={currentUser}
                setExpanded={setExpanded}
                otherMenu="menu1"
                links={
                  <ul aria-label="prijava" className="bx--header__menu">
                    {currentUser && !currentUser.isClient && (
                      <>
                        <li>
                          <Link
                            to="/novo-dugovanje"
                            onClick={() => setExpanded(!expanded)}
                            className="bx--header__menu-item"
                          >
                            <span className="bx--text-truncate--end">
                              Novi unos
                            </span>
                          </Link>
                        </li>
                      </>
                    )}
                    <li>
                      <Link
                        to="/davanja-pregled"
                        onClick={() => setExpanded(!expanded)}
                        className="bx--header__menu-item"
                      >
                        <span className="bx--text-truncate--end">
                          Pregled dugovanja
                        </span>
                      </Link>
                    </li>
                  </ul>
                }
                menuName="dugovanja"
                name="PDV / Virman"
              />
              <CustomHeaderMenu
                expanded={expanded}
                currentUser={currentUser}
                setExpanded={setExpanded}
                otherMenu="menu1"
                links={
                  <ul aria-label="prijava" className="bx--header__menu">
                    {currentUser && currentUser.isClient && (
                      <>
                        <li>
                          <Link
                            to="/loko-obracuni-novi"
                            onClick={() => setExpanded(!expanded)}
                            className="bx--header__menu-item"
                          >
                            <span className="bx--text-truncate--end">
                              Novi nalog
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/loko-pregled"
                            onClick={() => setExpanded(!expanded)}
                            className="bx--header__menu-item"
                          >
                            <span className="bx--text-truncate--end">
                              Pregled naloga
                            </span>
                          </Link>
                        </li>
                      </>
                    )}
                    {currentUser && !currentUser.isClient && (
                      <li>
                        <Link
                          to="/loko-obracuni"
                          onClick={() => setExpanded(!expanded)}
                          className="bx--header__menu-item"
                        >
                          <span className="bx--text-truncate--end">
                            Upload datoteka
                          </span>
                        </Link>
                      </li>
                    )}
                  </ul>
                }
                menuName="nalozi"
                name="Putni nalozi"
              />
            </HeaderNavigation>
          ) : null}
          <HeaderGlobalBar>
            <HeaderNavigation className="customNav" aria-label="Notifications">
             {/*  {currentUser && currentUser.username ? (
                <Notifications
                  expanded={expanded}
                  setExpanded={setExpanded}
                  currentUser={currentUser}
                  menuName="menu2"
                  menuIcon={<Notification20 fill="white" />}
                ></Notifications>
              ) : null} */}

              {size.width < 800 ? (
                <AccountResponsive
                  currentUser={currentUser}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  logout={logout}
                  menuName="menu3"
                  menuIcon={<AccountCircleIcon fill="white" />}
                ></AccountResponsive>
              ) : (
                <Account
                  currentUser={currentUser}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  logout={logout}
                  menuName="menu3"
                  menuIcon={<AccountCircleIcon fill="white" />}
                ></Account>
              )}
            </HeaderNavigation>
          </HeaderGlobalBar>
          {/*  <SideNav
            aria-label="Side navigation"
            expanded={isSideNavExpanded}
            isPersistent={false}
          >
            <SideNavItems>
              <HeaderSideNavItems>
                <HeaderMenu aria-label="Plaće" menuLinkName="Plaće">
                  {currentUser && currentUser.isClient && (
                    <HeaderMenuItem
                      element={Link}
                      to="/place-nova"
                      onClick={handleSideNav}
                    >
                      Novi obračun
                    </HeaderMenuItem>
                  )}
                  <HeaderMenuItem
                    element={Link}
                    to="/place-pregled"
                    onClick={handleSideNav}
                  >
                    Pregled
                  </HeaderMenuItem>
                </HeaderMenu>
              </HeaderSideNavItems>
            </SideNavItems>
          </SideNav> */}
          <CustomDrawer
            anchor={"left"}
            open={isSideNavExpanded}
            onClose={handleSideNav}
            style={{ marginTop: 45 }}
          >
            <SideNavItems>
              <HeaderSideNavItems>
                <HeaderMenu aria-label="Plaće" menuLinkName="Plaće">
                  {currentUser && currentUser.isClient && (
                    <HeaderMenuItem
                      element={Link}
                      to="/place-nova"
                      onClick={handleSideNav}
                    >
                      Novi obračun
                    </HeaderMenuItem>
                  )}
                  <HeaderMenuItem
                    element={Link}
                    to="/place-pregled"
                    onClick={handleSideNav}
                  >
                    Pregled
                  </HeaderMenuItem>
                </HeaderMenu>
                <HeaderMenu aria-label="Prijava" menuLinkName="Prijava">
                  {currentUser && currentUser.isClient && (
                    <HeaderMenuItem
                      element={Link}
                      to="/prijava-nova"
                      onClick={handleSideNav}
                    >
                      Nova prijava
                    </HeaderMenuItem>
                  )}
                  <HeaderMenuItem
                    element={Link}
                    to="/prijava-pregled"
                    onClick={handleSideNav}
                  >
                    Pregled prijava
                  </HeaderMenuItem>
                </HeaderMenu>
                <HeaderMenu aria-label="Odjava" menuLinkName="Odjava">
                  {currentUser && currentUser.isClient && (
                    <>
                      <HeaderMenuItem
                        element={Link}
                        to="/odjava"
                        onClick={handleSideNav}
                      >
                        Nova odjava
                      </HeaderMenuItem>
                      <HeaderMenuItem
                        element={Link}
                        to="/odjava-pregled"
                        onClick={handleSideNav}
                      >
                        Pregled odjava
                      </HeaderMenuItem>
                    </>
                  )}
                </HeaderMenu>
              </HeaderSideNavItems>
            </SideNavItems>
          </CustomDrawer>
        </Header>
      </Grid>
    </div>
  );
}

const CustomHeaderMenu = React.forwardRef(
  ({ expanded, setExpanded, menuName, name, links }, ref) => {
    return (
      <>
        <li
          className="bx--header__submenu"
          onMouseEnter={() => {
            setExpanded({ ...defaultMenuValues, [menuName]: "true" });
          }}
          onMouseLeave={() => {
            setExpanded(defaultMenuValues);
          }}
        >
          <a
            aria-haspopup="menu"
            aria-expanded={expanded[menuName]}
            className="bx--header__menu-item bx--header__menu-title"
            href="#3"
            tabIndex="0"
            aria-label={menuName}
          >
            {name}
            <svg
              focusable="false"
              preserveAspectRatio="xMidYMid meet"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              aria-hidden="true"
              className="bx--header__menu-arrow"
              style={{ willChange: "transform" }}
            >
              <path d="M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z" />
            </svg>
          </a>
          {links}
        </li>
      </>
    );
  }
);
/* const Notifications = React.forwardRef(
  ({ expanded, setExpanded, menuName, menuIcon }, ref) => {
    return (
      <li
        className="bx--header__submenu"
        style={{ listStyle: "none" }}
        onMouseEnter={() => {
          setExpanded({ ...defaultMenuValues, [menuName]: "true" });
        }}
        onMouseLeave={() => {
          setExpanded(defaultMenuValues);
        }}
      >
        <BlankButton
          aria-haspopup="menu"
          aria-expanded={expanded[menuName]}
          className="bx--header__menu-item bx--header__menu-title"
          tabIndex="0"
          aria-label={menuName}
        >
          {menuIcon}
        </BlankButton>
        {
          //add notifications
          <ul aria-label={menuName} className="bx--header__menu customDropdown">
            <li>
              <a href="#3" className="bx--header__menu-item" tabIndex="0">
                <span className="bx--text-truncate--end">Sub-link 1</span>
              </a>
            </li>
          </ul>
        }
      </li>
    );
  }
); */
const Account = React.forwardRef(
  ({ expanded, setExpanded, menuName, menuIcon, logout, currentUser }, ref) => {
    return (
      <>
        <li
          className="bx--header__submenu"
          style={{ listStyle: "none" }}
          onMouseEnter={() => {
            setExpanded({ ...defaultMenuValues, [menuName]: "true" });
          }}
          onMouseLeave={() => {
            setExpanded(defaultMenuValues);
          }}
        >
          <BlankButton
            aria-haspopup="menu"
            aria-expanded={expanded[menuName]}
            className="bx--header__menu-item bx--header__menu-title"
            tabIndex="0"
            aria-label={menuName}
          >
            {menuIcon}
          </BlankButton>
          {
            <ul
              aria-label={menuName}
              className="bx--header__menu customDropdown"
            >
              <li>
                {currentUser && currentUser.username ? (
                  <BlankButton
                    className="bx--header__menu-item"
                    tabIndex="0"
                    style={{ width: "100%", textAlign: "left", padding: 10 }}
                    onClick={() => {
                      setExpanded(!expanded);
                      logout();
                    }}
                  >
                    Logout
                  </BlankButton>
                ) : (
                  <>
                    <Link
                      to="/login"
                      onClick={() => setExpanded(!expanded)}
                      className="bx--header__menu-item"
                    >
                      <span className="bx--text-truncate--end">Prijava</span>
                    </Link>
                    <Link
                      to="/register"
                      onClick={() => setExpanded(!expanded)}
                      className="bx--header__menu-item"
                    >
                      <span className="bx--text-truncate--end">
                        Registracija
                      </span>
                    </Link>
                  </>
                )}
              </li>
            </ul>
          }
        </li>
      </>
    );
  }
);
const AccountResponsive = React.forwardRef(
  ({ expanded, setExpanded, menuName, menuIcon, logout, currentUser }, ref) => {
    const menuItem = React.useRef(null);
    useOutsideAlerter(menuItem, () => {
      setExpanded({
        ...defaultMenuValues,
        [menuName]: "false",
      });
    });
    return (
      <>
        <li
          ref={menuItem}
          className="bx--header__submenu"
          style={{ listStyle: "none" }}
          onClick={() => {
            setExpanded({
              ...defaultMenuValues,
              [menuName]: expanded[menuName] === "true" ? "false" : "true",
            });
          }}
        >
          <BlankButton
            aria-haspopup="menu"
            aria-expanded={expanded[menuName]}
            className="bx--header__menu-item bx--header__menu-title"
            tabIndex="0"
            aria-label={menuName}
          >
            {menuIcon}
          </BlankButton>
          {
            <ul
              aria-label={menuName}
              className="bx--header__menu customDropdown"
            >
              <li>
                {currentUser && currentUser.username ? (
                  <BlankButton
                    className="bx--header__menu-item"
                    tabIndex="0"
                    style={{ width: "100%", textAlign: "left", padding: 10 }}
                    onClick={() => logout()}
                  >
                    Logout
                  </BlankButton>
                ) : (
                  <>
                    <Link
                      to="/login"
                      onClick={() => setExpanded(!expanded)}
                      className="bx--header__menu-item"
                    >
                      <span className="bx--text-truncate--end">Prijava</span>
                    </Link>
                    <Link
                      to="/register"
                      onClick={() => setExpanded(!expanded)}
                      className="bx--header__menu-item"
                    >
                      <span className="bx--text-truncate--end">
                        Registracija
                      </span>
                    </Link>
                  </>
                )}
              </li>
            </ul>
          }
        </li>
      </>
    );
  }
);
const BlankButton = styled.button`
  background: 0;
  border: unset;
  outline: 0;
  width: 50px;
  height: 100%;
`;
const CustomDrawer = styled(Drawer)`
  .MuiDrawer-paperAnchorLeft {
    margin-top: 48px !important;
    width: 220px;
    .bx--side-nav__items {
      padding-top: 0;
      .bx--header__submenu {
        height: 50px;
        a {
          color: black;
        }
        svg {
          fill: black;
        }
        .bx--header__menu .bx--header__menu-item {
          color: #f4f4f4;
        }
        a.bx--header__menu-item:hover {
          background-color: #2c2c2c;
          color: #f4f4f4;
          svg {
            fill: #f4f4f4;
          }
        }
        .bx--header__menu-title[aria-expanded="true"],
        .bx--header__menu-title[aria-expanded="true"] + .bx--header__menu {
          color: #f4f4f4;
          background-color: #1a2044;
          width: 100%;
          svg {
            fill: #f4f4f4;
          }
        }
      }
    }
  }
`;
