import React from "react";
import fileDownload from "js-file-download";
import TimesheetTable from "./TimesheetTable";
import BasicInfo from "./BasicInfo";
//import Modal from "./Modal";
import {
  Grid,
  Row,
  Column,
  InlineNotification,
  InlineLoading,
  Loading,
} from "carbon-components-react";
import { useFormContext } from "react-hook-form";
import dayjs from "dayjs";
import { getTimes, getTimesWithBreak, timeFormat, getUTC } from "./timeFunc";
import {
  Typography,
  Button,
  InputLabel,
  FormControl,
  Select,
} from "@material-ui/core";
import {
  valuesToChoose,
  choosenValuesEnum,
  numberOfDays,
  mjeseci,
} from "./constants/index";
import { TimeWrapper } from "../styles";
import style from "./WorkTimesheet.module.scss";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  DatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import hrLocale from "date-fns/locale/hr";
import { getTimeObject, formatTime } from "./helperFunc";
import { handlePostRequest } from "../../helpers/handle-response";
import { cloneDeep } from "lodash";
import { b64toBlob } from "../../functions/helpers";

import styled from "styled-components";

function addTimes(arr) {
  let hours = 0;
  let minutes = 0;
  arr.map((item) => {
    if (item === null || item === "" || item === undefined) {
      return 0;
    }
    hours += Number(item.substr(0, item.indexOf(":")));
    minutes += Number(item.substr(item.indexOf(":") + 1));
    if (minutes >= 60) {
      hours++;
      let mins = minutes - 60;
      minutes = mins;
    }
    return null;
  });
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}`;
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const useStyles = makeStyles({
  root: {
    maxWidth: 324,
    "& label.Mui-focused": {
      color: "#2F0743",
    },
    "& .MuiInput-underline:after": {},
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 4,
        backgroundClip: "padding-box",
        border: "solid 2px #ad5389",
      },
      "&:hover fieldset": {
        borderColor: "#592275",
      },
      "&.Mui-focused fieldset": {
        borderRadius: 4,
        backgroundClip: "padding-box",
        border: "solid 2px #ad5389",
      },
    },
    "& label": {
      color: "#3c1053",
    },
  },
});

const updateTime = (item, date, getShiterica, getValues) => {
  const addData = async () => {
    const result = await handlePostRequest(
      `https://apiaccountant.plc.hr/api/s?m=SihtericaItemAddEdit`,
      [{ user: "igor", si: item }]
    );

    if (result.data === "true") {
      getShiterica(getValues());
    }
  };

  addData();
};
const addNew = (daysCopy, date, currentItem, extraTimes) => {
  const items = daysCopy
    .map((item, index) => {
      delete item.id;
      delete item.alreadyAdded;
      for (let key in item) {
        if (
          item[key] === null &&
          key !== "SatiZastojFrom" &&
          key !== "SatiZastojTill"
        ) {
          item[key] = { h: 0, m: 0, s: 0 };
        } else if (typeof item[key] !== typeof new Date()) {
          if (key === "ID" || key === "SihtericaID") {
          } else if (
            key !== "SatiZastojFrom" &&
            key !== "SatiZastojTill" &&
            key !== "CreatedByIP" &&
            key !== "CreatedByUser"
          ) {
            const time = formatTime(item[key]);
            item[key] = time;
          }
        } else if (key !== "CreatedByIP" && key !== "CreatedByUser") {
          item[key] = getUTC(item[key], date);
        }
        if (
          item["SatiZastojFrom"] === "" ||
          item["SatiZastojTill"] === "" ||
          item["SatiZastojFrom"] === null ||
          item["SatiZastojTill"] === null
        ) {
          item["SatiZastojFrom"] = new Date(2000, 0, 1, 12, 0, 0);
          item["SatiZastojTill"] = new Date(2000, 0, 1, 12, 0, 0);
        }
        if (extraTimes.time !== null) {
          if (key !== "TerenskiRad" && key !== "SatiPripravnosti") {
            if (
              item["PocetakRada"].hasOwnProperty("h") ||
              item["ZavrsetakRada"].hasOwnProperty("h")
            ) {
              const day = extraTimes.time.getDate();
              const month = extraTimes.time.getMonth();
              item["PocetakRada"] = new Date(2000, month, day, 12, 0, 0);
              item["ZavrsetakRada"] = new Date(2000, month, day, 12, 0, 0);
            }
          }
        }
      }
      return {
        ...item,
        SihtericaID: currentItem,
        CreatedByIP: "",
        CreateTime: new Date(),
        CreatedByUser: "",
      };
    })
    .filter((item, index) => {
      if (index + 1 === date.getDate()) {
        return true;
      }
      return null;
    });
  return items;
};
const getPDF = (
  days,
  values,
  totalSum,
  setDownloading,
  holidaysTotal,
  date
) => {
  setDownloading({ state: "active", message: "Preuzimanje..." });
  const daysCopy = cloneDeep(days);
  const filteredDays = daysCopy.map((item) => {
    for (let key in item) {
      if (
        key === "PocetakRada" ||
        key === "ZavrsetakRada" ||
        key === "SatiZastojaFrom" ||
        key === "SatiZastojaTill"
      ) {
        if (item[key]) {
          if (getUTC(item[key], date).getFullYear() === 2000) {
            item[key] = null;
          } else {
            item[key] = getUTC(item[key], date);
          }
        }
      }
    }
    return item;
  });
  handlePostRequest(`https://pdf-api.accountant.hr/create-pdf`, [
    {
      name: "workTimesheet",
      days: filteredDays,
      values,
      totalSum,
      holidaysTotal,
    },
  ])
    .then((response) => {
      console.log(response);
      setDownloading({ state: "", message: "" });
      fileDownload(
        b64toBlob(response.data),
        `${values.ImePrezimeString}_${
          mjeseci[values.Mjesec]
        }_${values.Godina.getFullYear()}.pdf`
      );
    })
    .catch((err) => console.log(err));
};
const WorkTimesheet = ({
  currentUser,
  days,
  dispatch,
  currentItem,
  setCurrentItem,
  disableForm,
  date,
  setDate,
  monthYear,
  setMonthYear,
  createNew,
  reload,
  history,
}) => {
  const classes = useStyles();
  const [holidaysTotal, setHolidaysTotal] = React.useState();
  const [totalSum, setTotalSum] = React.useState();
  const [choosenValue, setChoosenValue] = React.useState("");
  const [flag, setFlag] = React.useState(0);

  const [times, setTimes] = React.useState({
    start: null,
    end: null,
    breakStart: null,
    breakEnd: null,
  });
  const [extraTimes, setExtraTimes] = React.useState({ value: "", time: null });
  const [extraValues, setExtraValues] = React.useState(valuesToChoose);
  const [disabledTimes, setDisabeledTimes] = React.useState(false);
  const [error, setError] = React.useState({ state: "", message: "" });
  const [sihtericaError, setSihtericaError] = React.useState({
    state: "",
    message: "",
  });
  const [sihtericaPageLoad, setSihtericaPageLoad] = React.useState(false);
  const [sending, setSending] = React.useState({ state: "", message: "" });

  const handleTimeChange = React.useCallback((name, value) => {
    setTimes((times) => ({ ...times, [name]: value }));
  }, []);
  const [downloading, setDownloading] = React.useState({
    state: "",
    message: "",
  });
  const { register, triggerValidation, getValues } = useFormContext();

  React.useEffect(() => {
    if (isValidDate(times.start) && isValidDate(times.end)) {
      setExtraValues(
        valuesToChoose.filter((item) => {
          if (item === "TerenskiRad" || item === "SatiPripravnosti") {
            return true;
          } else return false;
        })
      );
    }
    if (
      !isValidDate(times.start) ||
      !isValidDate(times.end) ||
      isValidDate(extraTimes.time)
    ) {
      setExtraValues(valuesToChoose);
    }
    if (extraTimes.value.length !== 0 && isValidDate(extraTimes.time)) {
      setExtraValues(
        valuesToChoose.filter((item) => {
          if (item === extraTimes.value) {
            return true;
          } else return false;
        })
      );
    }
    if (
      extraTimes.value === "TerenskiRad" ||
      extraTimes.value === "SatiPripravnosti" ||
      extraTimes.value === ""
    ) {
      setDisabeledTimes(false);
    } else setDisabeledTimes(true);
  }, [extraTimes, times]);

  React.useEffect(() => {
    setExtraTimes({ value: "", time: null });
    setDisabeledTimes(false);
  }, [date]);
  const handleSetTime = () => {
    if (extraTimes.time !== null) {
      console.log("add special time");
      if (!isValidDate(extraTimes.time)) {
        setError({ state: "error", message: "Uneseno vrijeme nije ispravno" });
        setTimeout(() => {
          setError({ state: "", message: "" });
        }, 3500);
      } else {
        const dayToAdd = days.find((item) => {
          if (Number(item.id) === date.getDate()) {
            return item;
          }
          return null;
        });

        if (dayToAdd) {
          let hasValue = false;
          for (let key in dayToAdd) {
            if (
              extraTimes.value === "TerenskiRad" ||
              extraTimes.value === "SatiPripravnosti"
            ) {
              if (
                key !== "TerenskiRad" &&
                key !== "SatiPripravnosti" &&
                key !== "id" &&
                key !== "ID" &&
                key !== "SihtericaID" &&
                key !== "PocetakRada" &&
                key !== "ZavrsetakRada" &&
                key !== "SatiZastojFrom" &&
                key !== "SatiZastojTill" &&
                key !== "CreatedByIP" &&
                key !== "CreateTime" &&
                key !== "CreatedByUser" &&
                key !== "UkupnoDnevnoRV" &&
                key !== "alreadyAdded" &&
                key !== "DanjiRedovanRad" &&
                key !== "DanjiBlagdanNeradni" &&
                key !== "DanjiPrekovremeni" &&
                key !== "NocniRedovanRad" &&
                key !== "NocniBlagdanNeradni" &&
                key !== "NocniPrekovremeni"
              ) {
                if (dayToAdd[key] !== null && dayToAdd[key] !== "00:00") {
                  hasValue = true;
                }
              }
            } else {
              if (
                key !== "id" &&
                key !== "ID" &&
                key !== "SihtericaID" &&
                key !== "CreatedByIP" &&
                key !== "CreateTime" &&
                key !== "CreatedByUser" &&
                key !== "alreadyAdded"
              ) {
                if (dayToAdd[key] !== null && dayToAdd[key] !== "00:00") {
                  hasValue = true;
                }
              }
            }
          }
          if (hasValue) {
            setError({
              state: "error",
              message: "Vrijeme za taj dan već uneseno",
            });
            setTimeout(() => {
              setError({ state: "", message: "" });
            }, 3500);
          } else {
            const time = {
              h: extraTimes.time.getHours(),
              m: extraTimes.time.getMinutes(),
              s: 0,
              time: timeFormat(
                extraTimes.time.getHours(),
                extraTimes.time.getMinutes()
              ),
            };

            if (
              extraTimes.value === "TerenskiRad" ||
              extraTimes.value === "SatiPripravnosti"
            ) {
              const day = date.getDate() - 1;
              const times = days[day].UkupnoDnevnoRV.split(":");
              const timesObj = { h: Number(times[0]), m: Number(times[1]) };

              if (time.h > timesObj.h || time.m > timesObj.m) {
                setError({
                  state: "error",
                  message: "Uneseno vrijeme veće od ukupnog radnog vremena",
                });
                setTimeout(() => {
                  setError({ state: "", message: "" });
                }, 2500);
              }
            } else {
              if (
                days[date.getDate() - 1].alreadyAdded &&
                days[date.getDate() - 1].alreadyAdded.flag
              ) {
                dispatch({
                  type: "SET_TIME",
                  id: date.getDate(),
                  stateOption: {
                    [extraTimes.value]: time.time,
                  },
                });
                setFlag(Math.random());
              } else {
                dispatch({
                  type: "SET_TIME",
                  id: date.getDate(),
                  stateOption: {
                    [extraTimes.value]: time.time,
                  },
                });
                setFlag(Math.random());
              }
            }
          }
        }
      }
    } else if (!isValidDate(times.start) || !isValidDate(times.end)) {
      setError({ state: "error", message: "Neki od datuma nije ispravan" });
      setTimeout(() => {
        setError({ state: "", message: "" });
      }, 3500);
    } else if (isValidDate(times.breakStart) && isValidDate(times.breakEnd)) {
      const result = getTimesWithBreak(
        dayjs(times.start).date(date.getDate()),
        dayjs(times.end).date(date.getDate()),
        dayjs(times.breakStart).date(date.getDate()),
        dayjs(times.breakEnd).date(date.getDate())
      );
      const stateOption = getTimeObject(
        dayjs(times.start).date(date.getDate()).date(),
        holidaysTotal,
        times,
        result,
        date,
        days
      );
      dispatch({
        type: "SET_TIME",
        id: date.getDate(),
        stateOption: {
          ...stateOption,
          PocetakRada: times.start,
          ZavrsetakRada: times.end,
          SatiZastojFrom: times.breakStart,
          SatiZastojTill: times.breakEnd,
        },
      });
      setFlag(Math.random());
    } else if (isValidDate(times.start) && isValidDate(times.end)) {
      const result = getTimes(
        dayjs(times.start).date(date.getDate()),
        dayjs(times.end).date(date.getDate())
      );
      const stateOption = getTimeObject(
        dayjs(times.start).date(date.getDate()).date(),
        holidaysTotal,
        times,
        result,
        date,
        days
      );

      dispatch({
        type: "SET_TIME",
        id: date.getDate(),
        stateOption: {
          ...stateOption,
          PocetakRada: times.start,
          ZavrsetakRada: times.end,
        },
      });
      setFlag(Math.random());
    }
  };
  const checkIfExists = (values) => {
    const getData = async () => {
      const result = await handlePostRequest(
        `https://apiaccountant.plc.hr/api/s?m=SihtericaGets`,
        [{ user: "igor", orgID: currentUser.orgID }]
      );
      const items = JSON.parse(result.data);
      const current = Object.keys(items)
        .map((key) => {
          return items[key];
        })
        .find((item) => {
          if (
            item.Godina === date.getFullYear() &&
            item.Mjesec === monthYear.getMonth() + 1 &&
            item.RadnikID === Number(values.ImePrezime)
          ) {
            return item;
          }
          return null;
        });

      if (current === undefined) {
        console.log("doesnt exists, can create new");
        setCurrentItem(0);
        handleSetTime();
      } else {
        if (currentItem > 0) {
          handleSetTime();
        } else {
          setCurrentItem(current);
          console.log(currentItem, current.ID, "exists, cannot create");
          setSihtericaError({
            state: "error",
            message:
              "Unos već postoji za radnika, za taj mjesec i godinu. Otvorite šihtericu iz liste šihterica",
          });
          setTimeout(() => {
            setSihtericaError({ state: "", message: "" });
          }, 2500);
        }
      }
    };
    getData();
    return flag;
  };
  const getShiterica = React.useCallback(
    (values, from = "add", flag = "none") => {
      const getData = async () => {
        const result = await handlePostRequest(
          `https://apiaccountant.plc.hr/api/s?m=SihtericaGets`,
          [{ user: "igor", orgID: currentUser.orgID }]
        );
        const items = JSON.parse(result.data);
        const current = Object.keys(items)
          .map((key) => {
            return items[key];
          })
          .find((item) => {
            if (
              item.Godina === date.getFullYear() &&
              item.Mjesec === date.getMonth() + 1 &&
              item.RadnikID === Number(values.ImePrezime)
            ) {
              return item;
            }
            return null;
          });

        if (current === undefined) {
          setSihtericaError({
            state: "error",
            message: `Šihterica ne postoji. Kliknite na Dodaj Novu`,
          });
          setSihtericaPageLoad(false);
          setCurrentItem(-1);
          setTimeout(() => {
            setSihtericaError({ state: "", message: "" });
          }, 2000);
        } else {
          setSihtericaError({ state: "", message: "" });
          setCurrentItem(current.ID);
          if (from === "add") {
            setSending({ state: "finished", message: "Poslano!" });
            setTimeout(() => {
              setSending({ state: "", message: "" });
            }, 1500);
          }
        }
      };

      getData();
    },
    [currentUser, date, setCurrentItem]
  );

  React.useEffect(() => {
    register("Godina");
    register("ImePrezimeString");
  }, [register]);

  const submitSihterica = React.useCallback(() => {
    setSending({ state: "active", message: "Slanje..." });

    const values = getValues();
    const daysCopy = cloneDeep(days);
    const item = daysCopy.find((item) => {
      if (new Date(item.PocetakRada).getDate() === date.getDate()) {
        return item;
      }
      return null;
    });
    console.log("what is id", item, currentItem);
    if (item && item.ID !== 0) {
      delete item.id;
      delete item.alreadyAdded;
      for (let key in item) {
        if (
          item[key] === null &&
          key !== "SatiZastojFrom" &&
          key !== "SatiZastojTill"
        ) {
          item[key] = { h: 0, m: 0, s: 0 };
        } else if (typeof item[key] !== typeof new Date()) {
          if (key === "ID" || key === "SihtericaID") {
          } else if (
            key !== "SatiZastojFrom" &&
            key !== "SatiZastojTill" &&
            key !== "CreatedByIP" &&
            key !== "CreatedByUser"
          ) {
            const time = formatTime(item[key]);
            item[key] = time;
          }
        } else if (key !== "CreatedByIP" && key !== "CreatedByUser") {
          item[key] = getUTC(item[key], date);
        }
        if (
          item["SatiZastojFrom"] === "" ||
          item["SatiZastojTill"] === "" ||
          item["SatiZastojFrom"] === null ||
          item["SatiZastojTill"] === null
        ) {
          item["SatiZastojFrom"] = new Date(2000, 0, 1, 12, 0, 0);
          item["SatiZastojTill"] = new Date(2000, 0, 1, 12, 0, 0);
        }
        if (
          item["PocetakRada"] === { h: 0, m: 0, s: 0 } ||
          item["ZavrsetakRada"] === { h: 0, m: 0, s: 0 }
        ) {
          const day = extraTimes.time.getDate();
          const month = extraTimes.time.getMonth();
          item["PocetakRada"] = new Date(2000, month, day, 12, 0, 0);
          item["ZavrsetakRada"] = new Date(2000, month, day, 12, 0, 0);
        }
      }

      updateTime(item, date, getShiterica, getValues);
    } else {
      const items = addNew(daysCopy, date, currentItem, extraTimes);
      const Sihterica = {
        ID: currentItem,
        OrganizationID: currentUser.orgID,
        OrganizationNaziv: currentUser.companyName,
        OrganizationOIB: String(currentUser.OIB),
        Godina: date.getFullYear(),
        Mjesec: date.getMonth() + 1,
        RadnikID: Number(values.ImePrezime),
        RadnikImePrezime: values.ImePrezimeString,
        Locked: 0,
        CreatedByIP: "",
        CreateTime: new Date(),
        CreatedByUser: "",
        items: items,
      };
      console.log("sihterica to add", Sihterica);
      const addData = async () => {
        const result = await handlePostRequest(
          `https://apiaccountant.plc.hr/api/s?m=SihtericaAddEdit`,
          [{ user: "igor", s: Sihterica }]
        );

        if (result.data === "True") {
          getShiterica(getValues());
        }
      };
      addData();
    }
  }, [
    currentItem,
    currentUser,
    date,
    days,
    extraTimes,
    getShiterica,
    getValues,
  ]);

  React.useEffect(() => {
    console.log("submit effect called");
    if (flag > 0) {
      submitSihterica();
      setFlag(0);
    } else {
      console.log("flag is 0");
    }
  }, [days, flag, submitSihterica]);

  return (
    <>
      <Typography
        style={{
          textAlign: "center",
          fontSize: 24,
          marginBottom: 20,
          color: "#616161",
        }}
      >
        Evidencija o radnom vremenu radnika u mjesecu
      </Typography>
      <Grid style={{ maxWidth: 1100 }}>
        <Row style={{ marginBottom: 20 }}>
          <Column lg={3}>
            <Button variant="contained" color="primary" onClick={createNew}>
              Nova sihterica
            </Button>
          </Column>
          {history.location.pathname === "/evidencija/pregled" && !reload && (
            <Column>
              <Button
                variant="contained"
                onClick={() => {
                  history.push("/evidencija");
                }}
              >
                Natrag
              </Button>
            </Column>
          )}
        </Row>
      </Grid>
      <main>
        <form>
          {/*  <button
              onClick={(e) => {
                handleSubmit((data) => console.log(data));
              }}
            >
              click
            </button>
            <button onClick={() => setOpen(!open)}>modal</button> */}

          <Grid fullWidth style={{ width: "100%", marginBottom: 20 }}>
            <Row>
              <Column>
                <BasicInfo
                  date={date}
                  setDate={setDate}
                  monthYear={monthYear}
                  setMonthYear={setMonthYear}
                  currentUser={currentUser}
                  disableForm={disableForm}
                />
              </Column>
            </Row>
          </Grid>
          <Grid style={{ width: "100%" }}>
            <Row>
              <Column align="center">
                {sihtericaError.message.length !== 0 && (
                  <InlineNotification
                    style={{ maxWidth: 400 }}
                    lowContrast
                    hideCloseButton={false}
                    iconDescription="describes the close button"
                    kind={sihtericaError.state}
                    notificationType="inline"
                    onCloseButtonClick={() =>
                      setSihtericaError({ message: "", state: "" })
                    }
                    role="alert"
                    statusIconDescription="describes the status icon"
                    title={sihtericaError.message}
                  />
                )}
              </Column>
            </Row>
          </Grid>

          <Grid style={{ maxWidth: 500 }}>
            <Row>
              <Column style={{ display: "flex", justifyContent: "center" }}>
                {downloading.state.length !== 0 ? (
                  <CInlineLoading
                    style={{ width: "unset" }}
                    description={downloading.message}
                    spancolor="black"
                    iconDescription="Active loading indicator"
                    status={downloading.state}
                    successDelay={1500}
                  />
                ) : (
                  <Button
                    variant="contained"
                    onClick={() =>
                      getPDF(
                        days,
                        getValues(),
                        totalSum,
                        setDownloading,
                        holidaysTotal,
                        date
                      )
                    }
                  >
                    Preuzmi PDF
                  </Button>
                )}
              </Column>
            </Row>
          </Grid>

          {sihtericaPageLoad ? (
            <Grid>
              <Row>
                <Column lg={12} align="center">
                  <Loading
                    description="Active loading indicator"
                    withOverlay={false}
                  />
                </Column>
                <Column lg={12} align="center">
                  Učitavanje
                </Column>
              </Row>
            </Grid>
          ) : (
            <>
              <section className={style.inputs}>
                <Grid fullWidth style={{ width: "100%" }}>
                  <Row>
                    <Column lg={6} align="center">
                      <div
                        className={
                          disableForm ? style.calendarDisabled : style.calendar
                        }
                      >
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={hrLocale}
                        >
                          <DatePicker
                            variant="static"
                            openTo="date"
                            value={date}
                            orientation="portrait"
                            onChange={(event) => {
                              if (
                                event.getMonth() !== date.getMonth() ||
                                event.getFullYear() !== date.getFullYear()
                              ) {
                                setMonthYear(event);
                              }
                              setDate(event);
                              setChoosenValue(
                                days[event.getDate() - 1].chosenValue
                              );
                            }}
                            classes={{
                              root: classes.root,
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </Column>
                    <Column lg={6}>
                      <TimeWrapper
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: 415,
                          width: "100%",
                          flexWrap: "wrap",
                          padding: 25,
                          boxShadow: "7px 4px 30px -10px rgba(0,0,0,0.75)",
                          borderRadius: 5,
                        }}
                      >
                        <FormControl variant="filled">
                          <InputLabel htmlFor="brojDana">
                            Dan u mjesecu
                          </InputLabel>
                          <Select
                            native
                            value={date.getDate() - 1}
                            onChange={(event) => {
                              setDate(
                                new Date(
                                  date.setDate(Number(event.target.value) + 1)
                                )
                              );
                              setChoosenValue(
                                days[event.target.value].chosenValue
                              );
                            }}
                            inputProps={{
                              name: "pickedDay",
                              id: "brojDana",
                            }}
                          >
                            {numberOfDays.map((item) => {
                              return (
                                <option key={item} value={item - 1}>
                                  {item}
                                </option>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <div style={{ display: "flex" }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                              inputVariant="filled"
                              variant="inline"
                              className={style.timePicker}
                              ampm={false}
                              disabled={disabledTimes}
                              invalidDateMessage="Neispravan format unosa"
                              label="Početak rada"
                              value={times.start}
                              onChange={(event) => {
                                /*  const date = event;
                    date.setMonth(monthsStringToNum[info2.month]);
                    date.setYear(info2.year.getFullYear());
                    console.log(date, event, info2, monthsStringToNum[info2.month], info2.year.getFullYear()); */
                                if (isValidDate(event)) {
                                  const newDate = new Date(date.getTime());
                                  newDate.setHours(event.getHours());
                                  newDate.setMinutes(event.getMinutes());
                                  newDate.setSeconds(0);
                                  newDate.setMilliseconds(0);

                                  handleTimeChange("start", newDate);
                                } else if (event === null) {
                                  handleTimeChange("start", null);
                                }
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          <ClearIcon
                            className={style.icon}
                            onClick={() => {
                              handleTimeChange("start", null);
                            }}
                          >
                            clear
                          </ClearIcon>
                        </div>

                        <div style={{ display: "flex" }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                              inputVariant="filled"
                              variant="inline"
                              label="Završetak rada"
                              className={style.timePicker}
                              disabled={disabledTimes}
                              ampm={false}
                              value={times.end}
                              invalidDateMessage={"Neispravan format unosa"}
                              onChange={(event) => {
                                console.log(event);
                                if (isValidDate(event)) {
                                  const newDate = new Date(date.getTime());
                                  newDate.setHours(event.getHours());
                                  newDate.setMinutes(event.getMinutes());
                                  newDate.setSeconds(0);
                                  newDate.setMilliseconds(0);
                                  handleTimeChange("end", newDate);
                                } else if (event === null) {
                                  handleTimeChange("end", null);
                                }
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          <ClearIcon
                            className={style.icon}
                            onClick={(event) => {
                              handleTimeChange("end", null);
                            }}
                          >
                            clear
                          </ClearIcon>
                        </div>
                        <div style={{ display: "flex" }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                              inputVariant="filled"
                              variant="inline"
                              disabled={disabledTimes}
                              className={style.timePicker}
                              label="Sati zastoja bez krivnje radnika - od"
                              ampm={false}
                              invalidDateMessage={"Neispravan format unosa"}
                              value={times.breakStart}
                              onChange={(event) => {
                                if (event !== null) {
                                  const newDate = new Date(date.getTime());
                                  newDate.setHours(event.getHours());
                                  newDate.setMinutes(event.getMinutes());
                                  newDate.setSeconds(0);
                                  newDate.setMilliseconds(0);
                                  handleTimeChange("breakStart", newDate);
                                } else if (event === null) {
                                  handleTimeChange("breakStart", null);
                                }
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          <ClearIcon
                            className={style.icon}
                            onClick={(event) => {
                              handleTimeChange("breakStart", null);
                            }}
                          >
                            clear
                          </ClearIcon>
                        </div>
                        <div style={{ display: "flex" }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                              inputVariant="filled"
                              variant="inline"
                              className={style.timePicker}
                              disabled={disabledTimes}
                              invalidDateMessage={"Neispravan format unosa"}
                              label="Sati zastoja bez krivnje radnika - do"
                              ampm={false}
                              value={times.breakEnd}
                              onChange={(event) => {
                                if (event !== null) {
                                  const newDate = new Date(date.getTime());
                                  newDate.setHours(event.getHours());
                                  newDate.setMinutes(event.getMinutes());
                                  newDate.setSeconds(0);
                                  newDate.setMilliseconds(0);
                                  handleTimeChange("breakEnd", newDate);
                                } else if (event === null) {
                                  handleTimeChange("breakEnd", null);
                                }
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          <ClearIcon
                            className={style.icon}
                            onClick={(event) => {
                              handleTimeChange("breakEnd", null);
                            }}
                          >
                            clear
                          </ClearIcon>
                        </div>
                        <FormControl
                          variant="outlined"
                          style={{ marginTop: 10 }}
                        >
                          <InputLabel htmlFor="izabranaVrijednost">
                            Ostale...
                          </InputLabel>
                          <Select
                            variant="filled"
                            native
                            value={extraTimes.value}
                            onChange={(event) => {
                              if (event.target.value === "") {
                                setExtraTimes({
                                  value: event.target.value,
                                  time: null,
                                });
                              } else {
                                setExtraTimes({
                                  ...extraTimes,
                                  value: event.target.value,
                                });
                              }
                            }}
                            inputProps={{
                              name: "chosenValue",
                              id: "izabranaVrijednost",
                            }}
                          >
                            <option value="" />

                            {extraValues.map((item) => {
                              return (
                                <option key={item} value={item}>
                                  {choosenValuesEnum[item]}
                                </option>
                              );
                            })}
                          </Select>
                        </FormControl>
                        {extraTimes.value.length !== 0 ? (
                          <div style={{ display: "flex" }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardTimePicker
                                inputVariant="filled"
                                variant="inline"
                                className={style.timePicker}
                                label={
                                  choosenValuesEnum[choosenValue] === undefined
                                    ? "Broj sati"
                                    : choosenValuesEnum[choosenValue] +
                                      " - broj sati"
                                }
                                ampm={false}
                                invalidDateMessage={"Neispravan format unosa"}
                                value={extraTimes.time}
                                onChange={(event) => {
                                  if (event !== null) {
                                    const newDate = new Date(date.getTime());
                                    newDate.setHours(event.getHours());
                                    newDate.setMinutes(event.getMinutes());
                                    newDate.setSeconds(0);
                                    newDate.setMilliseconds(0);
                                    setExtraTimes({
                                      ...extraTimes,
                                      time: newDate,
                                    });
                                  }
                                }}
                              />
                            </MuiPickersUtilsProvider>
                            <ClearIcon
                              className={style.icon}
                              onClick={(event) => {
                                setExtraTimes({
                                  ...extraTimes,
                                  time: null,
                                });
                              }}
                            >
                              clear
                            </ClearIcon>
                          </div>
                        ) : null}
                        <Grid>
                          <Row>
                            <Column>
                              {error.message.length !== 0 && (
                                <InlineNotification
                                  style={{ maxWidth: 400 }}
                                  lowContrast
                                  hideCloseButton={false}
                                  iconDescription="describes the close button"
                                  kind={error.state}
                                  notificationType="inline"
                                  onCloseButtonClick={() =>
                                    setError({ message: "", state: "" })
                                  }
                                  role="alert"
                                  statusIconDescription="describes the status icon"
                                  title={error.message}
                                />
                              )}
                            </Column>
                          </Row>
                        </Grid>
                        <div style={{ display: "flex", marginTop: 20 }}>
                          {sending.state.length > 0 ? (
                            <CInlineLoading
                              style={{ width: "unset" }}
                              description={sending.message}
                              spancolor="white"
                              iconDescription="Active loading indicator"
                              status={sending.state}
                              successDelay={1500}
                            />
                          ) : (
                            <>
                              <Button
                                className={style.saveBtn}
                                variant="contained"
                                style={{
                                  margin: "8px auto",
                                  width: 100,
                                }}
                                onClick={async () => {
                                  const result = await triggerValidation(
                                    "ImePrezime"
                                  );

                                  if (result) {
                                    /*  if (
                                      !isValidDate(times.start) ||
                                      !isValidDate(times.end)
                                    ) {
                                      setError({
                                        state: "error",
                                        message: "Neki od datuma nije ispravan",
                                      });
                                      setTimeout(() => {
                                        setError({
                                          state: "",
                                          message: "",
                                        });
                                      }, 3500);
                                    } else { */
                                    checkIfExists(getValues());
                                    //}
                                  }
                                }}
                              >
                                Dodaj
                              </Button>
                              <Button
                                className={style.saveBtn}
                                variant="contained"
                                style={{
                                  margin: "8px auto",
                                  width: 140,
                                }}
                                onClick={() => {
                                  const dayNow = date.getDate();
                                  setDate(new Date(date.setDate(dayNow + 1)));
                                }}
                              >
                                Sljedeći dan
                              </Button>
                            </>
                          )}
                        </div>
                      </TimeWrapper>
                    </Column>
                  </Row>
                </Grid>
              </section>

              <TimesheetTable
                info={monthYear}
                days={days}
                dispatch={dispatch}
                addTimes={addTimes}
                setHolidaysTotal={setHolidaysTotal}
                totalSum={totalSum}
                setTotalSum={setTotalSum}
              />
            </>
          )}
          {/*  <Button
              className={style.downloadBtn}
              color="primary"
              variant="contained"
              onClick={handleSubmit((data) => console.log(data))}
            >
              Preuzmi PDF
            </Button> */}
        </form>
      </main>
      {/*   <Modal
        open={open}
        days={days}
        handleOpen={handleOpen}
        monthYear={monthYear}
        dispatch={dispatch}
        addTimes={addTimes}
        setHolidaysTotal={setHolidaysTotal}
      ></Modal> */}
    </>
  );
};

export default WorkTimesheet;

const CInlineLoading = styled(InlineLoading)`
  margin: auto;
  .bx--inline-loading__text {
    font-size: 20px;
    color: ${(props) => props.spancolor};
  }
`;
