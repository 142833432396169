export const Fields = {
  Ime: "Ime",
  Prezime: "Prezime",
  OIB: "OIB",
  Adresa: "Adresa",
  Spol: "Spol",
  OsobniBrojOsiguranika: "Osobni broj osiguranika",
  DatumRodenja: "Datum rođenja",
  MIO2: "2. Stup mirovinskog",
  StrucnaSprema: "Stručna sprema",
  NajvisaZavrsenaSkola: "Najviša završena škola",
  IBANredovni: "IBAN Redovni",
  IBANzasticeni: "IBAN Zaštićeni",
  DatumPocetkaRada: "Datum početka rada",
  RadnoMjesto: "Radno mjesto",
  StrucnaSpremaZaObavljanjePosla: "Stručna sprema za obavljanje posla",
  RadnoVrijemeTjedno: "Radno vrijeme tjedno",
  Bruto1placa: "Bruto 1 plaća",
  Prijevoz: "Prijevoz",
  Invalidnost: "Invalidnost",
  Napomene: "Napomene"
};
