import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";

const Box2 = ({ title, buttons }) => {
  return (
    <Container>
      <Row className="row">
        <div className="col-6" style={{ alignSelf: "center", display: "flex" }}>
          <Heading>{title}</Heading>
        </div>
        <Column1 className="col">
          <div>
            <div>{buttons}</div>
          </div>
        </Column1>
      </Row>
    </Container>
  );
};

export default Box2;

const Container = styled.div.attrs((props) => ({ className: "container" }))`
  //border: 2px solid #45649b;
  height: 100%;
  border-radius: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;
const Column1 = styled.div.attrs((props) => ({ className: "" }))`
  margin-left: 11px;
  overflow: hidden;
  margin: 0;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 25px 0;
  background: #5175b5;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  //box-shadow: -15px 0px 25px 5px #dbdbdb;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: center;
  & > div {
    align-self: center;
  }
`;
const Heading = styled.h4`
  box-shadow: 0px 6px 0px 0px #737af0;
`;
const Row = styled.div.attrs((props) => ({ className: "row" }))`
  height: 100%;
`;
