import React from "react";
import { Grid, Row, Column, InlineNotification } from "carbon-components-react";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import { handlePostRequest } from "../../helpers/handle-response";
import { CardTitle, Error, CustomSelect } from "../styles";

const important = [
  { label: "Hitno", value: "Hitno" },
  { label: "Normalno", value: "Normalno" },
  { label: "Nije hitno", value: "Nije hitno" },
];
const emails = [
  { label: "Mia", value: "mia@parpar.hr" },
  { label: "Tamara", value: "tamara@parpar.hr" },
  { label: "Bilo kome", value: "mia@parpar.hr tamara@parpar.hr" },
];

const OtherInquiries = ({ currentUser }) => {
  const { errors, control, handleSubmit, reset } = useForm({
    defaultValues: { Predmet: "", Prioritet: "", MailTo: "", Poruka: "" },
  });
  const [status, setStatus] = React.useState({
    msg: "",
    state: "",
    btnDisabled: false,
  });
  const submit = (data) => {
    setStatus({ msg: "Slanje emaila", state: "info", btnDisabled: true });
    const obj = {
      user: "igor",
      userEmail: currentUser.email,
      predmet: data.Predmet,
      prioritet: data.Prioritet,
      mailTo: data.MailTo,
      poruka: data.Poruka,
    };
    handlePostRequest(
      "https://apiaccountant.plc.hr/api/mt?m=MailTicketingCreate",
      [obj]
    )
      .then((res) => {
        if (res.data === "True") {
          setStatus({
            msg: "Email poslan",
            state: "success",
            btnDisabled: true,
          });
          setTimeout(() => {
            setStatus({ msg: "", state: "", btnDisabled: false });
            reset({ Predmet: "", Prioritet: "", MailTo: "", Poruka: "" });
          }, 2500);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <Grid style={{ maxWidth: 1250 }}>
      <Row>
        <Column>
          <CardTitle variant="h4">Ostali upiti</CardTitle>
        </Column>
      </Row>
      <form onSubmit={handleSubmit(submit)}>
        <Row style={{ marginTop: 50 }}>
          <Column lg={4} sm={4} md={3} align="center">
            <Grid condensed>
              <Row>
                <Column style={{ height: 50, margin: "10px 0" }} lg={12}>
                  <Controller
                    as={
                      <TextField
                        label="Predmet"
                        type="text"
                        className="customInput"
                        variant="filled"
                      />
                    }
                    name="Predmet"
                    control={control}
                    rules={{ required: "Predmet je obvezno polje" }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="Predmet" />
                </Column>
                <Column style={{ height: 50, margin: "10px 0" }} lg={12}>
                  <FormControl style={{ width: "100%" }} variant="filled">
                    <InputLabel id="select">Prioritet</InputLabel>
                    <Controller
                      as={
                        <CustomSelect
                          style={{ width: "100%" }}
                          labelId="select"
                          name="Prioritet"
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          }}
                        >
                          {important.map((item, index) => {
                            return (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </CustomSelect>
                      }
                      name="Prioritet"
                      control={control}
                      placeholder="Prioritet"
                      rules={{
                        required: "Prioritet je obvezno polje",
                      }}
                      defaultValue=""
                    />
                  </FormControl>
                  <ErrorMessage as={Error} errors={errors} name="Prioritet" />
                </Column>
                <Column style={{ height: 50, margin: "10px 0" }} lg={12}>
                  <FormControl style={{ width: "100%" }} variant="filled">
                    <InputLabel id="select">Kome</InputLabel>
                    <Controller
                      as={
                        <CustomSelect
                          style={{ width: "100%" }}
                          labelId="select"
                          name="MailTo"
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          }}
                        >
                          {emails.map((item, index) => {
                            return (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </CustomSelect>
                      }
                      name="MailTo"
                      control={control}
                      placeholder="Kome"
                      rules={{
                        required: "Kome je obvezno polje",
                      }}
                      defaultValue=""
                    />
                  </FormControl>
                </Column>
              </Row>
            </Grid>
          </Column>
          <Column
            style={{ margin: "10px 0" }}
            lg={4}
            sm={4}
            md={3}
            align="center"
          >
            <Controller
              as={
                <TextField
                  label="Poruka"
                  multiline
                  rows="8"
                  type="text"
                  className="customInput"
                  variant="filled"
                />
              }
              name="Poruka"
              control={control}
              rules={{ required: "Poruka je obvezno polje" }}
            />
            <ErrorMessage as={Error} errors={errors} name="Poruka" />
          </Column>
          <Column
            style={{ margin: "10px 0", alignSelf: "flex-end" }}
            lg={4}
            sm={4}
            md={2}
          >
            <Button
              type="submit"
              disabled={status.btnDisabled}
              variant="contained"
            >
              Pošalji
            </Button>
          </Column>
        </Row>
        <Row>
          <Column align="center" style={{ marginTop: "1rem" }}>
            {status.msg.length > 0 ? (
              <InlineNotification
                style={{ maxWidth: 400 }}
                lowContrast
                hideCloseButton={false}
                iconDescription="describes the close button"
                kind={status.state}
                notificationType="inline"
                onCloseButtonClick={() => setStatus({ message: "", state: "" })}
                role="alert"
                statusIconDescription="describes the status icon"
                title={status.msg}
              />
            ) : null}
          </Column>
        </Row>
      </form>
    </Grid>
  );
};

export default OtherInquiries;
