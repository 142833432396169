import styled from "styled-components";
import { Grid, Row, Column } from "carbon-components-react";
export const CardTitle = styled.div`
  padding: 15px;
  margin-right: 15px;
  font-size: 22px;
  border-radius: 3px;
  background-color: #999;
  width: 100%;
  max-width: 200px;
  text-align: center;
  color: white !important;
  background: linear-gradient(60deg, #66bb6a, #43a047);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(76, 175, 80, 0.4);
`;
export const CustomGrid = styled(Grid)`
  background-color: #334d7b;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgb(177, 177, 177);
  border-radius: 5px;
  margin-bottom: 5px !important;
  padding: 10px 0;
  margin: 5px;
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .MuiFormLabel-root.Mui-focused {
    color: white;
  }

  label {
    color: white;
  }
  input {
    color: white;
  }
  & > div {
    padding: 0 10px;
  }
  @media (max-width: 770px) {
    & > div > div {
      margin: 10px 0;
    }
  }
`;
export const Error = styled.p`
  margin: 5px 0;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: rgb(244, 67, 54);
`;
export const CustomRow = styled(Row)`
  margin-top: ${(props) => props.marginTop || "5px"};
  justify-content: ${(props) => props.justify || ""};
`;
export const CustomColumn = styled(Column)`
  .MuiFormControl-marginNormal {
    margin-top: ${(props) => props.margintop || "5px"};
  }
  @media (max-width: 770px) {
    .MuiFormControl-marginNormal {
      margin-top: 20px;
    }
  }
`;
