import React from "react";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import NumberFormat from "react-number-format";
import {
  Grid,
  Row,
  Column,
  RadioButtonGroup,
  RadioButton,
  Form,
} from "carbon-components-react";
import {
  CustomColumn,
  CustomInput,
  Error,
  CustomFormGroup as FormGroup,
  CustomSelect as Select,
  CustomInputLabel as InputLabel,
} from "./styles";
import hr from "date-fns/locale/hr";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { MenuItem, FormControl, Button } from "@material-ui/core";

const options = [
  { value: "NKV", label: "NKV" },
  { value: "PKV", label: "PKV" },
  { value: "NSS", label: "NSS" },
  { value: "KV", label: "KV" },
  { value: "SSS", label: "SSS" },
  { value: "VKV", label: "VKV" },
  { value: "VŠS", label: "VŠS" },
  { value: "VSS", label: "VSS" },
  { value: "VSS-mr.", label: "VSS-mr." },
  { value: "VSS-dr.", label: "VSS-dr." },
  { value: "VSS-dr.sp.", label: "VSS-dr.sp." },
];

const Zaposlenik = ({ handleNext }) => {
  const { action, state } = useStateMachine(updateAction);
  const { errors, control, handleSubmit, register, setValue } = useForm({
    defaultValues: {
      Ime: state.data.Ime,
      Prezime: state.data.Prezime,
      OIB: state.data.OIB,
      Adresa: state.data.Adresa,
      OsobniBrojOsiguranika: state.data.OsobniBrojOsiguranika,
      StrucnaSprema: state.data.StrucnaSprema,
      NajvisaZavrsenaSkola: state.data.NajvisaZavrsenaSkola,
      IBANredovni: state.data.IBANredovni,
      IBANzasticeni: state.data.IBANzasticeni,
      DatumRodenja: state.data.DatumRodenja,
      DatumPocetkaRada: state.data.DatumPocetkaRada,
      Spol: state.data.Spol,
      Invalidnost: state.data.Invalidnost,
      MIO2: state.data.MIO2,
      Napomene: state.data.Napomene,
    },
  });
  const submit = (data) => {
    console.log(data);
    action(data);
    handleNext();
  };
  return (
    <>
      <CustomColumn md={8} lg={6} style={{ margin: "auto" }}>
        <h3>Osnovni podaci o radniku</h3>
        <Form onSubmit={handleSubmit(submit)}>
          <Grid>
            <Row style={{ marginTop: 15, marginBottom: 15 }}>
              <Column sm={4} lg={6}>
                <Controller
                  as={
                    <CustomInput
                      autoComplete="off"
                      label="Ime"
                      type="text"
                      variant="filled"
                    />
                  }
                  name="Ime"
                  control={control}
                  rules={{
                    required: "Ime je obvezno polje",
                  }}
                />
                <ErrorMessage as={Error} errors={errors} name="Ime" />
              </Column>
              <Column sm={4} lg={6}>
                <Controller
                  as={
                    <CustomInput
                      autoComplete="off"
                      label="Prezime"
                      type="text"
                      variant="filled"
                    />
                  }
                  name="Prezime"
                  control={control}
                  rules={{ required: "Prezime je obvezno polje" }}
                />
                <ErrorMessage as={Error} errors={errors} name="Prezime" />
              </Column>
            </Row>
            <Row style={{ marginTop: 15, marginBottom: 15 }}>
              <Column sm={4} lg={6}>
                <Controller
                  as={
                    <NumberFormat
                      label="OIB"
                      type="text"
                      autoComplete="off"
                      variant="filled"
                      customInput={CustomInput}
                      inputProps={{ maxLength: 11 }}
                    ></NumberFormat>
                  }
                  name="OIB"
                  control={control}
                  rules={{
                    required: "OIB je obvezno polje",
                    minLength: {
                      value: 11,
                      message: "OIB mora sadržavati najmanje 11 znakova",
                    },
                    maxLength: {
                      value: 11,
                      message: "OIB mora sadržavati najviše 11 znakova",
                    },
                  }}
                />
                <ErrorMessage as={Error} errors={errors} name="OIB" />
              </Column>
              <Column sm={4} lg={6}>
                <Controller
                  as={
                    <CustomInput
                      autoComplete="off"
                      label="Adresa"
                      type="text"
                      variant="filled"
                    />
                  }
                  name="Adresa"
                  control={control}
                  rules={{ required: "Adresa je obvezno polje" }}
                />
                <ErrorMessage as={Error} errors={errors} name="Adresa" />
              </Column>
            </Row>
            <Row style={{ marginTop: 15, marginBottom: 15 }}>
              <Column sm={4} lg={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hr}>
                  <Controller
                    control={control}
                    name="DatumRodenja"
                    as={
                      <KeyboardDatePicker
                        style={{ margin: 5, width: "100%" }}
                        variant="inline"
                        inputVariant="filled"
                        format="dd.MM.yyyy"
                        margin="normal"
                        value={state.data.DatumRodenja}
                        autoOk
                        openTo="year"
                        id="date-picker-inline"
                        /*  onChange={(date) => {
                        setValue("DatumRodenja", date);
                      }} */
                        label="Datum rođenja"
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    }
                  />
                </MuiPickersUtilsProvider>
              </Column>
              <Column sm={4} lg={6}>
                <Controller
                  as={
                    <NumberFormat
                      label="Osobni broj osiguranika"
                      type="text"
                      allowLeadingZeros={true}
                      variant="filled"
                      autoComplete="off"
                      customInput={CustomInput}
                      inputProps={{ maxLength: 11 }}
                    ></NumberFormat>
                  }
                  name="OsobniBrojOsiguranika"
                  control={control}
                  rules={{
                    required: "Osobni broj osiguranika je obvezno polje",
                    minLength: {
                      value: 11,
                      message:
                        "Osobni broj mora sadržavati najmanje 11 znakova",
                    },
                    maxLength: {
                      value: 11,
                      message: "Osobni broj mora sadržavati najviše 11 znakova",
                    },
                  }}
                />
                <ErrorMessage
                  as={Error}
                  errors={errors}
                  name="OsobniBrojOsiguranika"
                />
              </Column>
            </Row>
            <Row style={{ marginTop: 15, marginBottom: 15 }}>
              <Column sm={4} lg={6}>
                <FormGroup
                  invalid={false}
                  legendText="Spol"
                  message={false}
                  messageText=""
                >
                  <Controller
                    as={
                      <RadioButtonGroup
                        defaultSelected="zenski"
                        labelPosition="right"
                        legend="Group Legend"
                        name="radio-button-group"
                        orientation="horizontal"
                        valueSelected="zenski"
                      >
                        <RadioButton
                          className="some-class"
                          id="radio-1"
                          labelText="Ženski"
                          value="zenski"
                        />
                        <RadioButton
                          className="some-class"
                          id="radio-2"
                          labelText="Muški"
                          value="muski"
                        />
                      </RadioButtonGroup>
                    }
                    name="Spol"
                    control={control}
                    rules={{ required: "Spol je obvezno polje" }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="Spol" />
                </FormGroup>
              </Column>
              <Column sm={4} lg={6}>
                <FormGroup
                  invalid={false}
                  legendText="2. Stup mirovinskog"
                  message={false}
                  messageText=""
                >
                  <Controller
                    as={
                      <RadioButtonGroup
                        defaultSelected={1}
                        labelPosition="right"
                        legend="Group Legend"
                        name="radio-button-group"
                        orientation="horizontal"
                        valueSelected={1}
                      >
                        <RadioButton
                          className="some-class"
                          id="radio-3"
                          labelText="Da"
                          value={1}
                        />
                        <RadioButton
                          className="some-class"
                          id="radio-4"
                          labelText="Ne"
                          value={0}
                        />
                      </RadioButtonGroup>
                    }
                    name="MIO2"
                    control={control}
                    rules={{ required: "Stup mirovinskog je obvezno polje" }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="MIO2" />
                </FormGroup>
              </Column>
            </Row>
            <Row style={{ marginTop: 15, marginBottom: 15 }}>
              <Column sm={4} lg={6}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="select">Stručna sprema</InputLabel>
                  <Controller
                    as={
                      <Select
                        style={{ margin: 5, width: "100%" }}
                        labelId="select"
                        variant="filled"
                        name="StrucnaSprema"
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                      >
                        {options.map((item) => {
                          return (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    }
                    name="StrucnaSprema"
                    control={control}
                    placeholder="Stručna sprema"
                    rules={{
                      required: "Stručna sprema je obvezno polje",
                    }}
                  />
                </FormControl>
                <ErrorMessage as={Error} errors={errors} name="StrucnaSprema" />
              </Column>
              <Column sm={4} lg={6}>
                <Controller
                  as={
                    <CustomInput
                      autoComplete="off"
                      label="Najviša završena škola"
                      type="text"
                      variant="filled"
                    />
                  }
                  name="NajvisaZavrsenaSkola"
                  control={control}
                  rules={{
                    required: "Najviša završena škola je obvezno polje",
                  }}
                />
                <ErrorMessage
                  as={Error}
                  errors={errors}
                  name="NajvisaZavrsenaSkola"
                />
              </Column>
            </Row>
            <Row style={{ marginTop: 15, marginBottom: 15 }}>
              <Column sm={4} lg={6}>
                <Controller
                  as={
                    <CustomInput
                      autoComplete="off"
                      label="IBAN Redovni"
                      type="text"
                      variant="filled"
                      inputProps={{ maxLength: 21 }}
                    />
                  }
                  name="IBANredovni"
                  control={control}
                  rules={{
                    required: "IBAN Redovni je obvezno polje",
                    minLength: {
                      value: 21,
                      message: "IBAN mora imati najmanje 21 znak",
                    },
                    maxLength: {
                      value: 21,
                      message: "IBAN mora imati najviše 21 znak",
                    },
                  }}
                />
                <ErrorMessage as={Error} errors={errors} name="IBANredovni" />
              </Column>
              <Column sm={4} lg={6}>
                <Controller
                  as={
                    <CustomInput
                      autoComplete="off"
                      label="IBAN Zaštićeni"
                      type="text"
                      variant="filled"
                      inputProps={{ maxLength: 21 }}
                    />
                  }
                  name="IBANzasticeni"
                  control={control}
                  rules={{
                    minLength: {
                      value: 21,
                      message: "IBAN mora imati najmanje 21 znak",
                    },
                    maxLength: {
                      value: 21,
                      message: "IBAN mora imati najviše 21 znak",
                    },
                  }}
                />
                <ErrorMessage as={Error} errors={errors} name="IBANzasticeni" />
              </Column>
            </Row>
          </Grid>
        </Form>
      </CustomColumn>
      <Grid style={{ maxWidth: 850 }}>
        <Row style={{ marginTop: 25 }}>
          <Column style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(submit)}
            >
              Sljedeći korak
            </Button>
          </Column>
        </Row>
      </Grid>
    </>
  );
};

export default Zaposlenik;
