import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { cloneDeep } from "lodash";
import fileDownload from "js-file-download";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  DataTable,
  InlineNotification,
  Grid,
  Row,
  Column,
  InlineLoading,
} from "carbon-components-react";
import { Download32 } from "@carbon/icons-react";
import { DownloadButton } from "./styles";
import { formatDate, formatNumber } from "../../functions/helpers";
import { handlePostRequest } from "../../helpers/handle-response";
import { formatAsTime, getHolidaysDownload } from "../Evidencija/helperFunc";
import { sumTime, timeFormat, getUTC } from "../Evidencija/timeFunc";
import { mjeseci, initialDays } from "../Evidencija/constants/index";
import { b64toBlob } from "../../functions/helpers";
import "./style.scss";

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
} = DataTable;
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  "@media(max-width: 959px)": {
    root: {
      marginTop: 40,
    },
  },
});

function sumAllTimes(days) {
  const values = [];
  days.map((item) => {
    for (let key in item) {
      if (
        key !== "TerenskiRad" &&
        key !== "SatiPripravnosti" &&
        key !== "id" &&
        key !== "ID" &&
        key !== "SihtericaID" &&
        key !== "PocetakRada" &&
        key !== "ZavrsetakRada" &&
        key !== "SatiZastojFrom" &&
        key !== "SatiZastojTill" &&
        key !== "CreatedByIP" &&
        key !== "CreateTime" &&
        key !== "CreatedByUser" &&
        key !== "UkupnoDnevnoRV" &&
        key !== "alreadyAdded"
      ) {
        if (item[key] !== null) {
          const split = item[key].split(":");
          values.push({ hours: Number(split[0]), minutes: Number(split[1]) });
        }
      }
    }
    return null;
  });
  const sum = sumTime(values);
  return timeFormat(sum.hours, sum.minutes);
}
const getPDF = (
  days,
  values,
  totalSum,
  downloading,
  setDownloading,
  holidaysTotal,
  date,
  ID
) => {
  setDownloading({
    ...downloading,
    [ID]: true,
  });
  const daysCopy = cloneDeep(days);
  const filteredDays = daysCopy.map((item) => {
    for (let key in item) {
      if (
        key === "PocetakRada" ||
        key === "ZavrsetakRada" ||
        key === "SatiZastojaFrom" ||
        key === "SatiZastojaTill"
      ) {
        if (item[key]) {
          if (getUTC(item[key], date).getFullYear() === 2000) {
            item[key] = null;
          } else {
            item[key] = getUTC(item[key], date);
          }
        }
      }
    }
    return item;
  });
  handlePostRequest(`https://pdf-api.accountant.hr/create-pdf`, [
    {
      name: "workTimesheet",
      days: filteredDays,
      values,
      totalSum,
      holidaysTotal,
    },
  ])
    .then((response) => {
      setDownloading({ ...downloading, [ID]: false });
      fileDownload(
        b64toBlob(response.data),
        `${values.ImePrezimeString}_${
          mjeseci[values.Mjesec]
        }_${values.Godina.getFullYear()}.pdf`
      );
    })
    .catch((err) => console.log(err));
};
const getItems = (ID, downloading, setDownloading, downloadID) => {
  const getData = async () => {
    const result = await handlePostRequest(
      `https://apiaccountant.plc.hr/api/s?m=SihtericaGetByIdFull`,
      [{ user: "igor", reqID: Number(ID) }]
    );
    const parsed = JSON.parse(result.data);
    const daysCopy = cloneDeep(initialDays);

    parsed.items.map((item) => {
      const obj = {};
      const dayID = new Date(item.PocetakRada).getDate();
      obj.id = String(dayID);
      Object.keys(item).map((key) => {
        if (item[key] !== null && item[key].hasOwnProperty("h")) {
          obj[key] = formatAsTime(item[key]);
        } else if (
          typeof item[key] === typeof "" &&
          item[key].includes("-") &&
          !item[key].includes("2000")
        ) {
          obj[key] = new Date(item[key]);
        } else if (item[key] === null) {
          obj[key] = "00:00";
        } else {
          obj[key] = item[key];
        }
        return null;
      });

      daysCopy[dayID - 1] = obj;
      return null;
    });
    const date = new Date(parsed.Godina, parsed.Mjesec - 1, 5);
    const values = {
      Mjesec: parsed.Mjesec,
      Godina: date,
      ImePrezimeString: parsed.RadnikImePrezime,
      Tvrtka: parsed.OrganizationNaziv,
    };
    const totalSum = sumAllTimes(daysCopy);
    return { parsed, daysCopy, values, totalSum, date };
    //days, values, totalSum, holidaysTotal
  };
  getData().then(({ parsed, daysCopy, values, totalSum, date }) => {
    return getHolidaysDownload(date).then((data) => {
      getPDF(
        daysCopy,
        values,
        totalSum,
        downloading,
        setDownloading,
        data,
        date,
        downloadID
      );
    });
  });
};

const DialogTitleCustom = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

export const Modal = ({ open, handleOpen, picked, allItems, currentUser }) => {
  const [downloading, setDownloading] = React.useState();
  const [downloadedFiles, setDownloadedFiles] = React.useState({
    0: false,
    1: false,
    2: false,
  });
  const [status, setStatus] = React.useState({ msg: "", state: "" });
  const [loaders, setLoaders] = React.useState({
    0: false,
    1: false,
    2: false,
    all: false,
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const submit = React.useCallback(() => {
    const Response = {
      DnlFile1: downloadedFiles[0],
      DnlFile2: downloadedFiles[1],
      DnlFile3: downloadedFiles[2],
      UpdateAck: new Date(),
      UserResponse: "",
    };
    handlePostRequest(
      "https://apiaccountant.plc.hr/api/p?m=PlaceUpdateNewAck",
      [
        {
          user: "igor",
          reqID: Number(picked.ID),
          acknowlage: JSON.stringify(Response),
        },
      ]
    )
      .then((res) => {
        if (res.data === "True") {
          setStatus({ msg: "Preuzimanje potvrđeno", state: "success" });
          setTimeout(() => {
            setStatus({ msg: "", state: "" });
          }, 2000);
        }
      })
      .catch((err) => console.log(err));
  }, [downloadedFiles, picked]);

  const downloadFiles = React.useCallback(
    (url, name, num, id) => {
      setLoaders((loaders) => ({ ...loaders, [num]: true }));
      setDownloading({ ...downloading, [id]: true });
      axios
        .get(url, {
          headers: {
            Authorization: `JWT ${currentUser.token}`,
          },
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res.data, name);

          setDownloadedFiles((downloadedFiles) => ({
            ...downloadedFiles,
            [num]: true,
          }));
          setLoaders((loaders) => ({ ...loaders, [num]: false }));
          setDownloading({ ...downloading, [id]: false });

          submit();
        })
        .catch((err) => console.log(err));
    },
    [currentUser, submit, downloading, setDownloading]
  );
  const downloadAllFiles = () => {
    setLoaders((loaders) => ({ ...loaders, all: true }));

    axios
      .post(
        `https://www.api.accountant.hr/zip/get-files`,
        {
          fileNames: [
            `${picked.response.File1Ref}`,
            `${picked.response.File2Ref}`,
            `${picked.response.File3Ref}`,
          ],
          zipName: "Place_",
          mjesec: picked.request.PayMonth,
          godina: picked.request.PayYear,
        },
        {
          headers: {
            Authorization: `JWT ${currentUser.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          axios
            .get(`https://www.api.accountant.hr/private/${res.data.filename}`, {
              headers: {
                Authorization: `JWT ${currentUser.token}`,
              },
              responseType: "arraybuffer",
            })
            .then((response) => {
              fileDownload(response.data, res.data.filename);

              setDownloadedFiles({
                0: true,
                1: true,
                2: true,
              });
              setLoaders((loaders) => ({ ...loaders, all: false }));
              submit(downloadedFiles, setStatus, picked);
            })
            .catch((err) => console.log(err));
        }
      });
  };

  const { data, isLoading, isFetching } = useQuery(
    "checkout",
    async () => {
      console.log(picked);
      const {
        data,
      } = await handlePostRequest(
        `https://apiaccountant.plc.hr/api/p?m=PlaceItemsGets`,
        [{ user: "igor", reqID: picked.ID }]
      );
      const formatted = JSON.parse(data);
      console.log(picked, formatted);
      const formatData = formatted.map((item) => {
        console.log(item);
        return {
          id: String(item.ID),
          Name: `${item.FirstName} ${item.LastName}`,
          Brutto: formatNumber(item.Brutto),
          Bonus: formatNumber(item.Bonus),
          Sihterica:
            item.Sihterica /* (
            <DownloadButton
              variant="contained"
              onClick={() =>
                downloadFiles(
                  `https://www.api.accountant.hr/private/${item.Sihterica.replace(/\s/g, "")}`,
                  item.Sihterica
                )
              }
            >
              {item.Sihterica.replace(/\s/g, "")}
            </DownloadButton>
          ), */,
        };
      });

      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );

  return (
    <>
      {data && !isLoading && !isFetching ? (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleOpen}
          maxWidth="lg"
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitleCustom id="responsive-dialog-title" onClose={handleOpen}>
            {currentUser.companyName}
          </DialogTitleCustom>
          <DialogContent>
            <h5>
              Obračun plaće za mjesec: {picked.request.PayMonth + 1}/
              {picked.request.PayYear}
            </h5>
            <h5>Datum isplate: {formatDate(picked.request.PayDate)}</h5>
            <br />

            <DataTable
              filterRows={function noRefCheck() {}}
              headers={[
                {
                  header: "Ime",
                  key: "Name",
                },
                {
                  header: "Brutto",
                  key: "Brutto",
                },
                {
                  header: "Bonus",
                  key: "Bonus",
                },
                {
                  header: "Šihterica",
                  key: "Sihterica",
                },
              ]}
              isSortable
              locale="en"
              rows={data}
              render={({
                rows,
                headers,
                getHeaderProps,
                getTableProps,
                getRowProps,
              }) => (
                <TableContainer style={{ minWidth: "unset" }}>
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <TableHeader {...getHeaderProps({ header })}>
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id} {...getRowProps({ row })}>
                          {row.cells.map((cell) => {
                            if (cell.id.includes("Sihterica")) {
                              return (
                                <TableCell key={cell.id}>
                                  <DownloadButton
                                    variant="contained"
                                    onClick={() =>
                                      isNaN(cell.value)
                                        ? downloadFiles(
                                            `https://www.api.accountant.hr/private/${cell.value.replace(
                                              /\s/g,
                                              ""
                                            )}`,
                                            cell.value,
                                            "",
                                            cell.id
                                          )
                                        : getItems(
                                            cell.value,
                                            downloading,
                                            setDownloading,
                                            cell.id
                                          )
                                    }
                                  >
                                    {downloading && downloading[cell.id] ? (
                                      <InlineLoading
                                        description="Preuzimanje..."
                                        iconDescription="Active loading indicator"
                                        status="active"
                                        successDelay={1500}
                                      />
                                    ) : isNaN(cell.value) ? (
                                      cell.value.replace(/\s/g, "")
                                    ) : (
                                      "Preuzmi šihtericu"
                                    )}
                                  </DownloadButton>
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={cell.id}>
                                  {cell.value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            />
            <Grid style={{ marginTop: 20, maxWidth: 750 }}>
              <Row>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  <h4>Preuzimanje datoteka: </h4>
                </Column>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  {console.log(picked.response.File1Ref)}
                  <Button
                    name={"file1"}
                    disabled={loaders[0] || picked.response.File1Ref.length < 1}
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                    style={{ maxWidth: 200, }}
                    startIcon={
                      loaders[0] ? null : (
                        <Download32
                          size={24}
                          style={{
                            width: "25px",
                            marginRight: "-3px",
                          }}
                          fill="white"
                        />
                      )
                    }
                    onClick={() =>
                      downloadFiles(
                        `https://www.api.accountant.hr/private/${picked.response.File1Ref.replace(
                          /\s/g,
                          ""
                        )}`,
                        picked.response.File1Ref,
                        0
                      )
                    }
                  >
                    {loaders[0] ? (
                      <InlineLoading
                        style={{ justifyContent: "center" }}
                        description="Preuzimanje..."
                        iconDescription="Active loading indicator"
                        status="active"
                        successDelay={1500}
                      />
                    ) : (
                      "Isplatne liste"
                    )}
                  </Button>
                </Column>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  <Button
                    name={"file2"}
                    disabled={loaders[1] || picked.response.File2Ref.length < 1}
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                    style={{ maxWidth: 200, backgroundColor: "#405E87" }}
                    startIcon={
                      loaders[1] ? null : (
                        <Download32
                          size={24}
                          style={{
                            width: "25px",
                            marginRight: "-3px",
                          }}
                          fill="white"
                        />
                      )
                    }
                    onClick={() =>
                      downloadFiles(
                        `https://www.api.accountant.hr/private/${picked.response.File2Ref.replace(
                          /\s/g,
                          ""
                        )}`,
                        picked.response.File2Ref,
                        1
                      )
                    }
                  >
                    {loaders[1] ? (
                      <InlineLoading
                        style={{ justifyContent: "center" }}
                        description="Preuzimanje..."
                        iconDescription="Active loading indicator"
                        status="active"
                        successDelay={1500}
                      />
                    ) : (
                      "Obrasci IP1"
                    )}
                  </Button>
                </Column>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  <Button
                    name={"file3"}
                    disabled={loaders[2] || picked.response.File3Ref.length < 1}
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                    style={{ maxWidth: 200, backgroundColor: "#405E87" }}
                    startIcon={
                      loaders[2] ? null : (
                        <Download32
                          size={24}
                          style={{
                            width: "25px",
                            marginRight: "-3px",
                          }}
                          fill="white"
                        />
                      )
                    }
                    onClick={() =>
                      downloadFiles(
                        `https://www.api.accountant.hr/private/${picked.response.File3Ref.replace(
                          /\s/g,
                          ""
                        )}`,
                        picked.response.File3Ref,
                        2
                      )
                    }
                  >
                    {loaders[2] ? (
                      <InlineLoading
                        style={{ justifyContent: "center" }}
                        description="Preuzimanje..."
                        iconDescription="Active loading indicator"
                        status="active"
                        successDelay={1500}
                      />
                    ) : (
                      "Nalozi za plaćanje"
                    )}
                  </Button>
                </Column>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  <Button
                    name={"file3"}
                    disabled={
                      loaders.all ||
                      (picked.response.File1Ref.length < 1 &&
                        picked.response.File2Ref.length < 1 &&
                        picked.response.File3Ref.length < 1)
                    }
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                    startIcon={
                      loaders.all ? null : (
                        <Download32
                          size={24}
                          style={{
                            width: "25px",
                            marginRight: "-3px",
                          }}
                          fill="white"
                        />
                      )
                    }
                    style={{
                      maxWidth: 200,
                      backgroundColor: "rgb(102, 144, 204)",
                    }}
                    onClick={() => downloadAllFiles()}
                  >
                    {loaders.all ? (
                      <InlineLoading
                        style={{ justifyContent: "center" }}
                        description="Preuzimanje..."
                        iconDescription="Active loading indicator"
                        status="active"
                        successDelay={1500}
                      />
                    ) : (
                      "Preuzmi sve"
                    )}
                  </Button>
                </Column>
                {/*  <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => submit()}
                  >
                    Potvrdite preuzimanje
                  </Button>
                </Column> */}
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  {status.msg.length > 1 ? (
                    <InlineNotification
                      style={{ maxWidth: 400 }}
                      lowContrast
                      hideCloseButton={false}
                      iconDescription="describes the close button"
                      kind={status.state}
                      notificationType="inline"
                      onCloseButtonClick={() =>
                        setStatus({ msg: "", state: "" })
                      }
                      role="alert"
                      statusIconDescription="describes the status icon"
                      title={status.msg}
                    />
                  ) : null}
                </Column>
              </Row>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleOpen} color="primary">
              Zatvori
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};
