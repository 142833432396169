import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { Card, CardActions, CardContent } from "@material-ui/core";
const useStyles = makeStyles({
  root: {
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: 0,
    display: "flex",
    fontSize: ".875rem",
    minWidth: 0,
    wordWrap: "break-word",
    background: "#FFF",
    //boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    boxShadow:
      "rgba(69, 100, 155 , 0.4) 5px 5px, rgba(69, 100, 155 , 0.3) 10px 10px, rgba(69, 100, 155 , 0.2) 15px 15px",
    /*   boxShadow:
      "rgba(102, 77, 144, 0.4) 5px 5px, rgba(102, 77, 144, 0.3) 10px 10px, rgba(102, 77, 144, 0.2) 15px 15px", */
    borderRadius: "6px",
    /*    marginTop: "60px",
    marginBottom: "60px", */
    flexDirection: "column",
    maxHeight: 200,
    minHeight: 200,
    height: "100%",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  action: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    minWidth: 200,
    marginTop: 15,
    justifyContent: "center",
    alignItems: "end !important",
    "& > *": {
      marginLeft: "0 !important",
    },
  },
});

const Box = ({ title, secondComponent, children, handleClick, buttons }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <CardTitle>{title}</CardTitle>
      </CardContent>
      <CardActions className={classes.action}>{buttons}</CardActions>
    </Card>
  );
};

export default Box;
const CardTitle = styled.div`
  padding: 15px;
  margin-right: 15px;
  font-size: 22px;
  border-radius: 3px;
  background-color: #999;
  width: 100%;
  max-width: 225px;
  text-align: center;
  position: absolute;
  color: white !important;
  margin-top: -35px !important;
  background: linear-gradient(60deg, #66bb6a, #43a047);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(76, 175, 80, 0.4);
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
