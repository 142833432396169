import React from "react";
import { Grid, Row, Column, DataTableSkeleton } from "carbon-components-react";
import { Modal } from "./Modal";
import { handlePostRequest } from "../../helpers/handle-response";
import { useQuery } from "react-query";
import ReviewTable from "./ReviewTable";
import { CardTitle } from "../styles";

const ClientStatementsReview = ({ currentUser, isClient }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);
  const [picked, setPicked] = React.useState(0);
  const { data, isLoading, isFetching } = useQuery(
    "radnici",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        `https://apiaccountant.plc.hr/api/p?m=PlaceGets`,
        [{ user: "igor", orgID: currentUser.orgID }]
      );
      const formatted = JSON.parse(data);

      const formatData = formatted.sort((a, b) => {
        return new Date(b.request.PayDate) - new Date(a.request.PayDate);
      });
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );

  return (
    <>
      <Grid>
        <Row lg={6} xs={12}>
          <Column>
            <CardTitle maxwidth="320px" variant="h4">
              Pregled zahtjeva za plaće
            </CardTitle>
          </Column>
        </Row>
      </Grid>
      <Grid>
        <Row>
          {!isLoading && !isFetching && data ? (
            <ReviewTable
              currentUser={currentUser}
              data={data}
              setPicked={setPicked}
              handleOpen={handleOpen}
              organizations={data}
              picked={picked}
            />
          ) : (
            <div style={{ width: "100%" }}>
              <DataTableSkeleton showToolbar={false} showHeader={false} />
            </div>
          )}
        </Row>
      </Grid>

      {open && (
        <Modal
          isClient={isClient}
          currentUser={currentUser}
          open={open}
          handleOpen={handleOpen}
          picked={picked}
          allItems={data}
        ></Modal>
      )}
    </>
  );
};

export default ClientStatementsReview;
