import React from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { Grid, Row, DataTableSkeleton } from "carbon-components-react";
import { handlePostRequest } from "../../helpers/handle-response";
import { cloneDeep } from "lodash";
import { formatAsTime } from "./helperFunc";
import ClientTable from "./ClientTable";

const getItems = (days, dispatch, ID, date) => {
  const getData = async () => {
    const result = await handlePostRequest(
      `https://apiaccountant.plc.hr/api/s?m=SihtericaGetByIdFull`,
      [{ user: "igor", reqID: ID }]
    );
    const parsed = JSON.parse(result.data);
    const daysCopy = cloneDeep(days);

    parsed.items.map((item) => {
      const obj = {};
      const dayID = new Date(item.PocetakRada).getDate();
      obj.id = String(dayID);
      Object.keys(item).map((key) => {
        if (item[key] !== null && item[key].hasOwnProperty("h")) {
          obj[key] = formatAsTime(item[key]);
        } else if (
          typeof item[key] === typeof "" &&
          item[key].includes("-") &&
          !item[key].includes("2000")
        ) {
          obj[key] = new Date(item[key]);
        } else if (item[key] === null) {
          obj[key] = "00:00";
        } else {
          obj[key] = item[key];
        }
        return null;
      });

      daysCopy[dayID - 1] = obj;
      return null;
    });

    dispatch({
      type: "SET_DATA",
      id: date.getDate(),
      state: daysCopy,
    });
  };
  getData();
};

const SihtericaList = ({
  currentUser,
  date,
  setDate,
  days,
  dispatch,
  setCurrentItem,
  setDisableForm,
  setMonthYear,
  initialDays,
  createNew,
}) => {
  let history = useHistory();
  const { reset } = useFormContext();

  const { data, isLoading } = useQuery(
    "sihterica",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        `https://apiaccountant.plc.hr/api/s?m=SihtericaGets`,
        [{ user: "igor", orgID: currentUser.orgID }]
      );

      const items = JSON.parse(data);
      let dataArray = [];
      for (let key in items) {
        dataArray.push(items[key]);
      }
      return dataArray;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );

  return (
    <>
      <Grid>
        <Row>
          {!isLoading && data ? (
            <ClientTable
              currentUser={currentUser}
              data={data}
              date={date}
              setDate={setDate}
              setCurrentItem={setCurrentItem}
              getItems={getItems}
              setMonthYear={setMonthYear}
              dispatch={dispatch}
              reset={reset}
              days={days}
              setDisableForm={setDisableForm}
              history={history}
              createNew={createNew}
            />
          ) : (
            <div style={{ width: "100%" }}>
              <DataTableSkeleton showToolbar={false} showHeader={false} />
            </div>
          )}
        </Row>
      </Grid>
    </>
  );
};

export default SihtericaList;
