import React from "react";
import { Grid, Column, Row } from "carbon-components-react";
import { AppDeveloper } from "@carbon/pictograms-react";
import { Overlay } from "../styles";
const ComingSoon = () => {
  return (
    <Grid>
      <Row style={{ justifyContent: "center" }}>
        <Column lg={16} style={{ textAlign: "center", marginBottom: 20 }}>
          <Overlay>
            <h1 style={{ color: "#443491", marginTop: 50 }}>AccountAnt</h1>
            <h1>Coming soon!</h1>
            <AppDeveloper style={{ height: 200, width: 170 }}></AppDeveloper>
          </Overlay>
        </Column>
      </Row>
    </Grid>
  );
};
export default ComingSoon;
