import React from "react";
import { useQuery } from "react-query";
import { Grid, Row, Column, DataTableSkeleton } from "carbon-components-react";
import PdvTable from "./PdvTable";
import { CardTitle } from "../styles";

import { handlePostRequest } from "../../helpers/handle-response";

import "./styles.scss";

const ClientVirmanReview = ({ currentUser }) => {
  const [isDownloaded, setIsDownloaded] = React.useState([]);

  const { data, /* isLoading */ } = useQuery(
    "pdvs",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        "https://apiaccountant.plc.hr/api/v?m=PlacanjaVirmaniGets",
        [{ user: "igor", orgID: currentUser.orgID }]
      );
      const parsedData = JSON.parse(data);
      setIsDownloaded(
        parsedData.map((item, index) => {
          return {
            DnlPdfAck: item.ack.DnlPdfAck,
            DnlXmlAck: item.ack.DnlXmlAck,
          };
        })
      );
      return parsedData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );
  return (
    <Grid style={{ maxWidth: 1250 }}>
      <Row>
        <Column>
          <CardTitle variant="h4">Dugovanja</CardTitle>
        </Column>
      </Row>

      <Grid helpercolor="white">
        {isDownloaded.length !== 0 && data ? (
          <PdvTable
            data={data}
            currentUser={currentUser}
            isDownloaded={isDownloaded}
            setIsDownloaded={setIsDownloaded}
          />
        ) : (
          <DataTableSkeleton showToolbar={false} showHeader={false} />
        )}
      </Grid>
    </Grid>
  );
};

export default ClientVirmanReview;
