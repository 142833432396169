import React from "react";
import { useQuery } from "react-query";
import {
  Grid,
  Row,
  Column,
  InlineNotification,
  InlineLoading,
  DataTableSkeleton,
} from "carbon-components-react";
import { Button } from "@material-ui/core";
import { CardTitle } from "../styles";
import {
  handlePostRequest,
  //handleGetRequest,
} from "../../helpers/handle-response";
//import fileDownload from "js-file-download";
import ClientTable from "./ClientTable";

const ClientDrivingReview = ({ currentUser }) => {
  const [status, setStatus] = React.useState({ msg: "", state: "" });
  const [requestPending, setRequestPending] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  //const [flag, setFlag] = React.useState(0);

  const { data, isLoading } = useQuery(
    "driving",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        "https://apiaccountant.plc.hr/api/loco?m=LocoVoznjaGetsAll",
        [{ user: "igor" }]
      );
      const formatData = JSON.parse(data);

      return Object.keys(formatData)
        .map((key) => {
          return formatData[key];
        })
        .filter((item) => {
          if (new Date(item.request.DatumObracuna) > new Date(2000, 2, 2)) {
            return item;
          }
          return null;
        });
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );

  const sendFile = (data) => {
    console.log(data);
    setSending({ ...sending, [data.ID]: true });
    const LocoRequest = {
      OrganizationNaziv: data.request.OrganizationNaziv,
      OrganizationOIB: data.request.OrganizationOIB,
      Godina: data.request.Godina,
      Mjesec: data.request.Mjesec,
      RadnikID: data.request.RadnikID,
      RadnikImePrezime: data.request.RadnikImePrezime,
      DatumObracuna: data.request.DatumObracuna,
      VoziloMarka: data.request.VoziloMarka,
      VoziloRegistracija: data.request.VoziloRegistracija,
      MailSentRequest: data.request.MailSentRequest,
      UpdateTime: data.request.UpdateTime,
      CreatedByUser: data.request.CreatedByUser,
    };
    const LocoResponse = {
      File1Ref: data.response.File1Ref,
      MailSentResponse: 0,
      ResponseTime: new Date(),
      ResponseUser: "",
    };
    const LocoAck = {
      File1Ack: true,
      AckTime: new Date(),
      AckUser: "",
    };
    // construct the LocoVoznja object for request
    const LocoVoznja = {
      ID: data.ID,
      OrganizationID: currentUser.orgID,
      request: LocoRequest,
      response: LocoResponse,
      ack: LocoAck,
    };
    handlePostRequest(
      "https://apiaccountant.plc.hr/api/loco?m=LocoVoznjaUpdateAck",
      [{ user: "igor", lv: LocoVoznja }]
    )
      .then((res) => {
        if (res.data === '"True"') {
          setStatus({
            msg: "Preuzimanje potvrđeno!",
            state: "success",
          });
          setSending({ ...sending, [data.ID]: false });

          setTimeout(() => {
            //setFlag(Math.random());
            setRequestPending(false);
            setStatus({ msg: "", state: "" });
          }, 2000);
        }
      })
      .catch((err) => console.log(err));
  };

  /*   const getPDF = React.useCallback(
    (data, url, name, num) => {
      setDownloading((downloading) => {
        return { ...downloading, [data.ID]: true };
      });
      console.log(data);
      handleGetRequest(url, currentUser.token, "blob")
        .then((res) => {
          console.log(res);
          fileDownload(res.data, name);
          setDownloading((downloading) => {
            return { ...downloading, [data.ID]: false };
          });
        })
        .catch((err) => console.log(err));
    },
    [currentUser]
  ); */

  return (
    <Grid style={{ maxWidth: 1250 }}>
      <Row>
        <Column>
          <CardTitle variant="h4">Putni nalozi</CardTitle>
        </Column>
      </Row>
      <Row>
        <Column align="center">
          {status.msg.length > 1 ? (
            <InlineNotification
              style={{ maxWidth: 400 }}
              lowContrast
              hideCloseButton={false}
              iconDescription="describes the close button"
              kind={status.state}
              notificationType="inline"
              onCloseButtonClick={() => setStatus({ msg: "", state: "" })}
              role="alert"
              statusIconDescription="describes the status icon"
              title={status.msg}
            />
          ) : null}
        </Column>
      </Row>
      <Grid helpercolor="white">
        {!isLoading && data ? (
          <ClientTable
            currentUser={currentUser}
            data={data}
            downloading={downloading}
            setDownloading={setDownloading}
            sendFile={sendFile}
          />
        ) : (
          <div style={{ width: "100%" }}>
            <DataTableSkeleton showToolbar={false} showHeader={false} />
          </div>
        )}
      </Grid>
      <Row align="center" style={{ marginTop: 50, display: "none" }}>
        <Column>
          <Button disabled={requestPending} variant="contained" type="submit">
            {requestPending ? (
              <InlineLoading
                description="Slanje..."
                iconDescription="Active loading indicator"
                status="active"
                successDelay={1500}
              />
            ) : (
              "Pošalji"
            )}
          </Button>
        </Column>
      </Row>
    </Grid>
  );
};

export default ClientDrivingReview;
