import React from "react";
import { DataTable, Button, Pagination } from "carbon-components-react";
import { formatDate, getObject } from "../../functions/helpers";
import { WarningAltFilled32, CheckmarkFilled32 } from "@carbon/icons-react";
const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
} = DataTable;

const ReviewTable = ({
  currentUser,
  data,
  setPicked,
  handleOpen,
  organizations,
}) => {
  const [page, setPage] = React.useState({ current: 1, pageSize: 10 });
  const filteredData = data.map((item) => {
    return {
      id: String(item.ID),
      Pregled: "",
      Naziv: currentUser.companyName,
      Mjesec: `${item.request.PayMonth + 1}/${item.request.PayYear}`,
      Datum: formatDate(item.request.PayDate),
      Status: item.response.UserResponse,
    };
  });
  const onPageChange = (pageChange) => {
    setPage({ current: pageChange.page, pageSize: pageChange.pageSize });
  };
  var startItem = (page.current - 1) * page.pageSize;
  var endItem = startItem + page.pageSize;
  var displayedRows = filteredData.slice(startItem, endItem);
  return (
    <div style={{ margin: "auto", width: "100%" }}>
      <DataTable
        filterRows={function noRefCheck() {}}
        headers={[
          {
            header: "Pregled",
            key: "Pregled",
          },
          {
            header: "Naziv",
            key: "Naziv",
          },
          {
            header: "Mjesec",
            key: "Mjesec",
          },
          {
            header: "Datum",
            key: "Datum",
          },
          {
            header: "Status",
            key: "Status",
          },
        ]}
        isSortable
        locale="en"
        rows={displayedRows}
        render={({
          rows,
          headers,
          getHeaderProps,
          getTableProps,
          getRowProps,
        }) => (
          <TableContainer style={{ minWidth: "unset" }}>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      if (cell.info.header === "Pregled") {
                        return (
                          <TableCell key={cell.id}>
                            <Button
                              onClick={() => {
                                setPicked(getObject(Number(row.id), organizations));
                                handleOpen();
                              }}
                            >
                              Pregled
                            </Button>
                          </TableCell>
                        );
                      } else if (cell.info.header === "Status") {
                        return (
                          <TableCell key={cell.id}>
                            {cell.value === "waiting" ? (
                              <WarningAltFilled32
                                style={{ width: "100%", height: "100%" }}
                                fill="#FD970F"
                              />
                            ) : (
                              <CheckmarkFilled32
                                style={{ width: "100%", height: "100%" }}
                                fill="#4DA851"
                              />
                            )}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={cell.id}>{cell.value}</TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      />
      <Pagination
        style={{ overflow: "hidden" }}
        backwardText="Prethodna stranica"
        disabled={false}
        forwardText="Sljedeća stranica"
        isLastPage={false}
        itemRangeText={function noRefCheck() {}}
        itemText={function noRefCheck() {}}
        itemsPerPageText="Broj stavki po stranici:"
        onChange={onPageChange}
        page={1}
        pageNumberText="Page Number"
        pageRangeText={function noRefCheck() {}}
        pageSize={10}
        pageSizes={[10, 20, 30, 40, 50]}
        pageText={function noRefCheck() {}}
        pagesUnknown={false}
        totalItems={filteredData.length}
      />
    </div>
  );
};

export default ReviewTable;
