import React from "react";
import { StateMachineProvider } from "little-state-machine";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Zaposlenik from "./Zaposlenik";
import RadnoMjesto from "./RadnoMjesto";
import LastStep from "./LastStep";
import styled from "styled-components";
import "./FormStepper.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    textAlign: "center",
  },
}));

function getSteps() {
  return ["Podaci o zaposleniku", "Podaci o radnom mjestu"];
}

function getStepContent(step, handleNext, handleBack, onSubmit) {
  switch (step) {
    case 0:
      return <Zaposlenik handleNext={handleNext} />;
    case 1:
      return (
        <RadnoMjesto
          handleNext={handleNext}
          handleBack={handleBack}
          onSubmit={onSubmit}
        />
      );
    default:
      return "Unknown step";
  }
}

export default function FormStepper({ onSubmit, isLoading }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <div className={classes.root}>
      <StateMachineProvider>
        <Box maxW="1200px" mx="auto">
          <Stepper
            activeStep={activeStep}
            style={{
              backgroundColor: "#EEEEEE",
              maxWidth: 900,
              margin: "auto",
            }}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>

        <div>
          {activeStep === steps.length ? (
            <LastStep handleBack={handleBack} isLoading={isLoading} />
          ) : (
            <div>
              {getStepContent(activeStep, handleNext, handleBack, onSubmit)}
            </div>
          )}
        </div>
      </StateMachineProvider>
    </div>
  );
}
const Box = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;
