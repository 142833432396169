import { authenticationService } from "../services/authentication";
import axios from "axios";

export function handleResponse(response) {
  return response.json().then((json) => {
    const data = json;
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        authenticationService.logout();
        window.location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

const instance = axios.create();
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (
      response.data === "{}" ||
      //REMOVED BECAUSE SOMETIMES TABLE IS EMPTY => response.data === "{}" ||
      response.data === "[]"
    ) {
      //authenticationService.logout();
      return Promise.reject("Retry");
    } else {
      return response;
    }
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    //replace with currentUser.username later
    console.log(error);
    /*  instance
      .post(
        "http://139.59.206.191:8080/http://accountant.plc.hr/Place/Unload",
        { user: "igor" }
      )
      .then(res => {
        authenticationService.logout();
        window.location.reload(true);
      }); */
    return Promise.reject(error);
  }
);

export function handlePostRequest(url, options, token) {
  //console.log(url, options, token);
  return instance.post(url, ...options, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
}
export function handleFileUpload(url, Files, token) {
  console.log(url, Files, token);
  return instance.post(url, Files, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
}
export function handleGetRequest(url, token, type) {
  return instance.get(url, {
    headers: { Authorization: `JWT ${token}` },
    responseType: type,
  });
}
