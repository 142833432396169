import styled from "styled-components";
//import { Column, FormGroup } from "carbon-components-react";
import { Button } from "@material-ui/core";
export const Box = styled.div`
  color: rgba(0, 0, 0, 0.87);
  padding: 20px;
  width: 100%;
  border: 0;
  display: flex;
  font-size: 0.875rem;
  min-width: 0;
  word-wrap: break-word;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  margin-top: 30px;
  border-radius: 6px;
  margin-bottom: 30px;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.14);
  }
`;
export const DownloadButton = styled(Button)`
  span {
    width: 100%;
    max-width: 100px;
    display: inline-block;
    text-transform: none !important;
    font-size: 13px;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    word-break: break-word !important;
  }
`;
