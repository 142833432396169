export const holidays = [
  "2020-1-1",
  "2020-1-6",
  "2020-4-12",
  "2020-4-13",
  "2020-5-1",
  "2020-6-11",
  "2020-6-22",
  "2020-8-5",
  "2020-8-15",
  "2020-11-1",
  "2020-12-25",
  "2020-12-26",
  "2020-5-30",
  "2020-6-25",
  "2020-11-18",
  "2021-1-1",
  "2021-1-6",
  "2021-4-4",
  "2021-4-5",
  "2021-5-1",
  "2021-6-3",
  "2021-6-22",
  "2021-8-5",
  "2021-8-15",
  "2021-11-1",
  "2021-12-25",
  "2021-12-26",
  "2021-5-30",
  "2021-6-25",
  "2021-11-18"
];
