import React from "react";
import { useQuery } from "react-query";
import { Grid, Row, Column, DataTableSkeleton } from "carbon-components-react";
import { Modal } from "./Modal";
import { sleep } from "../../functions/helpers";
import { handlePostRequest } from "../../helpers/handle-response";
import ApplicationTable from "../ApplicationTable";
import { CardTitle } from "../styles";

const ClientApplicationReview = ({ currentUser, isClient }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);
  const [picked, setPicked] = React.useState(0);

  const { data, isLoading } = useQuery(
    "applicationClient",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        `https://apiaccountant.plc.hr/api/r?m=RadniciGets`,
        [{ user: "igor", orgID: currentUser.orgID }]
      );
      const parsedData = JSON.parse(data);
      console.log(parsedData);
      const formatData = parsedData
        /*  .filter((item) => {
          if (item.odjavaReq.OdjavaReqUser === "waiting") {
            return false;
          } else return true;
        }) */
        .map((item) => {
          return { ...item, Radnik: item.info.Ime + " " + item.info.Prezime };
        })
        .sort((a, b) => {
          return (
            new Date(b.info.DatumPocetkaRada) -
            new Date(a.info.DatumPocetkaRada)
          );
        });
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );
  const { data: organizations } = useQuery(
    "organizationsApplications",
    async () => {
      await sleep(200);
      const {
        data: orgData,
      } = await handlePostRequest(
        "https://apiaccountant.plc.hr/api/p?m=GetOrganizations",
        [{ user: "igor" }]
      );
      const parsedData = JSON.parse(orgData);

      return data.map((item) => {
        return { ...item, OrgName: parsedData[item.OrganizationID].Naziv };
      });
    },

    {
      enabled: data,
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );
  return (
    <>
      <Grid>
        <Row lg={6} xs={12}>
          <Column>
            <CardTitle maxwidth="320px" variant="h4">
              Pregled prijava zaposlenika
            </CardTitle>
          </Column>
        </Row>
      </Grid>
      <Grid>
        <Row>
          {!isLoading && organizations && data? (
            <ApplicationTable
              currentUser={currentUser}
              data={organizations}
              setPicked={setPicked}
              handleOpen={handleOpen}
            />
          ) : (
            <div style={{ width: "100%" }}>
              <DataTableSkeleton showToolbar={false} showHeader={false} />
            </div>
          )}
        </Row>
      </Grid>
      {organizations && (
        <Modal
          isClient={isClient}
          currentUser={currentUser}
          open={open}
          handleOpen={handleOpen}
          picked={picked}
          allItems={organizations}
        ></Modal>
      )}
    </>
  );
};

export default ClientApplicationReview;
