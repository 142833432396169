import React from "react";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import {
  Grid,
  Column,
  Row,
  InlineNotification,
  Loading,
} from "carbon-components-react";
import { MenuItem, FormControl, Button } from "@material-ui/core";
import { useQuery } from "react-query";
import {
  CardTitle,
  CustomGrid,
  Error,
  CustomSelect as Select,
  CustomInputLabel as InputLabel,
} from "./styles";

import hr from "date-fns/locale/hr";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  formatDate,
  addHours,
  getNextWorkDay,
  disableHolidays,
} from "../../functions/helpers";
import { handlePostRequest } from "../../helpers/handle-response";

const EmployeeCheckout = ({ currentUser }) => {
  const { errors, control, handleSubmit, register, reset, setValue } = useForm({
    defaultValues: {
      ImePrezime: "",
      Datum: new Date(getNextWorkDay(new Date(addHours(new Date(), 48)))),
      Razlog: "",
    },
  });
  const [date, setDate] = React.useState({
    Datum: new Date(getNextWorkDay(new Date(addHours(new Date(), 48)))),
  });
  const [isLoading, setIsLoading] = React.useState({
    state: false,
    message: "",
  });
  const { data: employees, isLoading: eLoading } = useQuery(
    "checkout",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        `https://apiaccountant.plc.hr/api/r?m=RadniciGets`,
        [{ user: "igor", orgID: currentUser.orgID }]
      );
      const parsedData = JSON.parse(data);
      const formatData = parsedData
        .filter((item) => {
          console.log(item.odjavaReq, item.response)
          if (
            item.odjavaReq.OdjavaReqUser === "waiting" &&
            item.response.UserResponse !== "waiting"
          ) {
            return true;
          } else {
            return false;
          }
        })
        .map((item) => {
          return {
            ...item,
            label: item.info.Ime + " " + item.info.Prezime,
            value: item.ID,
            OrgName: currentUser.companyName,
          };
        })
        .sort((a, b) => {
          return (
            new Date(a.odjavaReq.DatumOdjave) -
            new Date(b.odjavaReq.DatumOdjave)
          );
        });
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );
  const { data: reasons, isLoading: loading } = useQuery(
    "organizationsCheckouts",
    async () => {
      const {
        data: orgData,
      } = await handlePostRequest(
        "https://apiaccountant.plc.hr/api/r?m=RadniciRazlogOdjaveGets",
        [{ user: "igor" }]
      );
      const formattedData = JSON.parse(orgData);

      return formattedData;
    },

    {
      enabled: employees,
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );

  const onSubmit = (data) => {
    setIsLoading({ state: true, message: "Slanje podataka" });

    const RadnikOdjavaRequest = {
      DatumOdjave: data.Datum,
      RazlogOdjave: data.Razlog,
      OdjavaReqMail: 0,
      OdjavaReqTime: new Date(),
      OdjavaReqUser: "",
    };
    handlePostRequest(
      `https://apiaccountant.plc.hr/api/r?m=RadniciUpdateOdjavaReq`,
      [
        {
          user: "igor",
          reqID: data.ImePrezime,
          req: JSON.stringify(RadnikOdjavaRequest),
        },
      ],
      currentUser.token
    )
      .then((response) => {
        if (response.data === "True") {
          setIsLoading({ state: "success", message: "Podaci poslani!" });
          setTimeout(() => {
            setIsLoading({ state: "", message: "" });
            reset({
              ImePrezime: "",
              Datum: new Date(
                getNextWorkDay(new Date(addHours(new Date(), 48)))
              ),
              Razlog: "",
            });
            setDate({
              Datum: new Date(
                getNextWorkDay(new Date(addHours(new Date(), 48)))
              ),
            });
          }, 3000);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    !eLoading &&
    !loading && (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Row lg={4} xs={12}>
              <Column>
                <CardTitle variant="h4">Odjava radnika</CardTitle>
              </Column>
            </Row>
          </Grid>
          <CustomGrid>
            <Row>
              <Column>
                <Grid fullWidth style={{ padding: 0 }}>
                  <Row>
                    <Column sm={4} lg={4}>
                      <FormControl style={{ width: "100%" }} variant="filled">
                        <InputLabel id="select">Ime i prezime</InputLabel>
                        <Controller
                          as={
                            <Select
                              style={{ width: "100%" }}
                              labelId="select"
                              name="ImePrezime"
                              MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                              }}
                            >
                              {employees.map((item) => {
                                return (
                                  <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          }
                          name="ImePrezime"
                          control={control}
                          placeholder="ImePrezime"
                          rules={{
                            required: "ImePrezime je obvezno polje",
                          }}
                          defaultValue=""
                        />
                      </FormControl>
                      <ErrorMessage
                        as={Error}
                        errors={errors}
                        name="ImePrezime"
                      />
                    </Column>
                    <Column sm={4} lg={4}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hr}>
                        <KeyboardDatePicker
                          style={{ width: "100%" }}
                          variant="inline"
                          inputVariant="filled"
                          format="dd.MM.yyyy"
                          margin="normal"
                          name="Datum"
                          value={date.Datum}
                          autoOk
                          id="date-picker-inline"
                          onChange={(date) => {
                            setValue("Datum", date);
                            setDate({
                              ...date,
                              Datum: date,
                            });
                          }}
                          label="Datum"
                          register={register({
                            name: "Datum",
                            type: "text",
                          })}
                          minDate={getNextWorkDay(
                            new Date(addHours(new Date(), 48))
                          )}
                          minDateMessage={`Datum ne može biti manji od ${formatDate(
                            getNextWorkDay(new Date(addHours(new Date(), 48)))
                          )}`}
                          shouldDisableDate={disableHolidays}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Column>
                    <Column sm={4} lg={4}>
                      <FormControl style={{ width: "100%" }} variant="filled">
                        <InputLabel id="select">Razlog</InputLabel>
                        <Controller
                          as={
                            <Select
                              style={{ width: "100%" }}
                              labelId="select"
                              name="Razlog"
                              MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                              }}
                            >
                              {reasons.map((item) => {
                                return (
                                  <MenuItem
                                    key={item.Razlog}
                                    value={item.Razlog}
                                  >
                                    {item.Razlog}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          }
                          name="Razlog"
                          control={control}
                          placeholder="Razlog"
                          rules={{
                            required: "Razlog je obvezno polje",
                          }}
                          defaultValue=""
                        />
                      </FormControl>
                      <ErrorMessage as={Error} errors={errors} name="Razlog" />
                    </Column>
                  </Row>
                </Grid>
              </Column>
            </Row>
          </CustomGrid>
          <Grid>
            <Row style={{ justifyContent: "center" }}>
              <Column
                lg={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                align="center"
              >
                {isLoading.message === "Slanje podataka" ? (
                  <Loading
                    style={{ margin: "auto" }}
                    description="Active loading indicator"
                    withOverlay={false}
                  />
                ) : null}
                {isLoading.message === "Podaci poslani!" ? (
                  <InlineNotification
                    style={{ maxWidth: 400 }}
                    lowContrast
                    hideCloseButton={false}
                    iconDescription="describes the close button"
                    kind={isLoading.state}
                    notificationType="inline"
                    onCloseButtonClick={() =>
                      setIsLoading({ message: "", state: "" })
                    }
                    role="alert"
                    statusIconDescription="describes the status icon"
                    title={isLoading.message}
                  />
                ) : null}
              </Column>
            </Row>
          </Grid>
          <Grid justify="center" style={{ marginTop: 35 }}>
            <Row>
              <Column align="center">
                <Button
                  disabled={isLoading.message.length > 0}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Pošalji
                </Button>
              </Column>
            </Row>
          </Grid>
        </form>
      </>
    )
  );
};

export default EmployeeCheckout;
