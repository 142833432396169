import React from "react";
import { useQuery } from "react-query";
import { Grid, Row, Column, DataTableSkeleton } from "carbon-components-react";
import { Modal } from "./Modal";
import { handlePostRequest } from "../../helpers/handle-response";
import CheckoutTable from "../CheckoutTable";
import { CardTitle } from "../styles";

const ClientCheckoutReview = ({ currentUser, isClient }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);

  const [picked, setPicked] = React.useState(0);

  const { data, isFetching } = useQuery(
    "checkoutsClient",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        `https://apiaccountant.plc.hr/api/r?m=RadniciGets`,
        [{ user: "igor", orgID: currentUser.orgID }]
      );
      const parsedData = JSON.parse(data);
      const formatData = parsedData
        .filter((item) => {
          if (item.odjavaReq.OdjavaReqUser === "waiting") {
            return false;
          } else return true;
        })
        .map((item) => {
          return {
            ...item,
            Radnik: item.info.Ime + " " + item.info.Prezime,
            OrgName: currentUser.companyName,
          };
        })
        .sort((a, b) => {
          return (
            new Date(a.odjavaReq.DatumOdjave) -
            new Date(b.odjavaReq.DatumOdjave)
          );
        });
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );

  return (
    <>
      <Grid>
        <Row lg={6} xs={12}>
          <Column>
            <CardTitle maxwidth="320px" variant="h4">
              Pregled odjava zaposlenika
            </CardTitle>
          </Column>
        </Row>
      </Grid>
      <Grid>
        <Row>
          {!isFetching && data ? (
            <CheckoutTable
              currentUser={currentUser}
              data={data}
              setPicked={setPicked}
              handleOpen={handleOpen}
            />
          ) : (
            <div style={{ width: "100%" }}>
              <DataTableSkeleton showToolbar={false} showHeader={false} />
            </div>
          )}
        </Row>
      </Grid>
      {data ? (
        <Modal
          isClient={isClient}
          currentUser={currentUser}
          open={open}
          handleOpen={handleOpen}
          picked={picked}
          allItems={data}
        ></Modal>
      ) : null}
    </>
  );
};

export default ClientCheckoutReview;
