import { BehaviorSubject } from "rxjs";
import { handleResponse } from "../helpers/handle-response";
import jwt_decode from "jwt-decode";

if (sessionStorage.getItem("currentUser") === "") {
  sessionStorage.removeItem("currentUser");
}
const currentUserSubject = new BehaviorSubject(
  JSON.parse(sessionStorage.getItem("currentUser"))
);
export const authenticationService = {
  login,
  logout,
  register,
  setUser,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function setUser(user) {
  sessionStorage.setItem("currentUser", JSON.stringify(user));
  currentUserSubject.next(user);
  return user;
}
function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  return fetch(`https://www.api.accountant.hr/users/login`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      const decoded = jwt_decode(res);
      const user = {
        id: decoded.id,
        username: decoded.username,
        email: decoded.email,
        gender: decoded.gender,
        OIB: decoded.CompanyOIB,
        companyName: decoded.companyName,
        orgID: decoded.orgID,
        name: decoded.name,
        lastName: decoded.lastName,
        hasPlace: decoded.hasPlace,
        hasPrijava: decoded.hasPrijava,
        token: res,
      };
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      setUser(user);
      return user;
    })
    .catch((error) => {
      return error;
    });
}

function logout() {
  // remove user from local storage to log user out
  sessionStorage.removeItem("currentUser");
  currentUserSubject.next(null);
}

function register(values) {
  const tempData = new FormData();
  for (var value in values) {
    tempData.append(value, values[value]);
    /* if (values[value] === undefined || values[value] === "") {
      tempData.append(value, "");
    } */
  }
  const requestOptions = {
    method: "POST",
    body: tempData,
  };

  return fetch(`https://www.api.accountant.hr/users/register`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log("error: " + error);
    });
}
