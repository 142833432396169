import React from "react";
import { DataTable, Button, Pagination } from "carbon-components-react";
import { Button as MuiButton } from "@material-ui/core";
import { WarningAltFilled32, CheckmarkFilled32 } from "@carbon/icons-react";
const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
} = DataTable;

const ClientTable = ({
  currentUser,
  data,
  date,
  setDate,
  setCurrentItem,
  getItems,
  dispatch,
  reset,
  days,
  setDisableForm,
  history,
  createNew,
  setMonthYear,
}) => {
  const [page, setPage] = React.useState({ current: 1, pageSize: 10 });

  const filteredData = data
    .map((item) => {
      return {
        id: String(item.ID),
        RadnikID: item.RadnikID,
        Pregled: "",
        Naziv: item.RadnikImePrezime,
        Mjesec: `${item.Mjesec}/${item.Godina}`,
        Status: item.Locked,
        Month: item.Mjesec,
      };
    })
    .sort((a, b) => {
      if (a.Month < b.Month) {
        return 1;
      }
      if (a.Month > b.Month) {
        return -1;
      }
      return null;
    });
  const onPageChange = (pageChange) => {
    setPage({ current: pageChange.page, pageSize: pageChange.pageSize });
  };
  var startItem = (page.current - 1) * page.pageSize;
  var endItem = startItem + page.pageSize;
  var displayedRows = filteredData.slice(startItem, endItem);

  const getObject = (id) => {
    const obj = data.find((item) => {
      if (item.ID === Number(id)) {
        return { ...item, ID: Number(item.ID) };
      }
      return null;
    });
    return obj;
  };

  const setSihterica = (row) => {
    const dataRow = getObject(Number(row.id));
    setCurrentItem(dataRow.ID);
    getItems(days, dispatch, dataRow.ID, date);
    reset({
      ImePrezime: dataRow.RadnikID,
      Mjesec: dataRow.Mjesec - 1,
      Godina: new Date(date.setFullYear(dataRow.Godina)),
      ImePrezimeString: dataRow.RadnikImePrezime,
    });
    console.log(new Date(date.setFullYear(dataRow.Godina)));
    const dateToSet = new Date(date.setFullYear(dataRow.Godina));
    setDate(new Date(dateToSet.setMonth(dataRow.Mjesec - 1)));
    setMonthYear(new Date(dateToSet.setMonth(dataRow.Mjesec - 1)));
    setDisableForm(true);
    history.push("/evidencija/pregled");
  };

  return (
    <div style={{ margin: "auto", width: "100%" }}>
      <DataTable
        filterRows={function noRefCheck() {}}
        headers={[
          {
            header: "Pregled",
            key: "Pregled",
          },
          {
            header: "Naziv",
            key: "Naziv",
          },
          {
            header: "Mjesec",
            key: "Mjesec",
          },
          {
            header: "Status",
            key: "Status",
          },
        ]}
        isSortable
        locale="en"
        rows={displayedRows}
        render={({
          rows,
          headers,
          getHeaderProps,
          getTableProps,
          getRowProps,
          getToolbarProps,
        }) => (
          <TableContainer style={{ minWidth: "unset" }}>
            <TableToolbar
              {...getToolbarProps()}
              aria-label="data table toolbar"
            >
              <TableToolbarContent style={{ justifyContent: "flex-start" }}>
                <MuiButton
                  variant="contained"
                  color="primary"
                  onClick={createNew}
                >
                  Nova šihterica
                </MuiButton>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      if (cell.info.header === "Pregled") {
                        return (
                          <TableCell key={cell.id}>
                            <Button
                              onClick={() => {
                                console.log(row);
                                setSihterica(row);
                              }}
                            >
                              Pregled
                            </Button>
                          </TableCell>
                        );
                      } else if (cell.info.header === "Status") {
                        return (
                          <TableCell key={cell.id}>
                            {cell.value === "waiting" ? (
                              <WarningAltFilled32
                                style={{ width: "100%", height: "100%" }}
                                fill="#FD970F"
                              />
                            ) : (
                              <CheckmarkFilled32
                                style={{ width: "100%", height: "100%" }}
                                fill="#4DA851"
                              />
                            )}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={cell.id}>{cell.value}</TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      />
      <Pagination
        style={{ overflow: "hidden" }}
        backwardText="Prethodna stranica"
        disabled={false}
        forwardText="Sljedeća stranica"
        isLastPage={false}
        itemRangeText={function noRefCheck() {}}
        itemText={function noRefCheck() {}}
        itemsPerPageText="Broj stavki po stranici:"
        onChange={onPageChange}
        page={1}
        pageNumberText="Page Number"
        pageRangeText={function noRefCheck() {}}
        pageSize={10}
        pageSizes={[10, 20, 30, 40, 50]}
        pageText={function noRefCheck() {}}
        pagesUnknown={false}
        totalItems={filteredData.length}
      />
    </div>
  );
};

export default ClientTable;
