import React from "react";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import {
  Avatar,
  Button,
  TextField,
  CssBaseline,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  makeStyles,
} from "@material-ui/core";
import { InlineNotification } from "carbon-components-react";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import { Error, CInlineLoading as InlineLoading } from "../styles";
import { authenticationService } from "../../services/authentication";
import axios from "axios";
//import { handlePostRequest } from "../../helpers/handle-response";

/* function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
} */

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
//PLACE => on LOAD
export default function Login({ history }) {
  const [status, setStatus] = React.useState({ msg: "", state: false });
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const { control, handleSubmit, errors } = useForm();
  const onSubmit = ({ username, password }) => {
    setLoading(true);
    authenticationService
      .login(username, password)
      .then(
        (res) => {
          if (res.id) {
            const Form = new FormData();
            Form.append("username", "igor");
            Form.append("password", "parpar66");
            Form.append("app", "minimaxapi");
            axios
              .post("https://apiaccountant.plc.hr/api/p?m=Load", {
                user: "igor",
                password: "parpar66",
              })
              .then((res) => console.log(res));
        /*     handlePostRequest("https://apiaccountant.plc.hr/api/s?m=Load", [
              {
                user: "igor",
                password: "parpar66",
              },
            ]).then((res) => {
              setLoading(false);
              console.log(res);
            }); */
            history.push("/");
          }
          if (typeof res === "string") {
            //Create error msg
            setStatus({ msg: res, state: "error" });
            setTimeout(() => {
              setLoading(false);
              setStatus({ msg: "", error: "" });
            }, 3000);
            console.log(res);
          }
        },
        (error) => {}
      )
      .catch((err) => console.log("catched login err"));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Prijava
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="username"
            defaultValue=""
            rules={{ required: "Korisničko ime je obvezno polje" }}
            as={
              <TextField
                variant="filled"
                margin="normal"
                fullWidth
                id="username"
                label="Korisničko ime"
                autoComplete="username"
                autoFocus
              />
            }
          ></Controller>
          <ErrorMessage as={Error} errors={errors} name="username" />
          <Controller
            control={control}
            name="password"
            defaultValue=""
            rules={{ required: "Lozinka je obvezno polje" }}
            as={
              <TextField
                variant="filled"
                margin="normal"
                fullWidth
                label="Lozinka"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            }
          ></Controller>
          <ErrorMessage as={Error} errors={errors} name="password" />
          {/*  <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            color="primary"
            className={classes.submit}
            style={{ overflow: "hidden" }}
          >
            {loading ? (
              <InlineLoading
                style={{ justifyContent: "center" }}
                iconDescription="Active loading indicator"
                description="Učitavanje..."
                spancolor="black"
                status="active"
                successDelay={1500}
              />
            ) : (
              "Prijava"
            )}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Zaboravili ste lozinku?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Nemate račun? Registrirajte se"}
              </Link>
            </Grid>
            {status.msg.length > 1 ? (
              <InlineNotification
                style={{ maxWidth: 400 }}
                lowContrast
                hideCloseButton={false}
                iconDescription="describes the close button"
                kind={status.state}
                notificationType="inline"
                onCloseButtonClick={() => setStatus({ msg: "", state: "" })}
                role="alert"
                statusIconDescription="describes the status icon"
                title={status.msg}
              />
            ) : null}
          </Grid>
        </form>
      </div>
      <Box mt={8}>{/*  <Copyright /> */}</Box>
    </Container>
  );
}
