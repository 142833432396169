import React from "react";
import hr from "date-fns/locale/hr";
import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Row, Column, InlineNotification } from "carbon-components-react";
import {
  Typography,
  Button,
  TextField,
  Dialog,
  IconButton,
} from "@material-ui/core";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import NumberFormat from "react-number-format";
import { formatDate } from "../../functions/helpers";
import { formatUTCDate } from "./constants";

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { Error } from "../styles";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, disabled, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          disabled={disabled.state.length > 0 ? true : false}
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const Modal = ({
  open,
  handleClose,
  itemsData,
  mainData,
  setData,
  employees,
}) => {
  const { errors, control, handleSubmit, register, setValue, getValues } =
    useForm({
      defaultValues: { Iznos: 250, IznosDan: 250 },
    });
  const [dates, setDates] = React.useState({
    DatumOdlaska: new Date(),
    DatumPovratka: new Date(),
  });
  const [status, setStatus] = React.useState({ state: "", message: "" });
  const submit = (data) => {
    //add date check

    const employeeData = employees.find((item) => {
      if (item.value === mainData.ImePrezime) {
        return item;
      }
      return null;
    });
    const days = Math.ceil(data.Iznos / data.IznosDan);
    const odlazak = dayjs(dates.DatumOdlaska);
    const povratak = dayjs(dates.DatumPovratka);
    const numOfDays = povratak.diff(dayjs(formatUTCDate(odlazak)), "day") + 1;

    if (
      odlazak.isSameOrAfter(dayjs(employeeData.start)) &&
      povratak.isSameOrBefore(
        dayjs(employeeData.stop ? employeeData.stop : new Date())
      )
    ) {
      if (days === numOfDays) {
        const object = {
          id: String(itemsData.length + 1),
          DatumOdlazak: formatDate(dates.DatumOdlaska, true),
          DatumPovratak: formatDate(dates.DatumPovratka, true),
          BrojDana: days,
          SvotaPoDanu: data.IznosDan,
          UkupnaSvota: data.Iznos,
          SvotaTroskova: 0,
          UkupnoIsplata: data.Iznos,
        };
        setData((data) => [...data, object]);
        if (status.state.length === 0) {
          handleClose();
        }
      } else {
        setStatus({
          state: "error",
          message: `Broj dana (${numOfDays}) ne odgovara broju dana prema izračunu (${days}) `,
        });
      }
    } else {
      setStatus({
        state: "error",
        message: `Odabrani datumi nisu unutar razdoblja u kojem je radnik zaposlen.`,
      });
    }
    setTimeout(() => {
      setStatus({ state: "", message: "" });
    }, 5000);
  };
  return (
    <div>
      {open ? (
        <Dialog
          fullWidth
          maxWidth={"md"}
          onClose={status.state.length > 0 ? null : handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            disabled={status}
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Novi unos terenskog dodatka
          </DialogTitle>
          <DialogContent dividers style={{ maxWidth: "100%" }}>
            <Grid>
              <Row>
                <Column>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hr}>
                    <KeyboardDatePicker
                      variant="inline"
                      inputVariant="filled"
                      autoOk
                      format="dd.MM.yyyy"
                      label="Datum odlaska"
                      value={dates.DatumOdlaska}
                      register={register({
                        name: "DatumOdlaska",
                      })}
                      onChange={(date) => {
                        setDates({ ...dates, DatumOdlaska: date });
                        setValue("DatumOdlaska", date);
                        const days =
                          dayjs(dates.DatumPovratka).diff(dayjs(date), "days") +
                          1;
                        setValue("Iznos", days * Number(getValues("IznosDan")));
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Column>
                <Column>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hr}>
                    <KeyboardDatePicker
                      variant="inline"
                      inputVariant="filled"
                      autoOk
                      format="dd.MM.yyyy"
                      label="Datum povratka"
                      value={dates.DatumPovratka}
                      register={register({
                        name: "DatumPovratka",
                      })}
                      onChange={(date) => {
                        setDates({ ...dates, DatumPovratka: date });
                        setValue("DatumPovratka", date);
                        const days =
                          dayjs(date).diff(dayjs(dates.DatumPovratka), "days") +
                          1;
                        setValue("Iznos", days * Number(getValues("IznosDan")));
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Column>
                <Column>
                  <Controller
                    as={
                      <NumberFormat
                        label="Iznos HRK"
                        type="text"
                        autoComplete="off"
                        variant="filled"
                        customInput={TextField}
                      ></NumberFormat>
                    }
                    defaultValue={250}
                    name="IznosDan"
                    control={control}
                    onChange={([event]) => {
                      const days =
                        dayjs(dates.DatumPovratka).diff(
                          dayjs(dates.DatumOdlaska),
                          "days"
                        ) + 1;
                      setValue("Iznos", days * Number(event.target.value));
                      return event.target.value;
                    }}
                    rules={{
                      required: "Iznos je obvezno polje",
                    }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="IznosDan" />
                </Column>
                <Column>
                  <Controller
                    as={
                      <NumberFormat
                        label="Ukupan iznos HRK"
                        type="text"
                        autoComplete="off"
                        variant="filled"
                        customInput={TextField}
                      ></NumberFormat>
                    }
                    name="Iznos"
                    control={control}
                    defaultValue={250}
                    rules={{
                      required: "Iznos je obvezno polje",
                    }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="Iznos" />
                </Column>
              </Row>
              <Row>
                <Grid>
                  <Row style={{ marginTop: 20 }}>
                    <Column>
                      {status.message.length > 0 ? (
                        <InlineNotification
                          style={{ maxWidth: 600 }}
                          lowContrast
                          hideCloseButton={false}
                          iconDescription="describes the close button"
                          kind={status.state}
                          notificationType="inline"
                          onCloseButtonClick={() =>
                            setStatus({ message: "", state: "" })
                          }
                          role="alert"
                          statusIconDescription="describes the status icon"
                          title={status.message}
                        />
                      ) : null}
                    </Column>
                  </Row>
                </Grid>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Column align="end">
                  <Button
                    disabled={status.state === "error" ? true : false}
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit(submit)}
                  >
                    Dodaj
                  </Button>
                </Column>
              </Row>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  );
};

export default Modal;
