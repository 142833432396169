export const sums = [
  "NocniRedovanRad",
  "NocniPrekovremeni",
  "NocniBlagdanNeradni",
  "DanjiRedovanRad",
  "DanjiPrekovremeni",
  "DanjiBlagdanNeradni",
  /*  "TerenskiRad",
  "SatiPripravnosti", */
  "DnevniOdmor",
  "TjedniOdmor",
  "GodisnjiOdmor",
  "PlaceniNDBlagdani",
  "Bolovanje",
  "KomplikacijeUTrudnoci",
  "RodiljniDopust",
  "RoditeljskiDopust",
  "MirovanjeRadnogOdnosa",
  "paidHolidays",
  "KoristenjeDrugihPrava",
  "SatiPlacenogDopusta",
  "SatiNeplacenogDopusta",
  "IzostanakNaZahtjev",
  "IzostanakBezOdobrenja",
  "SatiUStrajku",
  "SatiIskljucenjaSRada",
];
export const valuesToChoose = [
  "TerenskiRad",
  "SatiPripravnosti",
  "DnevniOdmor",
  "TjedniOdmor",
  "GodisnjiOdmor",
  "PlaceniNDBlagdani",
  "Bolovanje",
  "KomplikacijeUTrudnoci",
  "RodiljniDopust",
  "RoditeljskiDopust",
  "MirovanjeRadnogOdnosa",
  "KoristenjeDrugihPrava",
  "SatiPlacenogDopusta",
  "SatiNeplacenogDopusta",
  "IzostanakNaZahtjev",
  "IzostanakBezOdobrenja",
  "SatiUStrajku",
  "SatiIskljucenjaSRada",
];
export const choosenValuesEnum = {
  TerenskiRad: "Terenski rad",
  SatiPripravnosti: "Sati pripravnosti, te sati po pozivu",
  DnevniOdmor: "Dnevni odmor",
  TjedniOdmor: "Tjedni odmor",
  GodisnjiOdmor: "Godišnji odmor",
  PlaceniNDBlagdani: "Plaćeni neradni dani i blagdani",
  Bolovanje: "Privremena nesposobnost za rad (bolovanje)",
  KomplikacijeUTrudnoci: "Komplikacije u trudnoći",
  RodiljniDopust: "Rodiljni dopust",
  RoditeljskiDopust: "Roditeljski dopust",
  MirovanjeRadnogOdnosa: "Mirovanje radnog odnosa",
  KoristenjeDrugihPrava: "Korištenje drugih prava sukladno drugim propisima",
  SatiPlacenogDopusta: "Sati plaćenog dopusta",
  SatiNeplacenogDopusta: "Sati NEplaćenog dopusta",
  IzostanakNaZahtjev: "Izostanak na zahtjev radnika",
  IzostanakBezOdobrenja: "Izostanak s posla bez odobrenja",
  SatiUStrajku: "Sati provedeni u štrajku",
  SatiIskljucenjaSRada: "Sati isključenja s rada (lockout)",
};
export const months = {
  January: "Sijecanj",
  February: "Veljaca",
  March: "Ozujak",
  April: "Travanj",
  May: "Svibanj",
  June: "Lipanj",
  July: "Srpanj",
  August: "Kolovoz",
  September: "Rujan",
  October: "Listopad",
  November: "Studeni",
  December: "Prosinac",
};
export const mjeseci = {
  0: "Sijecanj",
  1: "Veljaca",
  2: "Ozujak",
  3: "Travanj",
  4: "Svibanj",
  5: "Lipanj",
  6: "Srpanj",
  7: "Kolovoz",
  8: "Rujan",
  9: "Listopad",
  10: "Studeni",
  11: "Prosinac",
};
export const monthsStringToNum = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};
export const monthsNum = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};
export const monthHours = {
  "2019January": 184,
  "2019February": 160,
  "2019March": 168,
  "2019April": 176,
  "2019May": 184,
  "2019June": 160,
  "2019July": 184,
  "2019August": 176,
  "2019September": 168,
  "2019October": 184,
  "2019November": 168,
  "2019December": 176,
  "2020January": 184,
  "2020February": 160,
  "2020March": 176,
  "2020April": 176,
  "2020May": 168,
  "2020June": 176,
  "2020July": 184,
  "2020August": 168,
  "2020September": 176,
  "2020October": 176,
  "2020November": 168,
  "2020December": 184,
};
export const numberOfDays = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
];

export const initialDays = [
  {
    id: "1",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "2",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "3",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "4",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "5",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "6",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "7",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "8",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "9",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "10",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "11",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "12",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "13",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "14",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "15",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "16",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "17",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "18",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "19",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "20",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "21",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "22",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "23",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "24",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "25",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "26",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "27",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "28",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "29",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "30",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
  {
    id: "31",
    PocetakRada: null,
    ZavrsetakRada: null,
    UkupnoDnevnoRV: null,
    SatiZastojFrom: null,
    SatiZastojTill: null,
    ID: 0,

    NocniRedovanRad: null,
    NocniPrekovremeni: null,
    NocniBlagdanNeradni: null,
    DanjiRedovanRad: null,
    DanjiPrekovremeni: null,
    DanjiBlagdanNeradni: null,
    TerenskiRad: null,
    SatiPripravnosti: null,
    DnevniOdmor: null,
    TjedniOdmor: null,
    GodisnjiOdmor: null,
    PlaceniNDBlagdani: null,
    Bolovanje: null,
    KomplikacijeUTrudnoci: null,
    RodiljniDopust: null,
    RoditeljskiDopust: null,
    MirovanjeRadnogOdnosa: null,

    KoristenjeDrugihPrava: null,
    SatiPlacenogDopusta: null,
    SatiNeplacenogDopusta: null,
    IzostanakNaZahtjev: null,
    IzostanakBezOdobrenja: null,
    SatiUStrajku: null,
    SatiIskljucenjaSRada: null,
  },
];
