import React from "react";
import { createStore } from "little-state-machine";
import { Grid, Row, Column } from "carbon-components-react";
import { CardTitle } from "../styles";
import { addHours, getNextWorkDay } from "../../functions/helpers";
//import { Overlay } from "../styles";
import { handlePostRequest } from "../../helpers/handle-response";
import Stepper from "./Stepper";

createStore({
  data: {
    Ime: "",
    Prezime: "",
    OIB: "",
    Adresa: "",
    Spol: "zenski",
    OsobniBrojOsiguranika: "",
    DatumRodenja: new Date(),
    MIO2: 1,
    StrucnaSprema: "",
    NajvisaZavrsenaSkola: "",
    IBANredovni: "",
    IBANzasticeni: "",
    DatumPocetkaRada: new Date(
      getNextWorkDay(new Date(addHours(new Date(), 48)))
    ),
    RadnoMjesto: "",
    StrucnaSpremaZaObavljanjePosla: "",
    RadnoVrijemeTjedno: "",
    Bruto1placa: "",
    Prijevoz: "",
    Invalidnost: 0,
    Napomene: "",
  },
});

const EmployeeApplication = ({ currentUser }) => {
  const [isLoading, setIsLoading] = React.useState({
    state: false,
    message: "",
  });
  const onSubmit = (data) => {
    setIsLoading({ state: true, message: "Slanje podataka" });
    const RadnikInfo = {
      RadnikID: -2,
      Ime: data.Ime,
      Prezime: data.Prezime,
      OIB: data.OIB,
      Adresa: data.Adresa,
      Spol: data.Spol,
      OsobniBrojOsiguranika: data.OsobniBrojOsiguranika,
      DatumRodenja: data.DatumRodenja,
      MIO2: data.MIO2,
      StrucnaSprema: data.StrucnaSprema,
      NajvisaZavrsenaSkola: data.NajvisaZavrsenaSkola,
      IBANredovni: data.IBANredovni,
      IBANzasticeni: data.IBANzasticeni,
      DatumPocetkaRada: data.DatumPocetkaRada,
      RadnoMjesto: data.RadnoMjesto,
      StrucnaSpremaZaObavljanjePosla: data.StrucnaSpremaZaObavljanjePosla,
      RadnoVrijemeTjedno: Number(data.RadnoVrijemeTjedno),
      Bruto1placa: Number(data.Bruto1placa.replace(".", "").replace(",", ".")),
      Prijevoz: data.Prijevoz
        ? Number(data.Prijevoz.replace(".", "").replace(",", "."))
        : 0,
      Invalidnost: data.Invalidnost,
      Napomene: data.Napomene,
      UpdateTime: new Date(),
      UpdateUser: "",
    };
    const RadnikReponse = {
      File1Ref: "",
      File2Ref: "",
      File3Ref: "",
      MailSentResponse: 0,
      UpdateResponse: new Date(),
      UserResponse: "",
    };
    const RadnikAck = {
      DnlFile1: "",
      DnlFile2: "",
      DnlFile3: "",
      UpdateAck: new Date(),
      UserAck: "",
    };

    const RadnikDB = {
      ID: 0,
      OrganizationID: currentUser.orgID,
      info: JSON.stringify(RadnikInfo),
      response: JSON.stringify(RadnikReponse),
      ack: JSON.stringify(RadnikAck),
    };
    handlePostRequest(
      "https://apiaccountant.plc.hr/api/r?m=RadniciAddRequest",
      [
        {
          user: "igor",
          orgID: currentUser.orgID,
          info: JSON.stringify(RadnikInfo),
        },
      ]
    )
      .then((res) => {
        if (res.data === "True") {
          setIsLoading({ state: true, message: "Podaci poslani!" });
          setTimeout(() => {
            setIsLoading({ state: false, message: "" });
            // add reset here
         /*    reset(
              {
                Ime: "",
                Prezime: "",
                OIB: "",
                Adresa: "",
                Spol: "",
                OsobniBrojOsiguranika: "",
                DatumRodenja: new Date(),
                MIO2: "",
                StrucnaSprema: "",
                NajvisaZavrsenaSkola: "",
                IBANredovni: "",
                IBANzasticeni: "",
                DatumPocetkaRada: new Date(
                  getNextWorkDay(new Date(addHours(new Date(), 48)))
                ),
                RadnoMjesto: "",
                StrucnaSpremaZaObavljanjePosla: "",
                RadnoVrijemeTjedno: "",
                Bruto1placa: "",
                Prijevoz: "",
                Invalidnost: "",
                Napomene: "",
              },
              {
                errors: true, // errors will not be reset
                dirtyFields: true, // dirtyFields will not be reset
                dirty: false,
                isSubmitted: false,
                touched: false,
                isValid: false,
                submitCount: false,
              }
            ); */
          }, 3000);
        }
      })
      .catch((err) => console.log(err));
    /* setTimeout(() => {
      //this one emulates axios request
      setIsLoading({ state: true, message: "Podaci poslani!" });
      setTimeout(() => {
        setIsLoading({ state: false, message: "" });
        reset(
          {
            Ime: "",
            Prezime: "",
            OIB: "",
            Adresa: "",
            Spol: "",
            OsobniBrojOsiguranika: "",
            DatumRodenja: new Date(),
            MIO2: "",
            StrucnaSprema: "",
            NajvisaZavrsenaSkola: "",
            IBANredovni: "",
            IBANzasticeni: "",
            DatumPocetkaRada: addHours(new Date(), 48),
            RadnoMjesto: "",
            StrucnaSpremaZaObavljanjePosla: "",
            RadnoVrijemeTjedno: "",
            Bruto1placa: "",
            Prijevoz: "",
            Invalidnost: "",
            Napomene: ""
          },
          {
            errors: true, // errors will not be reset
            dirtyFields: true, // dirtyFields will not be reset
            dirty: false,
            isSubmitted: false,
            touched: false,
            isValid: false,
            submitCount: false
          }
        );
      }, 3000);
    }, 3000); */

    console.log(RadnikDB);
  };
/*   const [selectedDate, setSelectedDate] = React.useState({
    DatumRodenja: new Date(),
    DatumPocetkaRada: new Date(
      getNextWorkDay(new Date(addHours(new Date(), 48)))
    ),
  }); */
  return (
    <>
      <Grid style={{ marginBottom: 60 }}>
        <Row>
          <Column sm={4} lg={6}>
            <CardTitle maxwidth="300px">Prijava novog radnika</CardTitle>
          </Column>
        </Row>
        <Row style={{ justifyContent: "space-around" }}>
          <Stepper onSubmit={onSubmit} isLoading={isLoading} />
        </Row>
      </Grid>
    {/*   <Overlay display={isLoading.state ? "block" : "none"} top="48px">
        <Grid>
          <Row style={{ justifyContent: "center" }}>
            <Column
              lg={5}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {isLoading.message === "Slanje podataka" ? (
                <Loading
                  style={{ margin: "auto" }}
                  description="Active loading indicator"
                  withOverlay={false}
                />
              ) : null}
              {isLoading.message === "Podaci poslani!" ? (
                <CheckmarkFilled32
                  style={{ width: "100%", height: "150px" }}
                  fill="#4DA851"
                ></CheckmarkFilled32>
              ) : null}
              <h2 style={{ margin: "auto" }}>{isLoading.message}</h2>
            </Column>
          </Row>
        </Grid>
      </Overlay> */}
    </>
  );
};

export default EmployeeApplication;
