import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { InlineNotification, Grid, Row, Column } from "carbon-components-react";
import DateFnsUtils from "@date-io/date-fns";
import hr from "date-fns/locale/hr";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import NumberFormat from "react-number-format";
import { Error } from "../styles";
//import { formatDate, getDiff } from "../../functions/helpers";
import { handlePostRequest } from "../../helpers/handle-response";
import { makeStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  "@media(max-width: 959px)": {
    root: {
      marginTop: 40,
    },
  },
});
const useStyles = makeStyles({
  root: {
    "& label.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.54) !important",
    },
    "& input.Mui-disabled": {
      color: "black !important",
    },
  },
});

const DialogTitleCustom = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

export const AddNewModal = ({
  open,
  handleOpen,
  currentUser,
  initialValues,
  getObject,
  pickedID,
  filteredData,
  fetchData,
  filterData,
  refresh,
}) => {
  const { errors, control, handleSubmit, register, reset, setValue } = useForm({
    defaultValues: {
      Datum: new Date(),
      OIB: currentUser.OIB,
      Tvrtka: currentUser.companyName,
      ImePrezime: "",
    },
  });
  const [status, setStatus] = React.useState({ msg: "", state: "" });
  const [error, setError] = React.useState({ msg: "", state: "" });
  const [date, setDate] = React.useState(new Date());
  const [time, setTime] = React.useState({
    from: new Date(),
    till: new Date(),
  });
  const [totalValues, setTotalValues] = React.useState({
    totalKM: "",
    Nadoknada: "",
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const newRequest = (data) => {
    //Add completly new request if items are 0
    console.log("new request");
    const LocoRequest = {
      OrganizationNaziv: currentUser.companyName,
      OrganizationOIB: currentUser.OIB,
      Godina: initialValues.Datum.getFullYear(),
      Mjesec: initialValues.Datum.getMonth() + 1,
      RadnikID: initialValues.ImePrezime,
      RadnikImePrezime: initialValues.ImePrezimeString,
      DatumObracuna: initialValues.DatumObracuna,
      VoziloMarka: data.Marka,
      VoziloRegistracija: data.Registracija,
      MailSentRequest: 0,
      UpdateTime: new Date(),
      CreatedByUser: "",
    };
    const LocoResponse = {
      File1Ref: "",
      MailSentResponse: 0,
      ResponseTime: new Date(),
      ResponseUser: "",
    };
    const LocoAck = {
      File1Ack: "",
      AckTime: new Date(),
      AckUser: "",
    };
    const LocoVoznjaItem = {
      ID: 0,
      LocoID: 0,
      DatumUnosa: data.Datum,
      VoziloID: 0,
      BrojiloPocetno: Number(data.PocetnoStanje),
      BrojiloZavrsno: Number(data.ZavrsnoStanje),
      Relacija: data.Relacija,
      VrijemeFrom: time.from,
      VrijemeTill: time.till,
      PrijedeniKilometri: Number(data.PrijedeniKm),
      NadoknadaKune: Number(totalValues.Nadoknada),
      Izvjesce: data.Izvjesce,
      UpdateTime: new Date(),
      CreatedByUser: "",
    };
    const LocoVoznja = {
      ID: 0,
      OrganizationID: currentUser.orgID,
      request: LocoRequest,
      response: LocoResponse,
      ack: LocoAck,
      items: [LocoVoznjaItem],
    };

    const addData = async () => {
      const result = await handlePostRequest(
        `https://apiaccountant.plc.hr/api/loco?m=LocoVoznjaAddRequest`,
        [{ user: "igor", lv: LocoVoznja }]
      );
      if (result.data === "True") {
        setStatus({ msg: "Novi unos spremljen.", state: "success" });
        setTimeout(() => {
          handleOpen();
          refresh();
          setStatus({ msg: "", state: "" });
          setTime({ from: new Date(), till: new Date() });
          setTotalValues({ totalKm: "", Nadoknada: "" });
          setDate(new Date());
          reset({
            PocetnoStanje: "",
            ZavrsnoStanje: "",
            Relacija: "",
          });
        }, 3000);
      }
    };
    console.log(LocoVoznja);

    /*     BrojiloPocetno: Number(data.PocetnoStanje),
      BrojiloZavrsno: Number(data.ZavrsnoStanje),
   
      PrijedeniKilometri: Number(data.PrijedeniKm), */
    const diff = Number(data.ZavrsnoStanje) - Number(data.PocetnoStanje);
    if (Number(data.PocetnoStanje) > Number(data.ZavrsnoStanje)) {
      //unijeli ste zavrsno stanje manje od pocetnog
      setError({
        msg: "Unijeli ste završno stanje manje od početnog",
        state: "error",
      });
      setTimeout(() => {
        setError({ msg: "", state: "" });
      }, 2500);
    } else if (Number(data.PrijedeniKm) > diff) {
      //unijeli ste prijedene kilometre vece od razlike pocetnog i zavrsnog stanja
      setError({
        msg:
          "Unijeli ste prijeđene kilometre veće od razlike početnog i završnog stanja",
        state: "error",
      });
    } else {
      addData();
    }
  };

  const addNewItem = (data) => {
    //Add new item to existing Request if Items exist and car is picked
    console.log("add existing", data, time);
    const LocoVoznjaItem = {
      ID: 0,
      LocoID: pickedID.ID,
      DatumUnosa: data.Datum,
      VoziloID: 0,
      BrojiloPocetno: Number(data.PocetnoStanje),
      BrojiloZavrsno: Number(data.ZavrsnoStanje),
      Relacija: data.Relacija,
      VrijemeFrom: time.from,
      VrijemeTill: time.till,
      PrijedeniKilometri: Number(data.PrijedeniKm),
      NadoknadaKune: Number(totalValues.Nadoknada),
      Izvjesce: data.Izvjesce,
      UpdateTime: new Date(),
      CreatedByUser: "",
    };

    const addData = async () => {
      const result = await handlePostRequest(
        `https://apiaccountant.plc.hr/api/loco?m=LocoVoznjaAddItem`,
        [{ user: "igor", lvi: LocoVoznjaItem }]
      );
      if (result.data === '"True"') {
        setStatus({ msg: "Novi unos spremljen.", state: "success" });
        setTimeout(() => {
          setStatus({ msg: "", state: "" });
          setTime({ from: new Date(), till: new Date() });
          setTotalValues({ totalKm: "", Nadoknada: "" });
          setDate(new Date());
          reset({
            PocetnoStanje: "",
            ZavrsnoStanje: "",
            Relacija: "",
          });
          fetchData(pickedID.ID);
        }, 3000);
      }
    };
    console.log(LocoVoznjaItem);
    const diff = Number(data.ZavrsnoStanje) - Number(data.PocetnoStanje);

    if (Number(data.PocetnoStanje) > Number(data.ZavrsnoStanje)) {
      //unijeli ste zavrsno stanje manje od pocetnog

      setError({
        msg: "Unijeli ste završno stanje manje od početnog",
        state: "error",
      });
      setTimeout(() => {
        setError({ msg: "", state: "" });
      }, 2500);
    } else if (Number(data.PrijedeniKm) > diff) {
      //unijeli ste prijedene kilometre vece od razlike pocetnog i zavrsnog stanja
      setError({
        msg:
          "Unijeli ste prijeđene kilometre veće od razlike početnog i završnog stanja",
        state: "error",
      });
    } else {
      addData();
    }
  };
  const classes = useStyles();

  return (
    <>
      {open &&
        (status.msg.length === 0 ? (
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleOpen}
            maxWidth="lg"
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitleCustom
              id="responsive-dialog-title"
              onClose={handleOpen}
            >
              {currentUser.companyName}
            </DialogTitleCustom>
            <DialogContent>
              <h3 style={{ marginBottom: 10 }}>Dodaj novi unos</h3>
              <h5>
                Za mjesec:{" "}
                {initialValues.Datum.getMonth() +
                  1 +
                  " / " +
                  initialValues.Datum.getFullYear()}{" "}
              </h5>
              <h5>Radnik: {getObject(initialValues.ImePrezime).label}</h5>

              <br />

              <Grid style={{ marginTop: 20, maxWidth: 750 }}>
                <form
                  onSubmit={handleSubmit(
                    filteredData.length > 0 ? addNewItem : newRequest
                  )}
                >
                  <Row>
                    <Column lg={6} className="bx--offset-lg-3">
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hr}>
                        <DatePicker
                          style={{
                            width: "100%",
                            marginTop: "1rem",
                            marginBotton: "0.7rem",
                          }}
                          variant="inline"
                          inputVariant="filled"
                          format="dd.MM.yyyy"
                          margin="normal"
                          name="Datum"
                          value={date.Datum}
                          autoOk
                          id="date-picker-inline"
                          onChange={(date) => {
                            setValue("Datum", date);
                            setDate({
                              ...date,
                              Datum: date,
                            });
                          }}
                          label="Datum"
                          register={register({
                            name: "Datum",
                          })}
                        />
                      </MuiPickersUtilsProvider>
                    </Column>
                    {/* disable vozilo and reg if vozilo picked */}
                    <Column lg={6}>
                      <Controller
                        as={
                          <TextField
                            style={{
                              width: "100%",
                              marginTop: "1rem",
                              marginBotton: "0.7rem",
                            }}
                            autoComplete="off"
                            label="Marka vozila"
                            type="text"
                            variant="filled"
                          />
                        }
                        name="Marka"
                        control={control}
                        defaultValue={
                          pickedID !== undefined
                            ? pickedID.request.VoziloMarka
                            : ""
                        }
                        disabled={pickedID !== undefined ? true : false}
                        rules={{
                          required: "Marka vozila je obvezno polje",
                        }}
                      />
                      <ErrorMessage as={Error} errors={errors} name="Marka" />
                    </Column>
                    <Column lg={6}>
                      <Controller
                        as={
                          <TextField
                            style={{
                              width: "100%",
                              marginTop: "1rem",
                              marginBotton: "0.7rem",
                            }}
                            autoComplete="off"
                            label="Registracijski broj"
                            type="text"
                            variant="filled"
                          />
                        }
                        name="Registracija"
                        control={control}
                        defaultValue={
                          pickedID !== undefined
                            ? pickedID.request.VoziloRegistracija
                            : ""
                        }
                        disabled={pickedID !== undefined ? true : false}
                        rules={{
                          required: "Registracijski broj je obvezno polje",
                        }}
                      />
                      <ErrorMessage
                        as={Error}
                        errors={errors}
                        name="Registracija"
                      />
                    </Column>
                    <Column lg={6}>
                      <Controller
                        as={
                          <NumberFormat
                            style={{
                              width: "100%",
                              marginTop: "1rem",
                              marginBotton: "0.7rem",
                            }}
                            label="Početno stanje"
                            type="text"
                            isNumericString
                            autoComplete="off"
                            variant="filled"
                            customInput={TextField}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  KM
                                </InputAdornment>
                              ),
                            }}
                          ></NumberFormat>
                        }
                        name="PocetnoStanje"
                        control={control}
                        onValueChange={(e) => {
                          setValue("PocetnoStanje", e.value);
                        }}
                        defaultValue={currentUser.PocetnoStanje}
                        rules={{
                          required: "Početno stanje je obvezno polje",
                        }}
                      />
                      <ErrorMessage
                        as={Error}
                        errors={errors}
                        name="PocetnoStanje"
                      />
                    </Column>
                    <Column lg={6}>
                      <Controller
                        as={
                          <NumberFormat
                            style={{
                              width: "100%",
                              marginTop: "1rem",
                              marginBotton: "0.7rem",
                            }}
                            label="Završno stanje"
                            type="text"
                            isNumericString
                            autoComplete="off"
                            variant="filled"
                            customInput={TextField}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  KM
                                </InputAdornment>
                              ),
                            }}
                          ></NumberFormat>
                        }
                        name="ZavrsnoStanje"
                        control={control}
                        onValueChange={(e) => {
                          setValue("ZavrsnoStanje", e.value);
                        }}
                        defaultValue={currentUser.ZavrsnoStanje}
                        rules={{
                          required: "Završno stanje je obvezno polje",
                        }}
                      />
                      <ErrorMessage
                        as={Error}
                        errors={errors}
                        name="ZavrsnoStanje"
                      />
                    </Column>
                    <Column lg={6}>
                      <Controller
                        as={
                          <TextField
                            style={{
                              width: "100%",
                              marginTop: "1rem",
                              marginBotton: "0.7rem",
                            }}
                            autoComplete="off"
                            label="Relacija"
                            type="text"
                            variant="filled"
                          />
                        }
                        name="Relacija"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Relacija je obvezno polje",
                        }}
                      />
                      <ErrorMessage
                        as={Error}
                        errors={errors}
                        name="Relacija"
                      />
                    </Column>
                    <Column sm={2} lg={3}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hr}>
                        <KeyboardTimePicker
                          ampm={false}
                          margin="normal"
                          id="time-picker"
                          inputVariant="filled"
                          label="Vrijeme od"
                          value={time.from}
                          onChange={(date) => {
                            setTime({ ...time, from: date });
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "postavi vrijeme od",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <ErrorMessage
                        as={Error}
                        errors={errors}
                        name="VrijemeOd"
                      />
                    </Column>
                    <Column sm={2} lg={3}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hr}>
                        <KeyboardTimePicker
                          ampm={false}
                          margin="normal"
                          id="time-picker"
                          inputVariant="filled"
                          label="Vrijeme do"
                          value={time.till}
                          onChange={(date) => {
                            setTime({ ...time, till: date });
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "postavi vrijeme do",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <ErrorMessage
                        as={Error}
                        errors={errors}
                        name="VrijemeDo"
                      />
                    </Column>
                    <Column lg={6}>
                      <Controller
                        as={
                          <NumberFormat
                            style={{
                              width: "100%",
                              marginTop: "1rem",
                              marginBotton: "0.7rem",
                            }}
                            label="Prijeđeni kilometri"
                            type="text"
                            isNumericString
                            autoComplete="off"
                            variant="filled"
                            customInput={TextField}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  KM
                                </InputAdornment>
                              ),
                            }}
                          ></NumberFormat>
                        }
                        name="PrijedeniKm"
                        control={control}
                        onValueChange={(e) => {
                          setValue("PrijedeniKm", e.value);
                          setTotalValues({
                            ...totalValues,
                            Nadoknada: Number(e.value) * 2,
                          });
                        }}
                        defaultValue={currentUser.PrijedeniKm}
                        rules={{
                          required: "Početno stanje je obvezno polje",
                        }}
                      />
                      <ErrorMessage
                        as={Error}
                        errors={errors}
                        name="PrijedeniKm"
                      />
                    </Column>
                    <Column lg={6}>
                      <NumberFormat
                        style={{
                          width: "100%",
                          marginTop: "1rem",
                          marginBotton: "0.7rem",
                        }}
                        classes={{ root: classes.root }}
                        label="Nadoknada"
                        type="text"
                        isNumericString
                        disabled
                        autoComplete="off"
                        variant="filled"
                        customInput={TextField}
                        name="Nadoknada"
                        value={totalValues.Nadoknada}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">HRK</InputAdornment>
                          ),
                        }}
                      ></NumberFormat>
                    </Column>
                    <Column lg={12}>
                      <Controller
                        as={
                          <TextField
                            multiline
                            rows="3"
                            style={{
                              width: "100%",
                              marginTop: "1rem",
                              marginBotton: "0.7rem",
                            }}
                            autoComplete="off"
                            label="Izvješće"
                            type="text"
                            variant="filled"
                          />
                        }
                        name="Izvjesce"
                        control={control}
                        defaultValue=""
                      />
                      <ErrorMessage
                        as={Error}
                        errors={errors}
                        name="Izvjesce"
                      />
                    </Column>
                    {error.msg.length > 0 ? (
                      <Grid>
                        <Row>
                          <Column>
                            <InlineNotification
                              style={{ maxWidth: 400 }}
                              lowContrast
                              hideCloseButton={false}
                              iconDescription="describes the close button"
                              kind={error.state}
                              notificationType="inline"
                              onCloseButtonClick={() =>
                                setError({ msg: "", state: "" })
                              }
                              role="alert"
                              statusIconDescription="describes the status icon"
                              title={error.msg}
                            />
                          </Column>
                        </Row>
                      </Grid>
                    ) : null}
                    <Column
                      lg={12}
                      align="center"
                      style={{ marginTop: 20, marginBottom: 20 }}
                    >
                      <Button type="submit" variant="contained">
                        Spremi
                      </Button>
                    </Column>
                  </Row>
                </form>
              </Grid>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        ) : (
          <Dialog
            open={status.msg.length !== 0 ? true : false}
            onClose={() => setStatus({ msg: "", state: "" })}
          >
            <Grid>
              <Row>
                <Column>
                  <InlineNotification
                    style={{ maxWidth: 400 }}
                    lowContrast
                    hideCloseButton={false}
                    iconDescription="describes the close button"
                    kind={status.state}
                    notificationType="inline"
                    onCloseButtonClick={() => setStatus({ msg: "", state: "" })}
                    role="alert"
                    statusIconDescription="describes the status icon"
                    title={status.msg}
                  />
                </Column>
              </Row>
            </Grid>
          </Dialog>
        ))}
    </>
  );
};
