import React from "react";
import hr from "date-fns/locale/hr";
import { useQuery } from "react-query";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import NumberFormat from "react-number-format";
import {
  useForm,
  useFieldArray,
  Controller,
  ErrorMessage,
} from "react-hook-form";
import {
  Typography,
  Button,
  TextField,
  Container,
  InputAdornment,
} from "@material-ui/core";
import {
  Grid,
  Column,
  Row,
  FileUploaderButton,
  InlineNotification,
  Loading,
} from "carbon-components-react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  formatDate,
  addHours,
  disableHolidays,
  getNextWorkDay,
} from "../../functions/helpers";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  CardTitle,
  CustomGrid,
  Error,
  CustomRow,
  CustomColumn,
} from "./styles";
import "./NewSalary.scss";
import {
  handlePostRequest,
  handleFileUpload,
} from "../../helpers/handle-response";

/* axios
  .get(
    "https://www.googleapis.com/calendar/v3/calendars/hr.croatian%23holiday%40group.v.calendar.google.com/events?timeMax=2022-12-31T10%3A00%3A00-07%3A00&timeMin=2022-01-01T10%3A00%3A00-07%3A00&key=AIzaSyB0ABhruzsyBP5XS055z6l5JhFZcNHbhj4"
  )
  .then(res => {
    console.log(
      res.data.items
        .filter(item => {
          if (
            item.summary.includes("Ramadan") ||
            item.summary.includes("Kurban") ||
            item.summary.includes("Roš") ||
            item.summary.includes("Yom") ||
            item.summary.includes("Pravoslavni")
          ) {
            return false;
          } else {
            return true;
          }
        })
        .map(item => {
          return item.start.date;
        })
    );
  })
  .catch(err => console.log(err));
 */
const getMaxDate = (date) => {
  const value = new Date(date.getTime());
  value.setDate(1);
  value.setHours(-1);
  console.log(value)
  return value;
};
const getPrevMonthDate = () => {
  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  return new Date(year, month, 0);
};
const NewSalary = ({ currentUser }) => {
  const { errors, control, handleSubmit, reset } = useForm({
    defaultValues: {
      employees: [
        {
          name: "",
          bruto: "",
          bonus: "",
          prijevoz: "",
          eviLink: [],
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "employees",
  });
  const [selectedDate, setSelectedDate] = React.useState(
    getNextWorkDay(new Date(addHours(new Date(), 48)))
  );
  const [monthYear, setMonthYear] = React.useState(getPrevMonthDate);
  const [filesToUpload, setFilesToUpload] = React.useState({});
  const [status, setStatus] = React.useState({ msg: "", state: "" });
  const [requestPending, setRequestPending] = React.useState(false);
  const [sihterice, setSihterice] = React.useState([]);
  const [sihtericaExists, setSihtericaExists] = React.useState({});

  console.log(sihtericaExists, filesToUpload);

  const { data: options /* isFetching */ } = useQuery(
    ["employeesSalary", monthYear],
    async () => {
      const { data } = await handlePostRequest(
        "https://apiaccountant.plc.hr/api/p?m=GetEmployeesForPlace",
        [
          {
            user: "igor",
            orgID: Number(currentUser.orgID),
            month: monthYear.getMonth() + 1,
            year: monthYear.getFullYear(),
          },
        ]
      );

      const parsedData = JSON.parse(data);
      console.log(parsedData);
      const result = Object.keys(parsedData).map((key) => {
        const split = parsedData[key].split(" ");

        return {
          label: parsedData[key],
          value: key,
          FirstName: split[0],
          LastName: split[1],
        };
      });
      console.log(result);
      return result;
    },

    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );

  React.useEffect(() => {
    const getData = async () => {
      const result = await handlePostRequest(
        `https://apiaccountant.plc.hr/api/s?m=SihtericaGets`,
        [{ user: "igor", orgID: currentUser.orgID }]
      );
      const items = JSON.parse(result.data);
      let dataArray = [];
      for (let key in items) {
        dataArray.push(items[key]);
      }
      setSihterice(dataArray);
    };
    currentUser && getData();
  }, [currentUser]);

  const GetLastMonth = () => {
    handlePostRequest(
      "https://apiaccountant.plc.hr/api/p?m=GetPlaceItemsLastMonth",
      [{ user: "igor", orgID: currentUser.orgID }]
    )
      .then((res) => {
        const parsedData = JSON.parse(res.data);
        reset({
          employees: parsedData.map((item) => {
            return {
              name: {
                value: item.RadnikID,
                label: `${item.FirstName} ${item.LastName}`,
                FirstName: item.FirstName,
                LastName: item.LastName,
              },
              bruto: item.Brutto,
              bonus: item.Bonus,
              prijevoz: item.Prijevoz,
              switch: false,
            };
          }),
        });
      })
      .catch((err) => console.log(err));
  };
  const onSubmit = (data) => {
    const filesLength = data.employees.length;
    console.log(data);
    const isDuplicate = data.employees.filter((item, index) => {
      if (index + 1 < data.employees.length) {
        if (item.name !== data.employees[index + 1].name) {
          console.log("no same ");
          return false;
        } else {
          console.log("has same");
          return true;
        }
      }
      return null;
    });
    const duplicateFiles = Object.keys(filesToUpload).filter((key, index) => {
      if (index + 1 < filesToUpload.length) {
        if (
          filesToUpload[key] === null ||
          filesToUpload[key].name !== filesToUpload[index + 1].name
        ) {
          console.log("no same file");
          return false;
        } else {
          console.log("has same files");
          return true;
        }
      }
      return null;
    });
    console.log(
      data,
      filesToUpload,
      isDuplicate,
      { duplicateFiles },
      filesLength
    );
    if (isDuplicate.length !== 0) {
      setStatus({
        msg: `Dva puta ste odabrali zaposlenika: ${
          isDuplicate[isDuplicate.length - 1].name.label
        }`,
        state: "error",
      });
      setTimeout(() => {
        setStatus({ msg: "", state: "" });
      }, 5000);
    } else if (duplicateFiles.length !== 0) {
      setStatus({
        msg: `Dodali ste dvije jednake datoteke: ${
          duplicateFiles[Object.entries(duplicateFiles).length - 1].name
        }`,
        state: "error",
      });
      setTimeout(() => {
        setStatus({ msg: "", state: "" });
      }, 5000);
    } else {
      console.log(filesToUpload, filesLength);

      if (
        Object.entries(filesToUpload).length < filesLength ||
        fields.length < filesLength
      ) {
        //show error message for no files
        console.log(filesToUpload, filesLength);

        setStatus({ msg: "Niste dodali sve datoteke", state: "error" });
        setTimeout(() => {
          setStatus({ msg: "", state: "" });
        }, 3000);
      } else {
        setRequestPending(true);

        const PlaceTicketItemDB = data.employees.map((item, index) => {
          console.log(
            filesToUpload[index],

            typeof filesToUpload[index]
          );
          console.log(item);
          return {
            ID: 0,
            RequestID: 0,
            RadnikID: item.name.value,
            FirstName: item.name.FirstName,
            LastName: item.name.LastName,
            Brutto: Number(item.bruto.replace(/\./g, "").replace(",", ".")),
            Bonus: Number(item.bonus.replace(/\./g, "").replace(",", ".")),
            Sihterica: filesToUpload[index].hasOwnProperty("ID")
              ? filesToUpload[index].ID
              : filesToUpload[index].name,
            UpdateTime: new Date(),
            UpdateUser: "",
          };
        });

        const Request = {
          PayYear: monthYear.getFullYear(),
          PayMonth: monthYear.getMonth(),
          PayDate: new Date(selectedDate),
          MailSentRequest: 0,
          UpdateRequest: new Date(),
          UserRequest: "",
        };
        //Append all files to FormData
        const Files = new FormData();
        for (let key in filesToUpload) {
          if (!filesToUpload[key].hasOwnProperty("ID")) {
            Files.append("files", filesToUpload[key]);
          } else {
          }
        }
        handleFileUpload(
          `https://www.api.accountant.hr/users/uploadFiles`,
          Files,
          currentUser.token
        ).then((res) => {
          console.log(res.data);
          if (res.data === true) {
            //send data to server if files are uploaded
            handlePostRequest(
              "https://apiaccountant.plc.hr/api/p?m=AddNewRequest",
              [
                {
                  user: "igor",
                  orgID: currentUser.orgID,
                  req: JSON.stringify(Request),
                  items: JSON.stringify(PlaceTicketItemDB),
                },
              ]
            )
              .then((res) => {
                if (res.data === "True") {
                  setRequestPending(false);
                  setStatus({
                    msg: "Zahtjev uspješno poslan!",
                    state: "success",
                  });
                  setFilesToUpload({});
                  reset({
                    employees: [
                      {
                        name: "",
                        bruto: "",
                        bonus: "",
                        prijevoz: "",
                        eviLink: [],
                      },
                    ],
                  });
                }
              })
              .catch((err) => console.log(err));
          }
        });
      }
    }
  };
  /* const isPicked = name => {
    return fields.find(item => item.name.value === name);
  }; */

  //Disable already picked values
  /*   React.useEffect(() => {
    const getChosen = fields.map(item => {
      console.log(item);
      return item.name.value;
    });
    console.log(getChosen);
    const newArr = options.map(item => {
      for (let i = 0; i < getChosen.length; i++) {
        if (item.value.indexOf(getChosen[i]) > -1) {
          return {
            value: item.value,
            label: item.label,
            isDisabled: true
          };
        } else {
          return {
            value: item.value,
            label: item.label,
            isDisabled: false
          };
        }
      }
    });

    setOptions(newArr);
  }, [fields]); */
  //holiday api, get dates for year, each year special, get 30 years of holiday, save them to variable
  return (
    <Container>
      <Grid>
        <Row lg={4} xs={12}>
          <Column>
            <CardTitle variant="h4">Obračun plaće</CardTitle>
          </Column>
        </Row>
      </Grid>
      <Container maxWidth="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Row lg={3} xs={8} style={{ marginTop: 30 }}>
              <Column lg={5}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hr}>
                  <DatePicker
                    openTo="month"
                    variant="inline"
                    inputVariant="filled"
                    autoOk
                    maxDate={getMaxDate(new Date())}
                    views={["year", "month"]}
                    label="Mjesec i godina plaće"
                    helperText="Odaberite mjesec i godinu za obračun plaće"
                    value={monthYear}
                    onChange={(date) => setMonthYear(date)}
                  />
                </MuiPickersUtilsProvider>
              </Column>
              <CustomColumn
                margintop="0px"
                lg={4}
                style={{ alignSelf: "flex-start" }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hr}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="filled"
                    format="dd.MM.yyyy"
                    minDate={getNextWorkDay(new Date(addHours(new Date(), 48)))}
                    minDateMessage={`Datum ne može biti manji od ${formatDate(
                      getNextWorkDay(new Date(addHours(new Date(), 48)))
                    )}`}
                    margin="normal"
                    autoOk
                    id="date-picker-inline"
                    label="Datum isplate"
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    shouldDisableDate={disableHolidays}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </CustomColumn>
            </Row>
          </Grid>

          <Grid style={{ marginTop: 20, marginBottom: 40 }}>
            <Row style={{ justifyContent: "space-between" }}>
              <Column xs={8} lg={4}>
                <Typography variant="h6">Podaci radnika</Typography>
              </Column>
              <Column xs={8} lg={4}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => GetLastMonth()}
                >
                  Kopiraj prošli mjesec
                </Button>
              </Column>
            </Row>
          </Grid>
          <TransitionGroup className="animations">
            {fields.map((item, index) => {
              return (
                <CSSTransition key={item.id} timeout={500} classNames="item">
                  <CustomGrid>
                    <Row>
                      {/* <InputLabel id="select">Ime i prezime radnika</InputLabel> */}
                      <Column xs={12} lg={3}>
                        <Controller
                          as={
                            <Select />
                            /* <Select
                          labelId="select"
                          variant="filled"
                          className="customInput"
                          name={`employees[${index}].name`}
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            }
                          }}
                        >
                          <MenuItem value=" "> None</MenuItem>
                          <MenuItem value="One">One</MenuItem>
                        </Select> */
                          }
                          options={options}
                          name={`employees[${index}].name`}
                          control={control}
                          placeholder="Radnik"
                          rules={{ required: "Radnik je obvezno polje" }}
                          onChange={([event]) => {
                            const item = sihterice.find((item) => {
                              if (
                                item.Godina === monthYear.getFullYear() &&
                                item.Mjesec === monthYear.getMonth() + 1 &&
                                item.RadnikID === Number(event.value)
                              ) {
                                console.log(item);
                                return item;
                              } else return null;
                            });
                            setSihtericaExists({
                              ...sihtericaExists,
                              [index]: item,
                            });
                            if (item !== undefined) {
                              setFilesToUpload({
                                ...filesToUpload,
                                [index]: item,
                              });
                            }
                            return event;
                            //check if sihterice exists for this user
                          }}
                        />

                        <ErrorMessage
                          as={Error}
                          style={{ marginTop: 1 }}
                          errors={errors}
                          name={`employees[${index}].name`}
                        />
                      </Column>
                      <Column xs={12} lg={2}>
                        <Controller
                          as={
                            <NumberFormat
                              allowNegative={false}
                              className="customInput"
                              customInput={TextField}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              label="Bruto plaća"
                              variant="filled"
                              min={0}
                              autoComplete="off"
                              thousandSeparator={"."}
                              decimalSeparator={","}
                            />
                          }
                          name={`employees[${index}].bruto`}
                          control={control}
                          defaultValue={item.bruto}
                          rules={{ required: "Bruto plaća je obvezno polje" }}
                        />
                        <ErrorMessage
                          as={Error}
                          errors={errors}
                          name={`employees[${index}].bruto`}
                        />
                      </Column>
                      <Column xs={12} lg={2}>
                        <Controller
                          as={
                            <NumberFormat
                              allowNegative={false}
                              className="customInput"
                              customInput={TextField}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              label="Bonus"
                              variant="filled"
                              min={0}
                              autoComplete="off"
                              thousandSeparator={"."}
                              decimalSeparator={","}
                            />
                          }
                          name={`employees[${index}].bonus`}
                          control={control}
                          defaultValue={item.bonus}
                        />
                      </Column>
                      <Column xs={12} lg={2}>
                        <Controller
                          as={
                            <NumberFormat
                              allowNegative={false}
                              className="customInput"
                              customInput={TextField}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              label="Prijevoz"
                              variant="filled"
                              min={0}
                              autoComplete="off"
                              thousandSeparator={"."}
                              decimalSeparator={","}
                            />
                          }
                          name={`employees[${index}].prijevoz`}
                          control={control}
                          defaultValue={item.prijevoz}
                        />
                      </Column>
                      <Column
                        xs={12}
                        lg={2}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {sihtericaExists[index] ? (
                          <p
                            style={{
                              alignSelf: "center",
                              color: "white",
                              marginBottom: 0,
                            }}
                          >
                            Šihterica postoji
                          </p>
                        ) : (
                          <FileUploaderButton
                            name={`employees[${index}].eviLink`}
                            style={{ height: 55 }}
                            labelText="Šihterica upload"
                            role="button"
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                            onChange={(e) => {
                              e.persist();
                              console.log(e.target.files, index);
                              setFilesToUpload({
                                ...filesToUpload,
                                [index]: e.target.files[0],
                              });
                            }}
                          />
                        )}
                      </Column>

                      <Column
                        xs={12}
                        lg={1}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          onClick={() => {
                            delete filesToUpload[index];
                            remove(index);
                          }}
                        >
                          <HighlightOffIcon style={{ color: "#f44336" }} />
                        </Button>
                      </Column>
                    </Row>
                  </CustomGrid>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
          <Grid condensed style={{ marginTop: 20 }}>
            <Row>
              <Column lg={12}>
                <Button
                  onClick={() => {
                    append({
                      name: "",
                      bruto: "",
                      bonus: "",
                      prijevoz: "",
                      eviLink: [],
                      switch: false,
                    });
                  }}
                  variant="contained"
                >
                  <AddIcon />
                </Button>
              </Column>
            </Row>
          </Grid>
          <Grid style={{ marginTop: 20 }}>
            <CustomRow justify="center">
              {requestPending ? (
                <Column lg={3}>
                  <Loading
                    active
                    className="some-class"
                    description="Active loading indicator"
                    small={false}
                    withOverlay={false}
                  />
                </Column>
              ) : null}
            </CustomRow>
            <CustomRow justify="center">
              <Column lg={2}>
                <Button
                  disabled={requestPending}
                  variant="contained"
                  type="submit"
                  style={{
                    background: " linear-gradient(60deg, #66bb6a, #43a047)",
                    color: "white",
                  }}
                >
                  Pošalji
                </Button>
              </Column>
            </CustomRow>
            <CustomRow justify="center">
              {status.msg.length > 1 ? (
                <InlineNotification
                  style={{ maxWidth: 400 }}
                  lowContrast
                  hideCloseButton={false}
                  iconDescription="describes the close button"
                  kind={status.state}
                  notificationType="inline"
                  onCloseButtonClick={() => setStatus({ msg: "", state: "" })}
                  role="alert"
                  statusIconDescription="describes the status icon"
                  title={status.msg}
                />
              ) : null}
            </CustomRow>
          </Grid>
        </form>
      </Container>
    </Container>
  );
};

export default NewSalary;
