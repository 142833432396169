import { uniq } from "lodash";
import { sumTime, timeFormat } from "./timeFunc";
import axios from "axios";

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

function getSaturdays(date) {
  const getTotal = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();

  let sat = [];
  for (let i = 1; i <= getTotal; i++) {
    let newDate = new Date(date.getFullYear(), date.getMonth(), i);
    if (newDate.getDay() === 6) {
      sat.push(i);
    }
  }
  return sat;
}

function getSundays(date) {
  console.log(date);
  const getTotal = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();

  let sun = [];
  for (let i = 1; i <= getTotal; i++) {
    let newDate = new Date(date.getFullYear(), date.getMonth(), i);
    if (newDate.getDay() === 0) {
      sun.push(i);
    }
  }
  return sun;
}
export function getWeekendDays(date) {
  const getTotal = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();

  let weekend = [];
  for (let i = 1; i <= getTotal; i++) {
    let newDate = new Date(date.getFullYear(), date.getMonth(), i);
    if (newDate.getDay() === 0 || newDate.getDay() === 6) {
      weekend.push(i);
    }
  }
  return weekend;
}
export const getHolidays = (date, setSundays, setHolidaysTotal) => {
  let holidays = [];

  holidays.push(
    ...getSundays(new Date(date.getFullYear(), date.getMonth(), 1))
  );
  const year = date.getFullYear();
  const fetchDates = async () => {
    const result = await axios.get(
      `https://www.googleapis.com/calendar/v3/calendars/hr.croatian%23holiday%40group.v.calendar.google.com/events?timeMax=${year}-12-31T10%3A00%3A00-07%3A00&timeMin=${year}-01-01T10%3A00%3A00-07%3A00&key=AIzaSyB0ABhruzsyBP5XS055z6l5JhFZcNHbhj4`
    );

    result.data.items.map((item) => {
      if (
        item.summary !== "Pravoslavni Božić" &&
        item.summary !== "Ramadan Bajram" &&
        item.summary !== "Kurban Bajram" &&
        item.summary !== "Roš hašana" &&
        item.summary !== "Yom Kipura"
      ) {
        if (new Date(item.start.date).getMonth() === date.getMonth()) {
          holidays.push(new Date(item.start.date).getDate());
        }
      }
      return null;
    });
    setSundays(holidays);
    setHolidaysTotal(holidays);
  };
  fetchDates();
};

export const getHolidaysDownload = async (date) => {
  let holidays = [];
  const year = date.getFullYear();

  holidays.push(
    ...getSundays(new Date(date.getFullYear(), date.getMonth(), 1))
  );
  const result = await axios.get(
    `https://www.googleapis.com/calendar/v3/calendars/hr.croatian%23holiday%40group.v.calendar.google.com/events?timeMax=${year}-12-31T10%3A00%3A00-07%3A00&timeMin=${year}-01-01T10%3A00%3A00-07%3A00&key=AIzaSyB0ABhruzsyBP5XS055z6l5JhFZcNHbhj4`
  );

  result.data.items.map((item) => {
    if (
      item.summary !== "Pravoslavni Božić" &&
      item.summary !== "Ramadan Bajram" &&
      item.summary !== "Kurban Bajram" &&
      item.summary !== "Roš hašana" &&
      item.summary !== "Yom Kipura"
    ) {
      if (new Date(item.start.date).getMonth() === date.getMonth()) {
        holidays.push(new Date(item.start.date).getDate());
      }
    }
    return null;
  });
  return holidays;
};

export function calcFond(days, holidaysTotal, date, result) {
  const resultTime = result.totalWork.hours * 60 + result.totalWork.minutes;
  const sat = getSaturdays(date);
  const holidays = uniq([...sat, ...holidaysTotal]);
  const daysMonth = daysInMonth(date.getMonth() + 1, date.getFullYear());
  const fondHours = (daysMonth - holidays.length) * 8;
  const filter = days.map((item) => {
    if (
      item["UkupnoDnevnoRV"] === null ||
      Number(item["id"]) === date.getDate()
    ) {
      return null;
    } else {
      const split = item["UkupnoDnevnoRV"].split(":");
      return { hours: Number(split[0]), minutes: Number(split[1]) };
    }
  });
  const sumNow = sumTime(filter);
  const obj = {
    fond: fondHours * 60,
    sum: sumNow.hours * 60 + sumNow.minutes,
  };
  const diff = obj.fond - (obj.sum + resultTime);

  const regularTime = resultTime - Math.abs(diff);
  //overtime times
  const hours =
    Math.abs(diff) < 60
      ? 0
      : resultTime > Math.abs(diff)
      ? diff / 60
      : resultTime / 60;
  const minutes = diff % 60;
  //regular times
  const hoursRegular = regularTime < 60 ? 0 : regularTime / 60;
  const minutesRegular = regularTime % 60;

  const object = {
    nightWork: { hours: 0, minutes: 0, time: "00:00" },
    nightOvertime: { hours: 0, minutes: 0, time: "00:00" },
    dayWork: { hours: 0, minutes: 0, time: "00:00" },
    dayOvertime: { hours: 0, minutes: 0, time: "00:00" },
  };
  console.log({ regularTime, hours, minutes, resultTime, diff });
  if (regularTime > 480) {
    console.log("regular is large", Object.assign(object, result));
    return { diff, object: Object.assign(object, result) };
  } else {
    if (diff <= 0) {
      console.log("everything is overtime ", result);
      const object = {
        nightWork: {
          hours: 0,
          minutes: 0,
          time: "00:00",
        },
        nightOvertime: {
          hours: result.nightWork.hours + result.nightOvertime.hours,
          minutes: result.nightWork.minutes + result.nightOvertime.minutes,
          time: timeFormat(
            result.nightWork.hours + result.nightOvertime.hours,
            result.nightWork.minutes + result.nightOvertime.minutes
          ),
        },
        dayWork: { hours: 0, minutes: 0, time: "00:00" },
        dayOvertime: {
          hours: result.dayWork.hours + result.dayOvertime.hours,
          minutes: result.dayWork.minutes + result.dayOvertime.minutes,
          time: timeFormat(
            result.dayWork.hours + result.dayOvertime.hours,
            result.dayWork.minutes + result.dayOvertime.minutes
          ),
        },
      };
      console.log(object);
      return { diff, object };
    } else {
      console.log(result);
      if (
        result.nightWork.hours > 0 ||
        result.nightWork.minutes > 0 ||
        result.nightOvertime.hours > 0 ||
        result.nightOvertime.minutes > 0
      ) {
        object.nightWork = {
          hours: Math.floor(hoursRegular),
          minutes: minutesRegular,
          time: timeFormat(
            Math.floor(Math.abs(hoursRegular)),
            Math.abs(minutesRegular)
          ),
        };
        object.nightOvertime = {
          hours: Math.floor(hours),
          minutes: minutes,
          time: timeFormat(Math.floor(Math.abs(hours)), Math.abs(minutes)),
        };
      }
      object.dayWork = {
        hours: Math.floor(hoursRegular),
        minutes: minutesRegular,
        time: timeFormat(
          Math.floor(Math.abs(hoursRegular)),
          Math.abs(minutesRegular)
        ),
      };
      object.dayOvertime = {
        hours: Math.floor(hours),
        minutes: minutes,
        time: timeFormat(Math.floor(Math.abs(hours)), Math.abs(minutes)),
      };

      return {
        diff,
        object,
      };
    }
  }
}

export const getTimeObject = (id, holidays, times, result, date, days) => {
  const obj = {};
  console.log(result);
  const fondHours = calcFond(days, holidays, date, result);
  obj.UkupnoDnevnoRV = result.totalWork.time;
  console.log(result.totalWork.time);
  if (fondHours.diff < 0) {
    //fix day and night hours splitting,
    //if night check how many hours is night,
    //if night add overtime to night automatically
    console.log(fondHours);
    if ([...holidays].includes(id)) {
      if (
        result.nightWork.hours > 0 ||
        result.nightWork.minutes > 0 ||
        result.nightOvertime.hours > 0 ||
        result.nightOvertime.minutes > 0
      ) {
        obj.NocniPrekovremeni = fondHours.object.nightOvertime.time;
        obj.NocniBlagdanNeradni = fondHours.object.nightWork.time;
      }
      obj.DanjiPrekovremeni = fondHours.object.dayOvertime.time;
      obj.DanjiBlagdanNeradni = fondHours.object.dayWork.time;
    } else {
      if (
        result.nightWork.hours > 0 ||
        result.nightWork.minutes > 0 ||
        result.nightOvertime.hours > 0 ||
        result.nightOvertime.minutes > 0
      ) {
        obj.NocniPrekovremeni = fondHours.object.nightOvertime.time;
        obj.NocniRedovanRad = fondHours.object.nightWork.time;
      }

      obj.DanjiPrekovremeni = fondHours.object.dayOvertime.time;
      obj.DanjiRedovanRad = fondHours.object.dayWork.time;
    }
  } else {
    if (
      result.hasOwnProperty(
        "dayOvertime"
      ) /* &&
      (result.dayOvertime.hours > 0 || result.dayOvertime.minutes > 0) */
    ) {
      obj.DanjiPrekovremeni = result.dayOvertime.time;
    }
    if (
      result.hasOwnProperty(
        "nightOvertime"
      ) /* &&
      (result.nightOvertime.hours > 0 || result.nightOvertime.minutes > 0) */
    ) {
      obj.NocniPrekovremeni = result.nightOvertime.time;
    }

    if ([...holidays].includes(id)) {
      obj.DanjiBlagdanNeradni = result.dayWork.time;
    } else {
      obj.DanjiRedovanRad = result.dayWork.time;
    }

    if ([...holidays].includes(id)) {
      obj.NocniBlagdanNeradni = result.nightWork.time;
    } else {
      obj.NocniRedovanRad = result.nightWork.time;
    }
  }

  return obj;
};

export const formatTime = (time) => {
  const split = time.split(":");
  const h = split[0];
  const m = split[1];
  return { h: Number(h), m: Number(m), s: 0 };
};
export const formatAsTime = (time) => {
  const hours = time.h < 10 ? `0${time.h}` : time.h;
  const minutes = time.m < 10 ? `0${time.m}` : time.m;
  return `${hours}:${minutes}`;
};
