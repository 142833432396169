import React from "react";
import { DataTable, Button, Pagination } from "carbon-components-react";
import { formatDate } from "../functions/helpers";
import { WarningAltFilled32, CheckmarkFilled32 } from "@carbon/icons-react";
const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
} = DataTable;
const getObject = (id, data) =>
  data.find((obj) => {
    return obj.ID === id;
  });
const ApplicationTable = ({ data, setPicked, handleOpen }) => {
  const [page, setPage] = React.useState({ current: 1, pageSize: 10 });
  const filteredData =
    data.length > 0 &&
    data.map((item) => {
      return {
        id: String(item.ID),
        Pregled: "",
        NazivTvrtke: item.OrgName,
        Radnik: item.Radnik,
        Datum: formatDate(item.info.DatumPocetkaRada),
        Status: item.response.UserResponse,
      };
    });
  const onPageChange = (pageChange) => {
    setPage({ current: pageChange.page, pageSize: pageChange.pageSize });
  };

  const startItem = (page.current - 1) * page.pageSize;
  const endItem = startItem + page.pageSize;
  const displayedRows = filteredData.slice(startItem, endItem);
  return (
    displayedRows.length > 0 && (
      <div style={{ margin: "auto", width: "100%" }}>
        <DataTable
          filterRows={function noRefCheck() {}}
          headers={[
            {
              header: "Pregled",
              key: "Pregled",
            },
            {
              header: "Naziv tvrtke",
              key: "NazivTvrtke",
            },
            {
              header: "Radnik",
              key: "Radnik",
            },
            {
              header: "Datum početka rada",
              key: "Datum",
            },
            {
              header: "Status",
              key: "Status",
            },
          ]}
          isSortable
          locale="en"
          rows={displayedRows}
          render={({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
          }) => (
            <TableContainer style={{ minWidth: "unset" }}>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id} {...getRowProps({ row })}>
                      {row.cells.map((cell) => {
                        if (cell.info.header === "Pregled") {
                          return (
                            <TableCell key={cell.id}>
                              <Button
                                onClick={() => {
                                  setPicked(getObject(Number(row.id), data));
                                  handleOpen();
                                }}
                              >
                                Pregled
                              </Button>
                            </TableCell>
                          );
                        } else if (cell.info.header === "Status") {
                          return (
                            <TableCell key={cell.id}>
                              {cell.value === "waiting" ? (
                                <WarningAltFilled32
                                  style={{ width: "100%", height: "100%" }}
                                  fill="#FD970F"
                                />
                              ) : (
                                <CheckmarkFilled32
                                  style={{ width: "100%", height: "100%" }}
                                  fill="#4DA851"
                                />
                              )}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={cell.id}>{cell.value}</TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        />
        <Pagination
          style={{ overflow: "hidden" }}
          backwardText="Prethodna stranica"
          disabled={false}
          forwardText="Sljedeća stranica"
          isLastPage={false}
          itemRangeText={function noRefCheck() {}}
          itemText={function noRefCheck() {}}
          itemsPerPageText="Broj stavki po stranici:"
          onChange={onPageChange}
          page={1}
          pageNumberText="Page Number"
          pageRangeText={function noRefCheck() {}}
          pageSize={10}
          pageSizes={[10, 20, 30, 40, 50]}
          pageText={function noRefCheck() {}}
          pagesUnknown={false}
          totalItems={filteredData.length}
        />
      </div>
    )
  );
};

export default ApplicationTable;
