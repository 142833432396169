import styled from "styled-components";
import { Grid } from "carbon-components-react";
import { Select, InputLabel } from "@material-ui/core";

export const CardTitle = styled.div`
  padding: 15px;
  margin-right: 15px;
  font-size: 22px;
  border-radius: 3px;
  background-color: #999;
  width: 100%;
  max-width: 200px;
  text-align: center;
  color: white !important;
  background: linear-gradient(60deg, #66bb6a, #43a047);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(76, 175, 80, 0.4);
`;
export const CustomGrid = styled(Grid)`
  background-color: #334d7b;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgb(177, 177, 177);
  border-radius: 5px;
  margin-bottom: 5px !important;
  padding: 10px;
  margin-top: 40px;
  max-width: 750px;
  @media (max-width: 770px) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .MuiIconButton-label {
    svg {
      fill: white;
    }
  }
  .MuiFormLabel-root.Mui-focused {
    color: white;
  }
  .MuiFormControl-marginNormal {
    margin-top: unset;
    margin-bottom: unset;
  }
  .bx--col-sm-4.bx--col-lg-4 {
    @media (max-width: 1040px) {
      margin: 10px 0;
    }
  }
  label {
    color: white;
  }
  input {
    color: white;
  }
  & > div {
    padding: 0 10px;
  }
  @media (max-width: 770px) {
    & > div > div {
      margin: 10px 0;
    }
  }
`;
export const Error = styled.p`
  margin: 5px 0;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: rgb(244, 67, 54);
`;

export const CustomSelect = styled(Select)`
  width: 100%;
  & > div {
    color: white;
  }
  svg {
    fill: white;
  }
`;

export const CustomInputLabel = styled(InputLabel)``;
