import React from "react";
import jwt_decode from "jwt-decode";
import { Router, Route, Switch } from "react-router-dom";
import { authenticationService } from "../../services/authentication";
import { useWindowSize } from "../../helpers/hooks";
import { /* Button, Menu, MenuItem, */ Container } from "@material-ui/core";
import { createBrowserHistory } from "history";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import styled from "styled-components";
import "./App.scss";
import axios from "axios";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import ComingSoon from "../uiHelpers/ComingSoon";
//import AppBar from "./AppBar";
import Home from "../Home/Home";
import Salaries from "../Salaries/Salaries";
import NewSalary from "../Salaries/NewSalary";
import Login from "../../components/Login/Login";
import CustomHeader from "../CustomHeader/CustomHeader";
import AdminStatementsReview from "../AdminStatementsReview/AdminStatementsReview";
import ClientStatementReview from "../ClientStatementsReview/ClientStatementsReview";
import EmployeeApplication from "../EmployeeApplication/EmployeeApplication";
import AdminApplicationReview from "../AdminApplicationReview/AdminApplicationReview";
import ClientApplicationReview from "../ClientApplicationReview/ClientApplicationReview";
import EmployeeCheckout from "../EmployeeCheckout/EmployeeCheckout";
import ClientCheckoutReview from "../ClientCheckoutReview/ClientCheckoutReview";
import AdminCheckoutReview from "../AdminCheckoutReview/AdminCheckoutReview";
import OtherInquiries from "../OtherInquiries/OtherInquiries";
import NewVirman from "../NewVirman/NewVirman";
import ClientVirmanReview from "../ClientVirmanReview/ClientVirmanReview";
import AdminVirmanReview from "../AdminVirmanReview/AdminVirmanReview";
import NewDrivingStatement from "../DrivingStatement/NewDrivingStatement";
import AdminAddFiles from "../DrivingStatement/AdminAddFiles";
//import WorkTimesheet from "../Evidencija/WorkTimesheet";
import ClientDrivingReview from "../DrivingStatement/ClientDrivingReview";
import TerenskiDodatak from "../TerenskiDodatak/TerenskiDodatak";
import Evidencija from "../Evidencija/Evidencija";

const history = createBrowserHistory();

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
function logout() {
  axios
    .post("https://apiaccountant.plc.hr/api/p?m=Unload", {
      user: "igor",
    })
    .then((res) => console.log(res));
  authenticationService.logout();
  history.push("/login");
}

function App() {
  const size = useWindowSize();
  const [currentUser, setCurrentUser] = React.useState();
  const decoded = currentUser && jwt_decode(currentUser.token);
  React.useEffect(() => {
    authenticationService.currentUser.subscribe((x) => setCurrentUser(x));
  });
  /*   console.log(
    decoded,
    currentUser && !decoded.isClient,
    decoded && decoded.CompanyOIB === 1886578177
  ); */
  return (
    <div style={{ backgroundColor: "#EEEEEE" }}>
      <Router history={history}>
        {history.location.pathname === "/coming-soon" ? null : (
          <CustomHeader currentUser={decoded} logout={logout}></CustomHeader>
        )}
        <Wrapper>
          <Switch>
            <Route path="/coming-soon">
              <ComingSoon></ComingSoon>
            </Route>
            <Route path="/login">
              <Login history={history}></Login>
            </Route>
            <PrivateRoute exact path="/">
              <Home history={history} currentUser={decoded} size={size}></Home>
            </PrivateRoute>
            <PrivateRoute path="/place-nova">
              {currentUser && decoded.isClient && (
                <NewSalary currentUser={currentUser}></NewSalary>
              )}
            </PrivateRoute>
            <PrivateRoute path="/place-pregled">
              {currentUser && decoded.isClient ? (
                <ClientStatementReview
                  currentUser={currentUser}
                  isClient={currentUser && decoded.isClient}
                ></ClientStatementReview>
              ) : (
                <AdminStatementsReview
                  currentUser={currentUser}
                  isClient={currentUser && decoded.isClient}
                ></AdminStatementsReview>
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/prijava-pregled">
              {currentUser && decoded.isClient ? (
                <ClientApplicationReview
                  currentUser={currentUser}
                  isClient={currentUser && decoded.isClient}
                ></ClientApplicationReview>
              ) : (
                <ReactQueryCacheProvider queryCache={queryCache}>
                  <AdminApplicationReview
                    currentUser={currentUser}
                    isClient={currentUser && decoded.isClient}
                  ></AdminApplicationReview>
                </ReactQueryCacheProvider>
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/place">
              <Salaries history={history}></Salaries>
            </PrivateRoute>
            <PrivateRoute exact path="/prijava-nova">
              <EmployeeApplication
                history={history}
                currentUser={currentUser}
              ></EmployeeApplication>
            </PrivateRoute>
            <PrivateRoute exact path="/odjava">
              {currentUser && decoded.isClient && (
                <EmployeeCheckout currentUser={currentUser}></EmployeeCheckout>
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/odjava-pregled">
              {currentUser && decoded.isClient ? (
                <ClientCheckoutReview
                  currentUser={currentUser}
                  isClient={currentUser && decoded.isClient}
                ></ClientCheckoutReview>
              ) : (
                <ReactQueryCacheProvider queryCache={queryCache}>
                  <AdminCheckoutReview
                    currentUser={currentUser}
                    isClient={currentUser && decoded.isClient}
                  ></AdminCheckoutReview>
                </ReactQueryCacheProvider>
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/ostali-upiti">
              {currentUser && decoded.isClient ? (
                <OtherInquiries
                  currentUser={currentUser}
                  isClient={currentUser && decoded.isClient}
                ></OtherInquiries>
              ) : (
                <p>not for admin</p>
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/novo-dugovanje">
              {currentUser && decoded.isClient ? (
                <p>client</p>
              ) : (
                <ReactQueryCacheProvider queryCache={queryCache}>
                  <NewVirman
                    currentUser={currentUser}
                    isClient={currentUser && decoded.isClient}
                  ></NewVirman>
                </ReactQueryCacheProvider>
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/davanja-pregled">
              {decoded && decoded.isClient ? (
                <ClientVirmanReview
                  currentUser={currentUser}
                ></ClientVirmanReview>
              ) : (
                <AdminVirmanReview
                  currentUser={currentUser}
                ></AdminVirmanReview>
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/loko-obracuni">
              {decoded && !decoded.isClient && (
                <AdminAddFiles currentUser={currentUser}></AdminAddFiles>
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/loko-obracuni-novi">
              {currentUser && decoded.isClient && (
                <NewDrivingStatement
                  currentUser={currentUser}
                ></NewDrivingStatement>
              )}
            </PrivateRoute>
            <PrivateRoute path="/evidencija">
              {currentUser && <Evidencija currentUser={currentUser} />}
            </PrivateRoute>
            <PrivateRoute exact path="/loko-pregled">
              {currentUser && decoded.isClient && (
                <ClientDrivingReview
                  currentUser={currentUser}
                ></ClientDrivingReview>
              )}
            </PrivateRoute>

            <PrivateRoute exact path="/terenski-dodatak">
              {decoded &&
                decoded.isClient &&
                decoded.CompanyOIB === 1886578177 && (
                  <TerenskiDodatak currentUser={currentUser}></TerenskiDodatak>
                )}
            </PrivateRoute>
            <Route component={ComingSoon} />
          </Switch>
        </Wrapper>
        <Footer>
          <Container style={{ alignSelf: "flex-end" }}>
            <p>&copy; Parpar Studio 2020</p>
          </Container>
        </Footer>
      </Router>
    </div>
  );
}

export default App;

const Wrapper = styled.div`
  position: relative;
  padding-top: 70px;
`;
const Footer = styled.div`
  margin-top: 30px;
  background-color: white;
  height: 50px;
  display: flex;
`;
