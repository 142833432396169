const dayjs = require("dayjs");
const isBetween = require("dayjs/plugin/isBetween");
const duration = require("dayjs/plugin/duration");
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
const utc = require("dayjs/plugin/utc");
dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);

export function timeFormat(h, m) {
  let hours = h;
  let minutes = m;
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}`;
}

export function isNightShift(start, end, nightshiftStart, nightshiftEnd) {
  if (
    dayjs(start).isBetween(nightshiftStart, nightshiftEnd) ||
    (dayjs(end).isSameOrAfter(nightshiftStart) &&
      dayjs(end).isSameOrBefore(nightshiftEnd))
  ) {
    return true;
  } else return false;
}

export function sumTime(timeMap) {
  let totalH = 0;
  let totalM = 0;

  // First simply adding all of it together, total hours and total minutes
  timeMap.forEach((element) => {
    if (element !== null) {
      totalH += parseInt(element.hours, 10);
      totalM += parseInt(element.minutes, 10);
    }
  });

  // If the minutes exceed 60
  if (totalM >= 60) {
    // Divide minutes by 60 and add result to hours
    totalH += Math.floor(totalM / 60);

    // Add remainder of totalM / 60 to minutes
    totalM = totalM % 60;
  }

  return { hours: totalH, minutes: totalM, time: `${totalH}:${totalM}` };
}

export function getDiff(start, end) {
  const hours = dayjs.duration(dayjs(end).diff(start)).$d.hours;
  const minutes = dayjs.duration(dayjs(end).diff(start)).$d.minutes;
  return { hours, minutes, time: timeFormat(hours, minutes) };
}

export function diffTimes(start, end) {
  const startString = new Date(`2020-04-16 ${start.hours}:${start.minutes}`);
  const endString = new Date(`2020-04-16 ${end.hours}:${end.minutes}`);
  const hours = dayjs.duration(dayjs(startString).diff(endString)).$d.hours;
  const minutes = dayjs.duration(dayjs(startString).diff(endString)).$d.minutes;
  return { hours, minutes, time: timeFormat(hours, minutes) };
}

export function getTimes(start, dateEnd) {
  // IMPLEMENT TIME FOND TO GET OVERTIME
  const end = dayjs(dateEnd).isBefore(start)
    ? dayjs(dateEnd).add(1, "day")
    : dateEnd;
  const nightshiftStart = dayjs(start).hour(22).minute(0).second(0).$d;
  const nightshiftEnd = dayjs(nightshiftStart).add(8, "h");
  const startNight = dayjs(start).isSameOrAfter(nightshiftStart);
  const isNight = isNightShift(start, end, nightshiftStart, nightshiftEnd);
  const totalWork = getDiff(start, end);
  const overtime = diffTimes(totalWork, { hours: 8, minutes: 0 });

  if (isNight) {
    const dayWork = getDiff(start, nightshiftStart);
    const nightWork = getDiff(nightshiftStart, end);

    if (overtime.hours > 0 || overtime.minutes > 0) {
      const totalDayWork = getDiff(start, nightshiftStart);
      const dayWork =
        totalDayWork.hours > 8 ||
        (totalDayWork.hours >= 8 && totalDayWork.minutes > 0)
          ? { hours: 8, minutes: 0, time: "08:00" }
          : totalDayWork.hours > 0 ||
            (totalDayWork.hours >= 0 && totalDayWork.minutes > 0)
          ? totalDayWork
          : { time: null };
      const dayOvertime =
        totalDayWork.hours > 8 ||
        (totalDayWork.hours >= 8 && totalDayWork.minutes > 0)
          ? diffTimes(totalDayWork, dayWork)
          : { hours: 0, minutes: 0, time: null };
      const totalNightWork = getDiff(nightshiftStart, end);
      const nightWork =
        totalWork.hours > 8 || (totalWork.hours >= 8 && totalWork.minutes > 0)
          ? diffTimes({ hours: 8, minutes: 0 }, dayWork)
          : totalNightWork;
      const nightOvertime =
        totalWork.hours > 8 || (totalWork.hours >= 8 && totalWork.minutes > 0)
          ? diffTimes(totalNightWork, nightWork)
          : { times: null };

      if (
        totalDayWork.hours > 8 ||
        (totalDayWork.hours >= 8 && totalDayWork.minutes > 0)
      ) {
        const nightOvertime = totalNightWork;
        const nightWork = { hours: 0, minutes: 0, time: null };
        return { dayWork, dayOvertime, nightWork, nightOvertime, totalWork };
      }

      console.log("overtime only in night");
      return { dayWork, dayOvertime, nightWork, nightOvertime, totalWork };
    }
    return {
      dayWork,
      dayOvertime: { hours: 0, minutes: 0, time: "00:00" },
      nightWork,
      totalWork,
      nightOvertime: { hours: 0, minutes: 0, time: "00:00" },
    };
  } else {
    const dayWork = isNight
      ? startNight
        ? { hours: 0, minutes: 0, time: null }
        : getDiff(start, nightshiftStart)
      : overtime.hours < 0 || overtime.minutes < 0
      ? getDiff(start, end)
      : diffTimes(totalWork, overtime);
    const nightWork = isNight
      ? overtime.hours > 0 || overtime.minutes > 0
        ? diffTimes(overtime, diffTimes(totalWork, dayWork))
        : startNight
        ? totalWork
        : diffTimes(totalWork, dayWork)
      : { hours: 0, minutes: 0, time: null };

    return {
      totalWork,
      dayWork,
      nightWork,
      dayOvertime:
        overtime.hours > 0 || (overtime.hours >= 0 && overtime.minutes > 0)
          ? overtime
          : { hours: 0, minutes: 0, time: null },
      nightOvertime: { hours: 0, minutes: 0, time: null },
      isNight,
      startNight,
    };
  }
}

export function getTimesWithBreak(start, dateEnd, breakStart, bEnd) {
  //ADD TOTAL WORK TIMES
  const end = dayjs(dateEnd).isBefore(start)
    ? dayjs(dateEnd).add(1, "day")
    : dateEnd;
  const breakEnd = dayjs(bEnd).isBefore(breakStart)
    ? dayjs(bEnd).add(1, "day")
    : bEnd;
  const nightshiftStart = dayjs(start).hour(22).minute(0).second(0).$d;
  // const nightshiftEnd = dayjs(nightshiftStart).add(8, "h");
  // const startNight = dayjs(start).isSameOrAfter(nightshiftStart);
  // const isNight = isNightShift(start, end, nightshiftStart, nightshiftEnd);
  if (start < nightshiftStart) {
    console.log("day work exists");
    if (breakStart < nightshiftStart) {
      console.log("break starts in day time");
      //Only day work
      if (end < nightshiftStart) {
        const totalBreak = getDiff(breakStart, breakEnd);
        const dayWork = diffTimes(getDiff(start, end), totalBreak);
        const nightWork = { hours: 0, minutes: 0, time: "00:00" };
        const totalWork = dayWork;
        const overtime = diffTimes(totalWork, { hours: 8, minutes: 0 });
        console.log("day work only");
        if (overtime.hours > 0 || overtime.minutes > 0) {
          console.log("break is only during day with overtime");

          const realDayWork = diffTimes(dayWork, overtime);
          return {
            dayWork: realDayWork,
            nightWork,
            totalWork,
            dayOvertime: overtime,
            nightOvertime: { hours: 0, minutes: 0, time: null },
          };
        }
        return {
          dayWork,
          nightWork,
          totalWork,
          overtime,
          dayOvertime: true,
          nightOvertime: { hours: 0, minutes: 0, time: null },
        };
        //Break is only during day
      } else if (breakEnd < nightshiftStart) {
        const totalBreak = getDiff(breakStart, breakEnd);
        const dayWork = diffTimes(getDiff(start, nightshiftStart), totalBreak);
        const nightWork = getDiff(nightshiftStart, end);
        const totalWork = sumTime([dayWork, nightWork]);
        const overtime = diffTimes(totalWork, { hours: 8, minutes: 0 });
        console.log("break is only during day");
        if (overtime.hours > 0 || overtime.minutes > 0) {
          console.log("break is only during day with overtime");

          const realDayWork = diffTimes(dayWork, overtime);
          return {
            dayWork: realDayWork,
            nightWork,
            totalWork,
            dayOvertime: overtime,
            nightOvertime: { hours: 0, minutes: 0, time: null },
          };
        }
        return { dayWork, nightWork, totalWork, overtime };
        //Break starts in day, but ends in night
      } else {
        //const totalBreak = getDiff(breakStart, breakEnd);
        const dayWork = getDiff(start, breakStart);
        const nightWork = getDiff(breakEnd, end);
        const totalWork = sumTime([dayWork, nightWork]);
        const overtime = diffTimes(totalWork, { hours: 8, minutes: 0 });
        console.log("break starts in day, but ends in night time");
        if (overtime.hours > 0 || overtime.minutes > 0) {
          console.log(
            "break starts in day, but ends in night time with overtime"
          );
          const realNightWork =
            dayWork.hours > 8 || (dayWork.hours >= 8 && dayWork.minutes >= 0)
              ? { hours: 0, minutes: 0, time: null }
              : overtime.hours > 0 ||
                (overtime.hours >= 0 && overtime.minutes > 0)
              ? diffTimes(nightWork, overtime)
              : nightWork;
          const nightOvertime =
            dayWork.hours > 8 || (dayWork.hours >= 8 && dayWork.minutes >= 0)
              ? nightWork
              : overtime.hours > 0 ||
                (overtime.hours >= 0 && overtime.minutes >= 0)
              ? diffTimes(totalWork, { hours: 8, minutes: 0 })
              : { hours: 0, minutes: 0, time: null };
          const realDayWork =
            dayWork.hours > 8 || (dayWork.hours && dayWork.minutes)
              ? { hours: 8, minutes: 0, time: "08:00" }
              : dayWork;
          return {
            dayWork: realDayWork,
            nightWork: realNightWork,
            nightOvertime,
            totalWork,
            dayOvertime:
              realDayWork.hours > 8 ||
              (realDayWork.hours >= 8 && realDayWork.minutes >= 0)
                ? diffTimes(overtime, nightWork)
                : { time: null },
          };
        }
        return {
          dayWork,
          nightWork,
          nightOvertime: { hours: 0, minutes: 0, time: null },
          totalWork,
          overtime,
        };
      }
      //Break starts in night time
    } else {
      const breakS = dayjs(breakStart).isBefore(start)
        ? breakStart.add(1, "day")
        : breakStart;
      const breakE = dayjs(breakEnd).isBefore(start)
        ? breakEnd.add(1, "day")
        : breakEnd;
      const totalBreak = getDiff(breakS, breakE);
      const dayWork = getDiff(start, nightshiftStart);
      const nightWork = diffTimes(getDiff(nightshiftStart, end), totalBreak);
      const totalWork = sumTime([dayWork, nightWork]);
      const overtime = diffTimes(totalWork, { hours: 8, minutes: 0 });
      console.log("Break starts in night time");
      if (overtime.hours > 0 || overtime.minutes > 0) {
        console.log("Break starts in night time with overtime");

        const realNightWork =
          dayWork.hours > 8 || (dayWork.hours >= 8 && dayWork.minutes >= 0)
            ? { hours: 0, minutes: 0, time: null }
            : overtime.hours > 0 ||
              (overtime.hours >= 0 && overtime.minutes > 0)
            ? diffTimes(nightWork, overtime)
            : nightWork;
        const nightOvertime =
          dayWork.hours > 8 || (dayWork.hours >= 8 && dayWork.minutes >= 0)
            ? nightWork
            : overtime.hours > 0 ||
              (overtime.hours >= 0 && overtime.minutes >= 0)
            ? diffTimes(totalWork, { hours: 8, minutes: 0 })
            : { hours: 0, minutes: 0, time: null };
        const realDayWork =
          dayWork.hours > 8 || (dayWork.hours && dayWork.minutes)
            ? { hours: 8, minutes: 0, time: "08:00" }
            : dayWork;
        return {
          dayWork: realDayWork,
          nightWork: realNightWork,
          nightOvertime,
          totalWork,
          dayOvertime:
            realDayWork.hours > 8 ||
            (realDayWork.hours >= 8 && realDayWork.minutes >= 0)
              ? diffTimes(overtime, nightWork)
              : { time: null },
        };
      }
      return {
        dayWork,
        totalBreak,
        nightWork,
        totalWork,
        nightOvertime: { hours: 0, minutes: 0, time: null },
        overtime,
      };
    }
    //Only night work
  } else {
    const breakS = dayjs(breakStart).isBefore(start)
      ? breakStart.add(1, "day")
      : breakStart;
    const breakE = dayjs(breakEnd).isBefore(start)
      ? breakEnd.add(1, "day")
      : breakEnd;
    const totalBreak = getDiff(breakS, breakE);
    const dayWork = { hours: 0, minutes: 0, time: null };
    const nightWork = diffTimes(getDiff(start, end), totalBreak);
    const totalWork = nightWork;
    const overtime = diffTimes(totalWork, { hours: 8, minutes: 0 });
    console.log("work is only night");
    if (overtime.hours > 0 || overtime.minutes > 0) {
      console.log("work is only night with overtime");

      const realNightWork = diffTimes(nightWork, overtime);
      return {
        dayWork,
        nightWork: realNightWork,
        totalWork,
        nightOvertime: overtime,
      };
    }

    return {
      dayWork,
      nightWork,
      totalBreak,
      totalWork,
      overtime,
      nightOvertime:
        overtime.hours > 0 || (overtime.hours >= 0 && overtime.minutes >= 0)
          ? overtime
          : { time: null },
    };
  }
}

export function getUTC(time, date) {
  const d = new Date(time);
  d.setTime(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
  const newDate = new Date(d.setDate(date.getDate()));
  return newDate;
}

//(new Date(item.PocetakRada).getDate() === date.getDate())
