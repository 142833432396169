import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import { Grid, Row, Column, DataTable, Loading } from "carbon-components-react";
import {
  Button,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import DateFnsUtils from "@date-io/date-fns";
import hr from "date-fns/locale/hr";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";

import { AddNewModal } from "./AddNewModal";
//import MessageModal from "./MessageModal";

import { CardTitle, CustomRow, Error } from "../styles";

import { pickBy } from "lodash";
import {
  formatDate,
  addHours,
  disableHolidays,
  getNextWorkDay,
  headers,
} from "../../functions/helpers";

import { handlePostRequest } from "../../helpers/handle-response";

import styled from "styled-components";

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
} = DataTable;

const useStyles = makeStyles({
  root: {
    background:
      "linear-gradient(to right top, #66bb6a, #5db461, #55ad59, #4ca750, #43a047);",
    border: 0,
    color: "white",
  },
  icon: {
    fill: "white",
  },
});

const NewDrivingStatement = ({ currentUser }) => {
  const {
    errors,
    control,
    handleSubmit,
    register,
    setValue,
    triggerValidation,
    getValues,
  } = useForm({
    defaultValues: {
      Datum: new Date(),
      DatumObracuna: getNextWorkDay(new Date(addHours(new Date(), 48))),
      OIB: currentUser.OIB,
      Tvrtka: currentUser.companyName,
      ImePrezime: "",
      ImePrezimeString: "",
    },
  });
  const [dates, setDates] = useState({
    monthYear: new Date(),
    DatumObracuna: getNextWorkDay(new Date(addHours(new Date(), 48))),
  });
  const [flag, setFlag] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [pickedID, setPickedID] = useState({});
  const [open, setOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const classes = useStyles();

  const setValues = () => {
    setInitialValues(getValues());
    return true;
  };

  const getObject = (id) =>
    employees.find((obj) => {
      return obj.value === id;
    });

  const handleOpen = async () => {
    const result = await triggerValidation([
      "Datum",
      "OIB",
      "Tvrtka",
      "ImePrezime",
    ]);
    if (open || result) {
      if (setValues()) {
        setOpen(!open);
      }
    }
  };
  //Register Select
  useEffect(() => {
    register(
      { name: "ImePrezime" },
      { required: "Ime i prezime radnika je obvezno polje" }
    );
    register({ name: "ImePrezimeString" });
  }, [register]);
  // get data from DB, add data to DB from modal,

  const { data: employees, isFetching } = useQuery(
    ["employeesSalary", dates],
    async () => {
      console.log("query is called");
      const { data } = await handlePostRequest(
        "https://apiaccountant.plc.hr/api/p?m=GetEmployeesForPlace",
        [
          {
            user: "igor",
            orgID: Number(currentUser.orgID),
            month: dates.monthYear.getMonth() + 1,
            year: dates.monthYear.getFullYear(),
          },
        ]
      );
      const parsedData = JSON.parse(data);
      const result = Object.keys(parsedData).map((key) => {
        return {
          label: parsedData[key],
          value: key,
        };
      });

      return result;
    },

    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
            return false;
          }
          return true;
        }
      },
    }
  );

  const { data: tableData } = useQuery(
    ["locoVoznjaAll", flag],
    async () => {
      const {
        data: orgData,
      } = await handlePostRequest(
        `https://apiaccountant.plc.hr/api/loco?m=LocoVoznjaGets`,
        [{ user: "igor", orgID: currentUser.orgID }]
      );
      const parsedData = JSON.parse(orgData);
      filterData(getValues(), parsedData);
      return parsedData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );
  const refresh = () => {
    setFlag(Math.random());
  };

  const fetchFullData = async (reqID) => {
    const result = await handlePostRequest(
      `https://apiaccountant.plc.hr/api/loco?m=LocoVoznjaGetByIdFull`,
      [{ user: "igor", reqID: reqID }]
    );
    const data = JSON.parse(result.data);
    setFilteredData(data.items);
  };

  const filterData = (
    data,
    dataToFilter = tableData,
    date = dates.monthYear
  ) => {
    const filtered = pickBy(dataToFilter, function (value, key) {
      // add filter by car + reg
      if (
        value.request.Godina === date.getFullYear() &&
        value.request.Mjesec === date.getMonth() + 1 &&
        value.request.RadnikID === Number(data.ImePrezime)
      )
        return value;
    });

    const reqID = filtered[Object.keys(filtered)[0]]
      ? filtered[Object.keys(filtered)[0]].ID
      : 0;
    console.log({ reqID, filtered, dataToFilter });
    setPickedID(filtered[Object.keys(filtered)[0]]);
    //Destructure object into Items for DataTable here

    reqID !== 0 ? fetchFullData(reqID) : setFilteredData([]);
  };

  const submit = (data) => {
    console.log(data);
  };
  return (
    <>
      <div
        style={{
          display: isFetching ? "block" : "none",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 999999999999999,
        }}
      >
        <Loading />
      </div>
      <Grid style={{ maxWidth: 1250 }}>
        <Row>
          <Column>
            <CardTitle variant="h4">Novi putni nalog</CardTitle>
          </Column>
        </Row>
        <form onSubmit={handleSubmit(submit)}>
          {/* Initial input */}
          <CustomRow marginbottom="2rem !important" style={{ marginTop: 30 }}>
            <Column lg={2}>
              <Controller
                as={
                  <NumberFormat
                    label="OIB"
                    type="text"
                    format="###########"
                    autoComplete="off"
                    variant="filled"
                    customInput={TextField}
                  ></NumberFormat>
                }
                name="OIB"
                control={control}
                defaultValue={currentUser.OIB}
                rules={{
                  required: "OIB je obvezno polje",
                  minLength: {
                    value: 11,
                    message: "OIB mora sadržavati najmanje 11 znakova",
                  },
                  maxLength: {
                    value: 11,
                    message: "OIB mora sadržavati najviše 11 znakova",
                  },
                }}
              />
              <ErrorMessage as={Error} errors={errors} name="OIB" />
            </Column>
            <Column lg={3}>
              <Controller
                as={
                  <TextField
                    autoComplete="off"
                    label="Naziv tvrtke"
                    type="text"
                    variant="filled"
                  />
                }
                name="Tvrtka"
                control={control}
                defaultValue={currentUser.companyName}
                rules={{
                  required: "Tvrtka je obvezno polje",
                }}
              />
              <ErrorMessage as={Error} errors={errors} name="Tvrtka" />
            </Column>
            <Column lg={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hr}>
                <DatePicker
                  openTo="month"
                  variant="inline"
                  inputVariant="filled"
                  autoOk
                  views={["year", "month"]}
                  label="Mjesec i godina"
                  /* helperText="Odaberite mjesec i godinu" */
                  value={dates.monthYear}
                  register={register({
                    name: "Datum",
                  })}
                  onChange={(date) => {
                    setDates({ ...dates, monthYear: date });
                    setValue("Datum", date);
                    filterData(getValues(), tableData, date);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Column>
            <Column lg={3}>
              <FormControl style={{ width: "100%" }} variant="filled">
                <InputLabel id="select">Ime i prezime</InputLabel>

                <Select
                  style={{ width: "100%", color: "white" }}
                  classes={{
                    icon: classes.icon,
                  }}
                  labelId="select"
                  name="ImePrezime"
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                  placeholder="Ime i prezime"
                  onChange={(e) => {
                    setValue(
                      "ImePrezimeString",
                      e.nativeEvent.target.innerText
                    );
                    setValue("ImePrezime", e.target.value);
                    filterData(getValues());
                  }}
                  defaultValue=""
                >
                  {employees &&
                    employees.map((item) => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <ErrorMessage as={Error} errors={errors} name="ImePrezime" />
            </Column>
            <Column lg={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hr}>
                <KeyboardDatePicker
                  variant="inline"
                  inputVariant="filled"
                  autoOk
                  format="dd.MM.yyyy"
                  label="Datum obračuna"
                  minDate={getNextWorkDay(new Date(addHours(new Date(), 48)))}
                  minDateMessage={`Datum ne može biti manji od ${formatDate(
                    getNextWorkDay(new Date(addHours(new Date(), 48)))
                  )}`}
                  shouldDisableDate={disableHolidays}
                  value={dates.DatumObracuna}
                  register={register({
                    name: "DatumObracuna",
                  })}
                  onChange={(date) => {
                    setDates({ ...dates, DatumObracuna: date });
                    setValue("DatumObracuna", date);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Column>
          </CustomRow>
          {/* Buttons */}
          {/*  <Row style={{ marginBottom: 20 }}>
            <Column lg={2}>
              <Button
                classes={{
                  root: classes.root, // class name, e.g. `classes-nesting-root-x`
                  label: classes.label, // class name, e.g. `classes-nesting-label-x`
                }}
                variant="contained"
                type="submit"
              >
                Pošalji zahtjev za isplatu
              </Button>
            </Column>
          </Row> */}
        </form>
        {/* DataTable */}
        <Row>
          <Column align="center">
            <DataTable
              filterRows={function noRefCheck() {}}
              headers={headers}
              isSortable
              locale="en"
              rows={filteredData.map((item) => {
                return {
                  ...item,
                  id: String(item.ID),
                  DatumUnosa: formatDate(item.DatumUnosa),
                  Marka: pickedID.request.VoziloMarka,
                  RegistracijskiBroj: pickedID.request.VoziloRegistracija,
                };
              })}
              render={({
                rows,
                headers,
                getHeaderProps,
                getToolbarProps,
                getTableContainerProps,
              }) => (
                <TableContainer
                  style={{ minWidth: "unset", fontFamily: "IBM Plex Sans" }}
                  {...getTableContainerProps()}
                >
                  <TableToolbar
                    {...getToolbarProps}
                    aria-label="data table toolbar"
                  >
                    <TableToolbarContent
                      style={{ justifyContent: "flex-start" }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleOpen}
                      >
                        Novi unos
                      </Button>
                    </TableToolbarContent>
                  </TableToolbar>
                  {filteredData.length > 0 ? (
                    <Table>
                      <CustomTHead>
                        <TableRow>
                          {headers.map((header) => (
                            <TableHeader {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </CustomTHead>
                      <TableBody>
                        {rows.map((row) => (
                          <CustomTRow key={row.id}>
                            {row.cells.map((cell) => (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            ))}
                          </CustomTRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : null}
                </TableContainer>
              )}
            />
          </Column>
        </Row>
      </Grid>
      <AddNewModal
        currentUser={currentUser}
        getObject={getObject}
        pickedID={pickedID}
        filteredData={filteredData}
        open={open}
        handleOpen={handleOpen}
        initialValues={initialValues}
        fetchData={fetchFullData}
        filterData={filterData}
        refresh={refresh}
      />
    </>
  );
};

export default NewDrivingStatement;

const CustomTHead = styled(TableHead)`
  th {
    padding-left: 0;
    padding-right: 0;
  }
  button {
    padding: 0.5rem !important;
  }
`;
const CustomTRow = styled(TableRow)`
  td {
    padding: 0;
    padding-top: 0.5rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
`;
