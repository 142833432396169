import React from "react";
import { DataTable, Pagination, InlineLoading } from "carbon-components-react";
import { WarningAltFilled32, CheckmarkFilled32 } from "@carbon/icons-react";
import { DownloadButton } from "../styles";
import {
  handlePostRequest,
  handleGetRequest,
} from "../../helpers/handle-response";
import fileDownload from "js-file-download";

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
} = DataTable;

const getID = (value) => {
  const index = value.indexOf(":");
  return Number(value.slice(0, index));
};

const CheckoutTable = ({
  data,
  currentUser,
  isDownloaded,
  setIsDownloaded,
}) => {
  const [page, setPage] = React.useState({ current: 1, pageSize: 10 });
  const filteredData = data.map((item, index) => {
    return {
      key: String(index),
      id: String(item.ID),
      Status: item.ack.DnlPdfAck || item.ack.DnlXmlAck,
      MjesecGodina: item.virman.Mjesec + " / " + item.virman.Godina,
      Opis: item.virman.Opis,
      StatusPDF: item.virman.DnlPdf,
      StatusXML: item.virman.DnlXml,
    };
  });
  const onPageChange = (pageChange) => {
    setPage({ current: pageChange.page, pageSize: pageChange.pageSize });
  };

  const startItem = (page.current - 1) * page.pageSize;
  const endItem = startItem + page.pageSize;
  const displayedRows = filteredData.slice(startItem, endItem);

  const confirmDownload = (downloadedState, current, ID, callback) => {
    const VirmanAck = Object.assign(
      {
        DnlPdfAck: downloadedState.DnlPdfAck,
        DnlXmlAck: downloadedState.DnlXmlAck,
        AckTime: new Date(),
        AckUser: "",
      },
      current
    );
    handlePostRequest(
      "https://apiaccountant.plc.hr/api/v?m=PlacanjaVirmaniUpdateAck",
      [{ user: "igor", reqID: ID, a: JSON.stringify(VirmanAck) }]
    )
      .then((res) => {
        callback(res.data);
      })
      .catch((err) => console.log(err));
  };

  const downloadFiles = React.useCallback(
    (url, name, type, index, downloadedState, ID, loading) => {
      setIsDownloaded((isDownloaded) => ({
        ...isDownloaded,
        [index]: { ...isDownloaded[index], [loading]: true },
      }));

      handleGetRequest(
        `https://www.api.accountant.hr/private/${url}`,
        currentUser.token,
        "blob"
      )
        .then((res) => {
          fileDownload(res.data, name);

          setIsDownloaded((isDownloaded) => ({
            ...isDownloaded,
            [index]: { ...isDownloaded[index], [type]: true, [loading]: false },
          }));
          confirmDownload(
            downloadedState[index],
            { [type]: true },
            ID,
            (res) => {
              // console.log(res);
            }
          );
        })
        .catch((err) => console.log(err));
    },
    [currentUser, setIsDownloaded]
  );
  return (
    <div style={{ margin: "auto", width: "100%" }}>
      <DataTable
        filterRows={function noRefCheck() {}}
        headers={[
          {
            header: "Status",
            key: "Status",
          },
          {
            header: "Mjesec / Godina",
            key: "MjesecGodina",
          },
          {
            header: "Opis",
            key: "Opis",
          },
          {
            header: "Preuzmi PDF",
            key: "StatusPDF",
          },
          {
            header: "Preuzmi XML",
            key: "StatusXML",
          },
          { key: "key", header: "Key" },
        ]}
        isSortable
        locale="hr"
        rows={displayedRows}
        render={({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
        }) => (
          <TableContainer style={{ minWidth: "unset" }}>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => {
                    if (header.key === "key") {
                    } else {
                      return (
                        <TableHeader {...getHeaderProps({ header })}>
                          {header.header}
                        </TableHeader>
                      );
                    }
                    return null;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      if (cell.info.header === "Status") {
                        return (
                          <TableCell key={cell.id}>
                            {cell.value === false ||
                            cell.value === "waiting" ? (
                              <WarningAltFilled32
                                style={{ width: "100%", height: "100%" }}
                                fill="#FD970F"
                              />
                            ) : (
                              <CheckmarkFilled32
                                style={{ width: "100%", height: "100%" }}
                                fill="#4DA851"
                              />
                            )}
                          </TableCell>
                        );
                      } else if (cell.info.header === "key") {
                      } else if (cell.info.header === "StatusPDF") {
                        return (
                          <TableCell key={cell.id}>
                            <DownloadButton
                              variant="contained"
                              disabled={
                                isDownloaded[Number(row.cells[5].value)].pdf
                              }
                              onClick={() => {
                                downloadFiles(
                                  `${cell.value.replace(/\s/g, "")}`,
                                  cell.value,
                                  "DnlPdfAck",
                                  Number(row.cells[5].value),
                                  isDownloaded,
                                  getID(cell.id),
                                  "pdf"
                                );
                              }}
                            >
                              {isDownloaded[Number(row.cells[5].value)].pdf ? (
                                <InlineLoading
                                  description="Preuzimanje..."
                                  iconDescription="Active loading indicator"
                                  status="active"
                                  successDelay={1500}
                                />
                              ) : (
                                cell.value.replace(/\s/g, "")
                              )}
                            </DownloadButton>
                          </TableCell>
                        );
                      } else if (cell.info.header === "StatusXML") {
                        return (
                          <TableCell key={cell.id}>
                            <DownloadButton
                              variant="contained"
                              disabled={
                                isDownloaded[Number(row.cells[5].value)].xml
                              }
                              onClick={() =>
                                downloadFiles(
                                  `${cell.value.replace(/\s/g, "")}`,
                                  cell.value,
                                  "DnlXmlAck",
                                  Number(row.cells[5].value),
                                  isDownloaded,
                                  getID(cell.id),
                                  "xml"
                                )
                              }
                            >
                              {isDownloaded[Number(row.cells[5].value)].xml ? (
                                <InlineLoading
                                  description="Preuzimanje..."
                                  iconDescription="Active loading indicator"
                                  status="active"
                                  successDelay={1500}
                                />
                              ) : (
                                cell.value.replace(/\s/g, "")
                              )}
                            </DownloadButton>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={cell.id}>{cell.value}</TableCell>
                        );
                      }
                      return null;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      />
      <Pagination
        style={{ overflow: "hidden" }}
        backwardText="Prethodna stranica"
        disabled={false}
        forwardText="Sljedeća stranica"
        isLastPage={false}
        itemRangeText={function noRefCheck() {}}
        itemText={function noRefCheck() {}}
        itemsPerPageText="Broj stavki po stranici:"
        onChange={onPageChange}
        page={1}
        pageNumberText="Page Number"
        pageRangeText={function noRefCheck() {}}
        pageSize={10}
        pageSizes={[10, 20, 30, 40, 50]}
        pageText={function noRefCheck() {}}
        pagesUnknown={false}
        totalItems={filteredData.length}
      />
    </div>
  );
};

export default CheckoutTable;
