import React from "react";
import fileDownload from "js-file-download";
import { Grid, Row, Column, DataTable } from "carbon-components-react";
import hr from "date-fns/locale/hr";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { CardTitle, Error, CInlineLoading as InlineLoading } from "../styles";
import { handlePostRequest } from "../../helpers/handle-response";
import { headers, months } from "./constants";
import Modal from "./Modal";
import styled from "styled-components";
import { b64toBlob } from "../../functions/helpers";
import { useQuery } from "react-query";

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
} = DataTable;

const TerenskiDodatak = ({ currentUser }) => {
  const { errors, control, handleSubmit, getValues, triggerValidation } =
    useForm();
  const [month, setMonth] = React.useState(new Date());
  // const [employees, setEmployees] = React.useState([
  //   { value: "", label: "", start: "", stop: "" },
  // ]);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [downloading, setDownloading] = React.useState(false);
  const handleOpen = async () => {
    const result = await triggerValidation(["ImePrezime", "Mjesto", "Svrha"]);
    if (open || result) {
      setOpen(!open);
    }
  };

  const getPDF = (formData) => {
    setDownloading(true);
    const info = {
      ...formData,
      companyName: currentUser.companyName,
      OIB: currentUser.OIB,
      Mjesec: `${months[month.getMonth()]} ${month.getFullYear()}`,
    };
    const allSum = data.reduce((prev, curr) => {
      return Number(prev) + Number(curr.UkupnoIsplata);
    }, 0);
    handlePostRequest(
      `https://pdf-api.accountant.hr/create-pdf/`,
      [
        {
          name: "terenski",
          info,
          items: data,
          allSum,
        },
      ],
      currentUser.token
    )
      .then((response) => {
        console.log(response);
        setDownloading(false);
        fileDownload(
          b64toBlob(response.data),
          "TerenskiDodatak_" + formData.ImePrezime + "_" + info.Mjesec + ".pdf"
        );

        //get pdf file here
      })
      .catch((err) => console.log(err));
  };
  const { data: employees, isFetching } = useQuery(
    ["employees", month],
    async () => {
      const { data } = await handlePostRequest(
        `https://apiaccountant.plc.hr/api/t?m=GetEmployees`,
        [
          {
            user: "igor",
            orgID: currentUser.orgID,
            mjesec: month.getMonth() + 1,
            godina: month.getFullYear(),
          },
        ]
      );
      const formattedData = JSON.parse(data).map((item) => {
        return {
          value: `${item.Ime} ${item.Prezime}`,
          label: `${item.Ime} ${item.Prezime}`,
          start: item.prijava,
          stop: item.odjava,
        };
      });

      return formattedData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );

  return (
    <>
      <Grid>
        <Row lg={4} xs={12}>
          <Column>
            <CardTitle maxwidth="400px" variant="h4">
              Obračun terenskog dodatka
            </CardTitle>
          </Column>
        </Row>
      </Grid>
      <Grid>
        <Row style={{ justifyContent: "center" }}>
          <Column sm={6} md={4} lg={2} align="center">
            <Controller
              as={<TextField label="Mjesto" type="text" variant="filled" />}
              name="Mjesto"
              control={control}
              rules={{
                required: "Mjesto je obvezno polje",
              }}
              defaultValue=""
            />
            <ErrorMessage as={Error} errors={errors} name="Mjesto" />
          </Column>
          <Column sm={6} md={4} lg={2} align="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hr}>
              <DatePicker
                openTo="month"
                variant="inline"
                inputVariant="filled"
                autoOk
                views={["year", "month"]}
                label="Mjesec"
                value={month}
                onChange={(date) => setMonth(date)}
              />
            </MuiPickersUtilsProvider>
          </Column>
          <Column sm={6} md={4} lg={2} align="center">
            {isFetching ? (
              "Učitavanje"
            ) : (
              <FormControl style={{ width: "100%" }} variant="filled">
                <InputLabel id="select">Radnik</InputLabel>
                <Controller
                  as={
                    <Select
                      style={{ width: "100%" }}
                      labelId="select"
                      name="ImePrezime"
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      {!isFetching &&
                        employees.map((item, index) => {
                          return (
                            <MenuItem
                              key={item.value + index}
                              value={item.value}
                            >
                              {item.label}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  }
                  name="ImePrezime"
                  onChange={(e) => {
                    setData([]);
                    return e[0].target.value;
                  }}
                  control={control}
                  placeholder="Radnik"
                  rules={{
                    required: "Radnik je obvezno polje",
                  }}
                  defaultValue=""
                />
              </FormControl>
            )}
            <ErrorMessage as={Error} errors={errors} name="ImePrezime" />
          </Column>
          <Column sm={6} md={4} lg={2} align="center">
            <Controller
              as={<TextField label="Svrha" variant="filled"></TextField>}
              name="Svrha"
              control={control}
              defaultValue=""
              rules={{
                required: "Svrha je obvezno polje",
              }}
            />
            <ErrorMessage as={Error} errors={errors} name="Svrha" />
          </Column>
          <Column sm={6} md={4} lg={2} align="center">
            <Controller
              as={
                <TextField label="Popis isprava" variant="filled"></TextField>
              }
              name="PopisIsprava"
              control={control}
              defaultValue=""
            />
            <ErrorMessage as={Error} errors={errors} name="PopisIsprava" />
          </Column>
        </Row>
        {/*  <Row></Row> */}
      </Grid>
      <Grid style={{ marginTop: 50 }}>
        <Row>
          <Column>
            <DataTable
              filterRows={function noRefCheck() {}}
              headers={headers}
              isSortable
              locale="en"
              rows={data}
              render={({
                rows,
                headers,
                getHeaderProps,
                getToolbarProps,
                getTableContainerProps,
              }) => (
                <TableContainer
                  style={{ minWidth: "unset", fontFamily: "IBM Plex Sans" }}
                  {...getTableContainerProps()}
                >
                  <TableToolbar
                    {...getToolbarProps}
                    aria-label="data table toolbar"
                  >
                    <TableToolbarContent
                      style={{ justifyContent: "flex-start" }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleOpen}
                      >
                        Novi unos
                      </Button>
                      {downloading ? (
                        <InlineLoading
                          style={{ width: "unset" }}
                          description="Preuzimanje..."
                          spancolor="black"
                          iconDescription="Active loading indicator"
                          status="active"
                          successDelay={1500}
                        />
                      ) : (
                        <Button
                          style={{ marginLeft: 5 }}
                          color="primary"
                          variant="contained"
                          onClick={handleSubmit(getPDF)}
                        >
                          Preuzmi PDF
                        </Button>
                      )}
                    </TableToolbarContent>
                  </TableToolbar>
                  {data.length > 0 ? (
                    <Table>
                      <CustomTHead>
                        <TableRow>
                          {headers.map((header) => (
                            <TableHeader {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </CustomTHead>
                      <TableBody>
                        {rows.map((row) => (
                          <CustomTRow key={row.id}>
                            {row.cells.map((cell) => (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            ))}
                          </CustomTRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : null}
                </TableContainer>
              )}
            />
          </Column>
        </Row>
      </Grid>
      <Modal
        open={open}
        handleClose={handleOpen}
        itemsData={data}
        mainData={getValues()}
        setData={setData}
        employees={employees}
      />
    </>
  );
};

export default TerenskiDodatak;

const CustomTHead = styled(TableHead)`
  th {
    padding-left: 0;
    padding-right: 0;
  }
  button {
    padding: 0.5rem !important;
  }
`;
const CustomTRow = styled(TableRow)`
  td {
    padding: 0;
    padding-top: 0.5rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
`;
