export const headers = [
  {
    header: "Datum odlaska",
    key: "DatumOdlazak",
  },
  {
    header: "Datum povratka",
    key: "DatumPovratak",
  },
  {
    header: "Broj dana provedenih na terenu",
    key: "BrojDana",
  },
  {
    header: "Svota terenskog dodatka po danu",
    key: "SvotaPoDanu",
  },
  {
    header: "Ukupna svota terenskog dodataka",
    key: "UkupnaSvota",
  },
  {
    header: "Svota ostalih troskova",
    key: "SvotaTroskova",
  },
  {
    header: "Ukupno za isplatu",
    key: "UkupnoIsplata",
  },
];

export const formatUTCDate = (date) => {
  return `${date.$y}-${date.$M + 1}-${date.$D}`;
};

export const months = {
  0: "Siječanj",
  1: "Veljača",
  2: "Ožujak",
  3: "Travanj",
  4: "Svibanj",
  5: "Lipanj",
  6: "Srpanj",
  7: "Kolovoz",
  8: "Rujan",
  9: "Listopad",
  10: "Studeni",
  11: "Prosinac"
};