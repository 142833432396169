import { holidays } from "../variables/index";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

export const formatDate = (value, withoutYear) => {
  const date = new Date(value);
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();
  if (withoutYear) {
    return `${day}.${month}.`;
  } else {
    return `${day}.${month}.${year}`;
  }
};
export const truncate = (string, n, useWordBoundary) => {
  console.log(string, string.length, n);
  if (string.length <= n) {
    return string;
  }
  var subString = string.substr(0, n - 1);
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + "&hellip;"
  );
};
export const formatNumber = (number) => {
  return number.toLocaleString("hr-HR", { style: "currency", currency: "HRK" });
};
export const addHours = (date, h) => {
  return date.setTime(date.getTime() + h * 60 * 60 * 1000);
};
export function getNextWorkDay(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const toCompareWith = `${year}-${month}-${day}`;
  if (
    holidays.includes(toCompareWith) ||
    date.getDay() === 0 ||
    date.getDay() === 6
  ) {
    return getNextWorkDay(new Date(date.setDate(date.getDate() + 1)));
  } else {
    return date;
  }
}

export function disableHolidays(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const toCompareWith = `${year}-${month}-${day}`;
  return (
    holidays.includes(toCompareWith) ||
    date.getDay() === 0 ||
    date.getDay() === 6
  );
}
export const formatValue = (name, value) => {
  if (name.includes("Datum")) {
    return formatDate(value);
  } else if (name === "Invalidnost" || name === "MIO2") {
    return value === 0 ? "Ne" : "Da";
  } else if (name === "Bruto1placa" || name === "Prijevoz") {
    return formatNumber(value);
  } else {
    return value;
  }
};
function timeFormat(h, m) {
  let hours = h;
  let minutes = m;
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}`;
}

export function getDiff(start, end) {
  console.log(start, end);
  const hours = dayjs.duration(dayjs(end).diff(start)).$d.hours;
  const minutes = dayjs.duration(dayjs(end).diff(start)).$d.minutes;
  return { hours, minutes, time: timeFormat(hours, minutes) };
}

export const headers = [
  {
    header: "Datum",
    key: "DatumUnosa",
  },
  {
    header: "Marka vozila",
    key: "Marka",
  },
  {
    header: "Registracijski broj",
    key: "RegistracijskiBroj",
  },
  {
    header: "PocetnoStanje",
    key: "BrojiloPocetno",
  },
  {
    header: "Završno stanje",
    key: "BrojiloZavrsno",
  },
  {
    header: "Relacija",
    key: "Relacija",
  },
  {
    header: "Vrijeme",
    key: "Vrijeme",
  },
  {
    header: "Prijeđeni KM",
    key: "PrijedeniKilometri",
  },
  {
    header: "Nadoknada",
    key: "NadoknadaKune",
  },
  {
    header: "Izvješće",
    key: "Izvjesce",
  },
];

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export const getObject = (id, data) =>
  data.find((obj) => {
    return obj.ID === id;
  });
