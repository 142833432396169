import React from "react";
import {
  DataTable,
  Button,
  Pagination,
  InlineLoading,
  FileUploaderButton,
} from "carbon-components-react";
import { formatDate } from "../../functions/helpers";
import styled from "styled-components";

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
} = DataTable;

const AdminTable = ({
  data,
  downloading,
  currentUser,
  setDownloading,
  sendFile,
  filesToUpload,
  setFilesToUpload,
  sending,
  getPDF,
}) => {
  const [page, setPage] = React.useState({ current: 1, pageSize: 10 });
  const filteredData = data.map((item, index) => {
    console.log(item);
    return {
      id: String(item.ID),
      Tvrtka: item.request.OrganizationNaziv,
      Radnik: item.request.RadnikImePrezime,
      Vozilo:
        item.request.VoziloMarka + " - " + item.request.VoziloRegistracija,
      MjesecGodina: item.request.Mjesec + " / " + item.request.Godina,
      DatumIsplate: formatDate(item.request.DatumObracuna),
      PreuzmiPDF: item.response.File1Ref,
      Upload: item.response.File1Ref,
    };
  });
  console.log(data, filteredData);
  const onPageChange = (pageChange) => {
    setPage({ current: pageChange.page, pageSize: pageChange.pageSize });
  };

  const startItem = (page.current - 1) * page.pageSize;
  const endItem = startItem + page.pageSize;
  const displayedRows = filteredData.slice(startItem, endItem);

  const getObj = (id) => {
    const item = data.find((item) => {
      if (item.ID === id) {
        return item;
      }
      return null;
    });
    return item;
  };

  console.log(downloading);
  return (
    <div style={{ margin: "auto", width: "100%" }}>
      <DataTable
        filterRows={function noRefCheck() {}}
        headers={[
          {
            header: "Tvrtka",
            key: "Tvrtka",
          },
          {
            header: "Radnik",
            key: "Radnik",
          },
          {
            header: "Vozilo",
            key: "Vozilo",
          },
          {
            header: "Mjesec / Godina",
            key: "MjesecGodina",
          },
          {
            header: "Datum isplate",
            key: "DatumIsplate",
          },
          {
            header: "Preuzmi PDF",
            key: "PreuzmiPDF",
          },
          {
            header: "Upload",
            key: "Upload",
          },
          {
            header: "Pošalji",
            key: "Confirm",
          },
        ]}
        isSortable
        locale="hr"
        rows={displayedRows}
        render={({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
        }) => (
          <TableContainer style={{ minWidth: "unset" }}>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      if (cell.info.header === "PreuzmiPDF") {
                        return (
                          <TableCell key={cell.id}>
                            <CButton
                              variant="contained"
                              color="primary"
                              disabled={
                                downloading[Number(row.id)] ? true : false
                              }
                              style={{
                                height: 55,
                                textTransform: "capitalize",
                              }}
                              onClick={() => {
                                getPDF(Number(row.id));
                              }}
                            >
                              {downloading[Number(row.id)] ? (
                                <InlineLoading
                                  description=""
                                  iconDescription="Active loading indicator"
                                  status="active"
                                  successDelay={1500}
                                />
                              ) : (
                                "Preuzmi PDF"
                              )}
                            </CButton>
                          </TableCell>
                        );
                      } else if (cell.info.header === "Upload") {
                        return (
                          <TableCell key={cell.id}>
                            <FileUploaderButton
                              name="pdf"
                              labelText={
                                cell.value.length < 1
                                  ? "Nalog - Upload"
                                  : "Datoteka već poslana"
                              }
                              role="button"
                              className={`MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle ${
                                !cell.value.length < 1 ? "whitespaceNorm" : " "
                              }`}
                              style={
                                cell.value.length < 1
                                  ? { height: 55 }
                                  : { height: 55, backgroundColor: "#2e7d32" }
                              }
                              onChange={(e) => {
                                e.persist();
                                setFilesToUpload({
                                  ...filesToUpload,
                                  [Number(row.id)]: e.target.files[0],
                                });
                              }}
                            />
                          </TableCell>
                        );
                      } else if (cell.info.header === "Confirm") {
                        return (
                          <TableCell key={cell.id}>
                            <CButton
                              disabled={sending[Number(row.id)] ? true : false}
                              variant="contained"
                              color="primary"
                              style={{
                                height: 55,
                                textTransform: "capitalize",
                                fontSize: 14,
                                padding: 10,
                                width: 110,
                              }}
                              onClick={() => sendFile(getObj(Number(row.id)))}
                            >
                              {sending[Number(row.id)] ? (
                                <InlineLoading
                                  description="Slanje..."
                                  iconDescription="Active loading indicator"
                                  status="active"
                                  successDelay={1500}
                                />
                              ) : (
                                "Pošalji nalog"
                              )}
                            </CButton>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={cell.id}>{cell.value}</TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      />
      <Pagination
        style={{ overflow: "hidden" }}
        backwardText="Prethodna stranica"
        disabled={false}
        forwardText="Sljedeća stranica"
        isLastPage={false}
        itemRangeText={function noRefCheck() {}}
        itemText={function noRefCheck() {}}
        itemsPerPageText="Broj stavki po stranici:"
        onChange={onPageChange}
        page={1}
        pageNumberText="Page Number"
        pageRangeText={function noRefCheck() {}}
        pageSize={10}
        pageSizes={[10, 20, 30, 40, 50]}
        pageText={function noRefCheck() {}}
        pagesUnknown={false}
        totalItems={filteredData.length}
      />
    </div>
  );
};

export default AdminTable;

const CButton = styled(Button)`
  padding: 10px;
  margin: auto;
  width: 160px;
  div[class="bx--loading bx--loading--small"] {
    svg {
      height: 2rem !important;
    }
  }
  div[class="bx--inline-loading__text"] {
    color: black;
  }
  div[class="bx--inline-loading__animation"] {
    margin: auto;
  }
`;
