import React from "react";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import NumberFormat from "react-number-format";
import {
  Grid,
  Row,
  Column,
  RadioButtonGroup,
  RadioButton,
} from "carbon-components-react";
import {
  CustomColumn,
  CustomInput,
  Error,
  CustomFormGroup as FormGroup,
  CustomSelect as Select,
  CustomInputLabel as InputLabel,
} from "./styles";
import hr from "date-fns/locale/hr";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  MenuItem,
  FormControl,
  Button,
  InputAdornment,
} from "@material-ui/core";
import {
  formatDate,
  addHours,
  getNextWorkDay,
  disableHolidays,
} from "../../functions/helpers";

const options = [
  { value: "NKV", label: "NKV" },
  { value: "PKV", label: "PKV" },
  { value: "NSS", label: "NSS" },
  { value: "KV", label: "KV" },
  { value: "SSS", label: "SSS" },
  { value: "VKV", label: "VKV" },
  { value: "VŠS", label: "VŠS" },
  { value: "VSS", label: "VSS" },
  { value: "VSS-mr.", label: "VSS-mr." },
  { value: "VSS-dr.", label: "VSS-dr." },
  { value: "VSS-dr.sp.", label: "VSS-dr.sp." },
];

const Zaposlenik = ({ handleNext, handleBack, onSubmit }) => {
  const { action, state } = useStateMachine(updateAction);
  const {
    errors,
    control,
    handleSubmit,
    register,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      DatumPocetkaRada: state.data.DatumPocetkaRada,
      RadnoMjesto: state.data.RadnoMjesto,
      StrucnaSpremaZaObavljanjePosla: state.data.StrucnaSpremaZaObavljanjePosla,
      RadnoVrijemeTjedno: state.data.RadnoVrijemeTjedno,
      Bruto1placa: state.data.Bruto1placa,
      Prijevoz: state.data.Prijevoz,
      Invalidnost: state.data.Invalidnost,
      Napomene: state.data.Napomene,
    },
  });

  const back = (data) => {
    action(getValues());
    handleBack();
  };
  const submit = (data) => {
    action(data);
    console.log({ ...state.data, ...data });
    onSubmit({ ...state.data, ...data });
    handleNext();
  };
  return (
    <>
      <CustomColumn md={8} lg={6} style={{ margin: "auto" }}>
        <h3>Podaci o radnom mjestu</h3>
        <Grid>
          <Row style={{ marginTop: 15, marginBottom: 15 }}>
            <Column sm={4} lg={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hr}>
                <Controller
                  name="DatumPocetkaRada"
                  control={control}
                  as={
                    <KeyboardDatePicker
                      disableToolbar
                      style={{ margin: 5, width: "100%" }}
                      variant="inline"
                      inputVariant="filled"
                      format="dd.MM.yyyy"
                      margin="normal"
                      value={state.data.DatumPocetkaRada}
                      autoOk
                      id="date-picker-inline"
                      label="Datum početka rada"
                      minDate={getNextWorkDay(
                        new Date(addHours(new Date(), 48))
                      )}
                      minDateMessage={`Datum ne može biti manji od ${formatDate(
                        getNextWorkDay(new Date(addHours(new Date(), 48)))
                      )}`}
                      shouldDisableDate={disableHolidays}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  }
                />
              </MuiPickersUtilsProvider>
            </Column>
            <Column sm={4} lg={6}>
              <Controller
                as={
                  <CustomInput
                    autoComplete="off"
                    label="Radno mjesto"
                    type="text"
                    variant="filled"
                  />
                }
                name="RadnoMjesto"
                control={control}
                rules={{ required: "Radno mjesto je obvezno polje" }}
              />
              <ErrorMessage as={Error} errors={errors} name="RadnoMjesto" />
            </Column>
          </Row>
          <Row style={{ marginTop: 15, marginBottom: 15 }}>
            <Column sm={4} lg={6}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="select">
                  Str. sprema za obavljanje posla
                </InputLabel>
                <Controller
                  as={
                    <Select
                      style={{ margin: 5, width: "100%" }}
                      labelId="select"
                      variant="filled"
                      name="StrucnaSpremaZaObavljanjePosla"
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      {options.map((item) => {
                        return (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  name="StrucnaSpremaZaObavljanjePosla"
                  control={control}
                  rules={{
                    required: "Stručna sprema je obvezno polje",
                  }}
                />
              </FormControl>
              <ErrorMessage
                as={Error}
                errors={errors}
                name="StrucnaSpremaZaObavljanjePosla"
              />
            </Column>
            <Column sm={4} lg={6}>
              <Controller
                as={
                  <CustomInput
                    autoComplete="off"
                    label="Radno vrijeme tjedno"
                    type="number"
                    variant="filled"
                    min={0}
                  />
                }
                name="RadnoVrijemeTjedno"
                control={control}
                rules={{
                  required: "Radno vrijeme tjedno je obvezno polje",
                  min: { value: 1, message: "Minimalno vrijeme je 1" },
                }}
              />
              <ErrorMessage
                as={Error}
                errors={errors}
                name="RadnoVrijemeTjedno"
              />
            </Column>
          </Row>
          <Row style={{ marginTop: 15, marginBottom: 15 }}>
            <Column sm={4} lg={6}>
              <Controller
                as={
                  <NumberFormat
                    allowNegative={false}
                    customInput={CustomInput}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    label="Bruto 1 plaća"
                    variant="filled"
                    min={0}
                    autoComplete="off"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">HRK</InputAdornment>
                      ),
                    }}
                  />
                }
                name="Bruto1placa"
                control={control}
                rules={{
                  required: "Bruto plaća 1 je obvezno polje",
                  min: { value: 1, message: "Minimalan iznos je 1" },
                }}
              />
              <ErrorMessage as={Error} errors={errors} name="Bruto1placa" />
            </Column>
            <Column sm={4} lg={6}>
              <Controller
                as={
                  <NumberFormat
                    allowNegative={false}
                    customInput={CustomInput}
                    label="Prijevoz"
                    decimalScale={2}
                    fixedDecimalScale={true}
                    variant="filled"
                    autoComplete="off"
                    min={0}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">HRK</InputAdornment>
                      ),
                    }}
                  />
                }
                name="Prijevoz"
                control={control}
                rules={{
                  min: { value: 1, message: "Minimalan iznos je 1" },
                }}
              />
              <ErrorMessage as={Error} errors={errors} name="Prijevoz" />
            </Column>
          </Row>
          <Row style={{ marginTop: 15, marginBottom: 15 }}>
            <Column sm={4} lg={6}>
              <FormGroup
                invalid={false}
                legendText="Invalidnost"
                message={false}
                messageText=""
              >
                <Controller
                  as={
                    <RadioButtonGroup
                      defaultSelected="0"
                      labelPosition="right"
                      legend="Group Legend"
                      name="radio-button-group"
                      orientation="horizontal"
                      valueSelected="0"
                    >
                      <RadioButton
                        className="some-class"
                        id="radio-5"
                        labelText="Da"
                        value="1"
                      />
                      <RadioButton
                        className="some-class"
                        id="radio-6"
                        labelText="Ne"
                        value="0"
                      />
                    </RadioButtonGroup>
                  }
                  name="Invalidnost"
                  control={control}
                  rules={{ required: "Invalidnost je obvezno polje" }}
                />
                <ErrorMessage as={Error} errors={errors} name="Invalidnost" />
              </FormGroup>
            </Column>
          </Row>
          <Row style={{ marginTop: 15, marginBottom: 15 }}>
            <Column sm={4} lg={6}>
              <Controller
                as={
                  <CustomInput
                    autoComplete="off"
                    label="Napomene"
                    type="text"
                    variant="filled"
                    multiline
                    rows="3"
                  />
                }
                name="Napomene"
                control={control}
              />
              <ErrorMessage as={Error} errors={errors} name="Napomene" />
            </Column>
          </Row>
        </Grid>
      </CustomColumn>
      <Grid style={{ maxWidth: 850 }}>
        <Row style={{ marginTop: 25, justifyContent: "flex-end" }}>
          <Column
            sm={3}
            lg={3}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button variant="contained" color="primary" onClick={back}>
              Natrag
            </Button>
          </Column>
          <Column
            sm={3}
            lg={3}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(submit)}
            >
              Pošalji
            </Button>
          </Column>
        </Row>
      </Grid>
    </>
  );
};

export default Zaposlenik;
