import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Row, Column, Loading, Button } from "carbon-components-react";
import { CheckmarkFilled32 } from "@carbon/icons-react";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import { addHours, getNextWorkDay } from "../../functions/helpers";

const LastStep = ({ isLoading }) => {
  const { action } = useStateMachine(updateAction);

  let history = useHistory();
  return (
    <Grid>
      {isLoading.state ? (
        <Row>
          <Column style={{ margin: "auto", maxWidth: 350 }}>
            <Loading
              style={{ margin: "auto" }}
              description="Slanje..."
              withOverlay={false}
            />
            <h2 style={{ textAlign: "center" }}>Slanje zahtjeva</h2>
          </Column>
        </Row>
      ) : (
        <Row>
          <Column style={{ margin: "auto", maxWidth: 350 }}>
            <CheckmarkFilled32
              style={{ width: "100%", height: "150px" }}
              fill="#4DA851"
            ></CheckmarkFilled32>
            <h2 style={{ textAlign: "center" }}>Zahtjev poslan</h2>
            <Button
              onClick={() => {
                action({
                  Ime: "",
                  Prezime: "",
                  OIB: "",
                  Adresa: "",
                  Spol: "",
                  OsobniBrojOsiguranika: "",
                  DatumRodenja: new Date(),
                  MIO2: "",
                  StrucnaSprema: "",
                  NajvisaZavrsenaSkola: "",
                  IBANredovni: "",
                  IBANzasticeni: "",
                  DatumPocetkaRada: new Date(
                    getNextWorkDay(new Date(addHours(new Date(), 48)))
                  ),
                  RadnoMjesto: "",
                  StrucnaSpremaZaObavljanjePosla: "",
                  RadnoVrijemeTjedno: "",
                  Bruto1placa: "",
                  Prijevoz: "",
                  Invalidnost: "",
                  Napomene: "",
                });
                history.push("/prijava-pregled");
              }}
              style={{ margin: "auto", display: "flex", marginTop: 30 }}
            >
              Natrag
            </Button>
          </Column>
        </Row>
      )}
    </Grid>
  );
};

export default LastStep;
