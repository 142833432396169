import React from "react";
import { useQuery } from "react-query";
import { Row, Column } from "carbon-components-react";
import {
  MenuItem,
  FormControl,
  TextField,
  InputLabel,
} from "@material-ui/core";
import { Controller, ErrorMessage, useFormContext } from "react-hook-form";
import { handlePostRequest } from "../../helpers/handle-response";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import { CustomGrid, CustomSelect as Select, Error } from "../styles";

const BasicInfo = ({
  currentUser,
  date,
  setDate,
  setMonthYear,
  monthYear,
  disableForm,
}) => {
  const { control, errors, setValue } = useFormContext(); // retrieve all hook methods

  const { data: employees, isFetching } = useQuery(
    "employeesSihterica",
    async () => {
      const { data } = await handlePostRequest(
        "https://apiaccountant.plc.hr/api/p?m=GetEmployeesForPlace",
        [
          {
            user: "igor",
            orgID: Number(currentUser.orgID),
            month: monthYear.getMonth() + 1,
            year: monthYear.getFullYear(),
          },
        ]
      );

      const parsedData = JSON.parse(data);
      console.log(parsedData);
      const result = Object.keys(parsedData).map((key) => {
        const split = parsedData[key].split(" ");

        return {
          label: parsedData[key],
          value: key,
          FirstName: split[0],
          LastName: split[1],
        };
      });
      console.log(result);
      return result;
    },

    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );
  return (
    !isFetching && (
      <CustomGrid margin="auto" style={{ maxWidth: 1200 }}>
        <Row>
          <Column lg={3}>
            <Controller
              as={
                <TextField
                  style={{ width: "100%" }}
                  autoComplete="off"
                  disabled={disableForm}
                  label="Tvrtka"
                  type="text"
                  variant="filled"
                />
              }
              name="Tvrtka"
              control={control}
              defaultValue={currentUser.companyName}
              rules={{
                required: "Tvrtka je obvezno polje",
              }}
            />
            <ErrorMessage as={Error} errors={errors} name="Tvrtka" />
          </Column>
          <Column lg={3}>
            <FormControl style={{ width: "100%" }} variant="filled">
              <InputLabel id="select">Ime i prezime</InputLabel>
              <Controller
                as={
                  <Select
                    disabled={disableForm}
                    clr="white"
                    style={{ width: "100%" }}
                    labelId="select"
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    {employees.map((item) => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
                name="ImePrezime"
                control={control}
                placeholder="ImePrezime"
                onChange={([event]) => {
                  console.log(event.nativeEvent);

                  let label = event.nativeEvent.target.innerText;
                  console.log(event.target, label);
                  setValue("ImePrezime", event.target.value);
                  setValue(
                    "ImePrezimeString",
                    event.nativeEvent.target.innerText
                  );
                  return event.target.value;
                }}
                rules={{
                  required: "Ime i prezime je obvezno polje",
                }}
              />
              <ErrorMessage as={Error} errors={errors} name="ImePrezime" />
            </FormControl>
          </Column>

          <Column lg={3}>
            <FormControl style={{ width: "100%" }} variant="filled">
              <InputLabel id="select">Mjesec</InputLabel>

              <Controller
                as={
                  <Select
                    disabled={disableForm}
                    clr="white"
                    variant="filled"
                    inputProps={{
                      name: "month",
                      id: "month",
                    }}
                    placeholder="Mjesec"
                  >
                    <MenuItem value={0}>Siječanj</MenuItem>
                    <MenuItem value={1}>Veljača</MenuItem>
                    <MenuItem value={2}>Ožujak</MenuItem>
                    <MenuItem value={3}>Travanj</MenuItem>
                    <MenuItem value={4}>Svibanj</MenuItem>
                    <MenuItem value={5}>Lipanj</MenuItem>
                    <MenuItem value={6}>Srpanj</MenuItem>
                    <MenuItem value={7}>Kolovoz</MenuItem>
                    <MenuItem value={8}>Rujan</MenuItem>
                    <MenuItem value={9}>Listopad</MenuItem>
                    <MenuItem value={10}>Studeni</MenuItem>
                    <MenuItem value={11}>Prosinac</MenuItem>
                  </Select>
                }
                name="Mjesec"
                control={control}
                placeholder="Mjesec"
                onChange={([event]) => {
                  const newDate = new Date(date.setMonth(event.target.value));
                  setDate(new Date(newDate));
                  setMonthYear(new Date(newDate));
                  setValue("Mjesec", newDate.getMonth());
                  return event.target.value;
                }}
                rules={{
                  required: "Mjesec je obvezno polje",
                }}
              />
            </FormControl>
          </Column>
          <Column lg={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                inputVariant="filled"
                disabled={disableForm}
                views={["year"]}
                name="Godina"
                style={{ width: "100%" }}
                label="Godina"
                value={date}
                onChange={(date) => {
                  setDate(date);
                  setMonthYear(date);
                  setValue("Godina", date);
                }}
                /*   onChange={(date) =>
                  handleInfoChange(date, "UPDATE_INFO", "year")
                } */
              />
            </MuiPickersUtilsProvider>
          </Column>
        </Row>
      </CustomGrid>
    )
  );
};

export default BasicInfo;
