import React from "react";
import {
  Grid,
  Row,
  Column,
  FileUploaderButton,
  InlineNotification,
  InlineLoading,
} from "carbon-components-react";
import {
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import hr from "date-fns/locale/hr";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "react-query";

import { useForm, Controller, ErrorMessage } from "react-hook-form";

import { CardTitle, CustomGrid, Error, CustomSelect } from "../styles";

import { handlePostRequest } from "../../helpers/handle-response";

const useStyles = makeStyles({
  root: {
    background:
      "linear-gradient(to right top, #66bb6a, #5db461, #55ad59, #4ca750, #43a047);",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 50,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(67, 160, 71, .3)",
  },
  label: {
    textTransform: "capitalize",
  },
});
const submit = (
  data,
  setRequestPending,
  setStatus,
  filesToUpload,
  currentUser,
  reset,
  setFilesToUpload
) => {
  setRequestPending(true);
  if (filesToUpload.pdf.length < 1 || filesToUpload.xml.length < 1) {
    setStatus({ msg: "Niste odabrali sve datoteke", state: "error" });
    setTimeout(() => {
      setStatus({ msg: "", state: "" });
      setRequestPending(false);
    }, 3000);
  } else {
    const Files = new FormData();
    for (let prop in filesToUpload) {
      Files.append("files", filesToUpload[prop]);
    }
    const VirmanUpload = {
      Mjesec: data.Datum.getMonth() + 1,
      Godina: data.Datum.getFullYear(),
      Opis: data.Opis,
      DnlPdf: filesToUpload.pdf.name.replace(/\s/g, ""),
      DnlXml: filesToUpload.xml.name.replace(/\s/g, ""),
      CreateTime: new Date(),
      CreateUser: "",
    };
    handlePostRequest(
      `https://www.api.accountant.hr/users/uploadFiles`,
      [Files],
      currentUser.token
    ).then((res) => {
      if (res.data === true) {
        //send data to server if files are uploaded
        handlePostRequest(
          "https://apiaccountant.plc.hr/api/v?m=PlacanjaVirmaniAddUpload",
          [
            {
              user: "igor",
              orgID: data.Tvrtka,
              r: JSON.stringify(VirmanUpload),
            },
          ]
        )
          .then((res) => {
            if (res.data === "True") {
              setRequestPending(false);
              setStatus({
                msg: "Poslano!",
                state: "success",
              });
              reset({
                Tvrtka: "",
                Datum: new Date(),
                Opis: "",
                pdf: "",
                xml: "",
              });
              setFilesToUpload({ pdf: [], xml: [] });
            }
          })
          .catch((err) => console.log(err));
      }
    });
  }
};
const NewVirman = ({ currentUser }) => {
  const [monthYear, setMonthYear] = React.useState(new Date());
  const { errors, control, handleSubmit, register, reset, setValue } = useForm({
    defaultValues: { Datum: new Date(), Opis: "" },
  });
  const [filesToUpload, setFilesToUpload] = React.useState({
    pdf: [],
    xml: [],
  });
  const [status, setStatus] = React.useState({ msg: "", state: "" });
  const [requestPending, setRequestPending] = React.useState(false);
  const classes = useStyles();

  const { data: organizations, isFetching } = useQuery(
    "organizationsPDV",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        "https://apiaccountant.plc.hr/api/p?m=GetOrganizations",
        [{ user: "igor" }]
      );
      console.log(data);
      const parsedData = JSON.parse(data);
      console.log(parsedData);
      return parsedData;
    },

    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );
  return (
    !isFetching && (
      <Grid style={{ maxWidth: 1250 }}>
        <Row>
          <Column>
            <CardTitle variant="h4">Dugovanja</CardTitle>
          </Column>
        </Row>
        <form
          onSubmit={handleSubmit((data) =>
            submit(
              data,
              setRequestPending,
              setStatus,
              filesToUpload,
              currentUser,
              reset,
              setFilesToUpload
            )
          )}
        >
          <CustomGrid helpercolor="white">
            <Row>
              <Column
                className="bx--offset-md-2 bx--offset-lg-0"
                sm={4}
                md={4}
                lg={3}
                align="center"
              >
                <FormControl style={{ width: "100%" }} variant="filled">
                  <InputLabel id="select">Tvrtka</InputLabel>
                  <Controller
                    as={
                      <CustomSelect
                        clr="white"
                        style={{ width: "100%" }}
                        labelId="select"
                        name="Tvrtka"
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                      >
                        {Object.keys(organizations).map((key, index) => {
                          return (
                            <MenuItem
                              key={organizations[key].OrganizationID}
                              value={organizations[key].OrganizationID}
                            >
                              {organizations[key].Naziv}
                            </MenuItem>
                          );
                        })}
                      </CustomSelect>
                    }
                    name="Tvrtka"
                    control={control}
                    placeholder="Tvrtka"
                    rules={{
                      required: "Naziv tvrtke je obvezno polje",
                    }}
                    defaultValue=""
                  />
                </FormControl>
                <ErrorMessage as={Error} errors={errors} name="Tvrtka" />
              </Column>
              <Column lg={2} sm={4} md={4} align="center">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hr}>
                  <DatePicker
                    openTo="month"
                    variant="inline"
                    inputVariant="filled"
                    autoOk
                    name="Datum"
                    views={["year", "month"]}
                    label="Mjesec i godina"
                    helperText="Odaberite mjesec i godinu"
                    value={monthYear}
                    style={{ width: "100%" }}
                    register={register({
                      name: "Datum",
                    })}
                    onChange={(date) => {
                      setMonthYear(date);
                      setValue("Datum", date);
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Column>
              <Column lg={3} sm={4} md={4} align="center">
                <Controller
                  as={
                    <TextField
                      label="Opis"
                      type="text"
                      className="customInput"
                      variant="filled"
                    />
                  }
                  name="Opis"
                  control={control}
                  rules={{ required: "Opis je obvezno polje" }}
                />
                <ErrorMessage as={Error} errors={errors} name="Opis" />
              </Column>
              <Column lg={2} sm={4} md={4} align="center">
                <FileUploaderButton
                  name="pdf"
                  accept={[".pdf"]}
                  style={{ height: 55 }}
                  labelText="PDF Upload"
                  role="button"
                  className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                  onChange={(e) => {
                    e.persist();
                    setFilesToUpload({
                      ...filesToUpload,
                      pdf: e.target.files[0],
                    });
                  }}
                />
              </Column>
              <Column lg={2} sm={4} md={4} align="center">
                <FileUploaderButton
                  accept={[".xml"]}
                  name="xml"
                  style={{ height: 55 }}
                  labelText="XML Upload"
                  role="button"
                  className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                  onChange={(e) => {
                    e.persist();
                    setFilesToUpload({
                      ...filesToUpload,
                      xml: e.target.files[0],
                    });
                  }}
                />
              </Column>
            </Row>
          </CustomGrid>
          <Row align="center" style={{ marginTop: 50 }}>
            <Column>
              <Button
                disabled={requestPending}
                classes={{
                  root: classes.root, // class name, e.g. `classes-nesting-root-x`
                  label: classes.label, // class name, e.g. `classes-nesting-label-x`
                }}
                variant="contained"
                type="submit"
              >
                {requestPending ? (
                  <InlineLoading
                    description="Slanje..."
                    iconDescription="Active loading indicator"
                    status="active"
                    successDelay={1500}
                  />
                ) : (
                  "Pošalji"
                )}
              </Button>
            </Column>
          </Row>
        </form>
        <Row>
          <Column align="center">
            {status.msg.length > 1 ? (
              <InlineNotification
                style={{ maxWidth: 400 }}
                lowContrast
                hideCloseButton={false}
                iconDescription="describes the close button"
                kind={status.state}
                notificationType="inline"
                onCloseButtonClick={() => setStatus({ msg: "", state: "" })}
                role="alert"
                statusIconDescription="describes the status icon"
                title={status.msg}
              />
            ) : null}
          </Column>
        </Row>
      </Grid>
    )
  );
};

export default NewVirman;
