import React from "react";
import { useQuery } from "react-query";
import { Grid, Row, Column, DataTableSkeleton } from "carbon-components-react";
import { sleep } from "../../functions/helpers";
import { Modal } from "./Modal";
import { handlePostRequest } from "../../helpers/handle-response";
import CheckoutTable from "../CheckoutTable";
import { CardTitle } from "../styles";

const AdminCheckoutReview = ({ currentUser, isClient }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);
  /* const [data, setData] = React.useState([]);
  const [organizations, setOrganizations] = React.useState({});
  const [isFetched, setIsFetched] = React.useState(false); */
  const [picked, setPicked] = React.useState(0);
  const [fetchFlag, setFetchFlag] = React.useState(0);

  const { data, isLoading } = useQuery(
    ["checkouts", fetchFlag],
    async () => {
      const {
        data,
      } = await handlePostRequest(
        "https://apiaccountant.plc.hr/api/r?m=RadniciAllGets",
        [{ user: "igor" }]
      );
      const parsedData = JSON.parse(data);
      const formatData = parsedData
        .filter((item) => {
          if (item.odjavaReq.OdjavaReqUser === "waiting") {
            return false;
          } else return true;
        })
        .map((item) => {
          return { ...item, Radnik: item.info.Ime + " " + item.info.Prezime };
        })
        .sort((a, b) => {
          return (
            new Date(a.odjavaReq.DatumOdjave) -
            new Date(b.odjavaReq.DatumOdjave)
          );
        });
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );
  const { data: organizations } = useQuery(
    "organizationsCheckouts",
    async () => {
      await sleep(200);
      const {
        data: orgData,
      } = await handlePostRequest(
        "https://apiaccountant.plc.hr/api/p?m=GetOrganizations",
        [{ user: "igor" }]
      );
      const formattedData = JSON.parse(orgData);
      return data.map((item) => {
        return { ...item, OrgName: formattedData[item.OrganizationID].Naziv };
      });
    },

    {
      enabled: data,
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );

  return (
    <>
      <Grid>
        <Row lg={6} xs={12}>
          <Column>
            <CardTitle maxwidth="320px" variant="h4">
              Pregled odjava zaposlenika
            </CardTitle>
          </Column>
        </Row>
      </Grid>
      <Grid>
        <Row>
          {!isLoading && organizations ? (
            <CheckoutTable
              currentUser={currentUser}
              data={organizations}
              setPicked={setPicked}
              handleOpen={handleOpen}
            />
          ) : (
            <div style={{ width: "100%" }}>
              <DataTableSkeleton showToolbar={false} showHeader={false} />
            </div>
          )}
        </Row>
      </Grid>
      {organizations && picked ? (
        <Modal
          isClient={currentUser && isClient}
          currentUser={currentUser}
          open={open}
          handleOpen={handleOpen}
          picked={picked}
          allItems={data}
          organizations={organizations}
          setFetchFlag={setFetchFlag}
        ></Modal>
      ) : null}
    </>
  );
};

export default AdminCheckoutReview;
