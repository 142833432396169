import React from "react";
import {
  DataTable,
  Button,
  Pagination,
  InlineLoading,
} from "carbon-components-react";
import { WarningAltFilled32, CheckmarkFilled32 } from "@carbon/icons-react";
import { formatDate } from "../../functions/helpers";
import { handleGetRequest } from "../../helpers/handle-response";
import fileDownload from "js-file-download";
import styled from "styled-components";

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
} = DataTable;

const ClientTable = ({
  data,
  downloading,
  currentUser,
  setDownloading,
  sendFile,
}) => {
  const [page, setPage] = React.useState({ current: 1, pageSize: 10 });
  const filteredData = data.map((item, index) => {
    console.log(item);
    return {
      id: String(index),
      RadnikVozilo:
        item.request.RadnikImePrezime +
        " - " +
        item.request.VoziloMarka +
        " - " +
        item.request.VoziloRegistracija,
      MjesecGodina: item.request.Mjesec + " / " + item.request.Godina,
      DatumIsplate: formatDate(item.request.DatumObracuna),
      Status: item.response.ResponseUser,
      PreuzmiPDF: item.response.File1Ref,
    };
  });
  console.log(data, filteredData);
  const onPageChange = (pageChange) => {
    setPage({ current: pageChange.page, pageSize: pageChange.pageSize });
  };

  const startItem = (page.current - 1) * page.pageSize;
  const endItem = startItem + page.pageSize;
  const displayedRows = filteredData.slice(startItem, endItem);

  const getPDF = React.useCallback(
    (row, url, name, id) => {
      const item = data.find((item, index) => {
        if (index === id) {
          return item;
        }
        return null;
      });

      setDownloading((downloading) => {
        return { ...downloading, [row]: true };
      });

      handleGetRequest(url, currentUser.token, "blob")
        .then((res) => {
          console.log(res);
          fileDownload(res.data, name);
          setDownloading((downloading) => {
            return { ...downloading, [row]: false };
          });
          //Confirm file download
          sendFile(item);
        })
        .catch((err) => console.log(err));
    },
    [currentUser, setDownloading, data, sendFile]
  );
  console.log(downloading);
  return (
    <div style={{ margin: "auto", width: "100%" }}>
      <DataTable
        filterRows={function noRefCheck() {}}
        headers={[
          {
            header: "Radnik - Vozilo",
            key: "RadnikVozilo",
          },
          {
            header: "Mjesec / Godina",
            key: "MjesecGodina",
          },
          {
            header: "Datum isplate",
            key: "DatumIsplate",
          },
          {
            header: "Status",
            key: "Status",
          },
          {
            header: "Preuzmi PDF",
            key: "PreuzmiPDF",
          },
        ]}
        isSortable
        locale="hr"
        rows={displayedRows}
        render={({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
        }) => (
          <TableContainer style={{ minWidth: "unset" }}>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      if (cell.info.header === "PreuzmiPDF") {
                        return (
                          <TableCell key={cell.id}>
                            <CButton
                              variant="contained"
                              color="primary"
                              disabled={
                                downloading[cell.id]
                                  ? true
                                  : cell.value === ""
                                  ? true
                                  : false
                              }
                              style={{
                                height: 55,
                                textTransform: "capitalize",
                              }}
                              /*  startIcon={
                                downloading[cell.id] ? null : <GetAppIcon />
                              } */
                              onClick={() => {
                                getPDF(
                                  cell.id,
                                  `https://www.api.accountant.hr/private/${cell.value.replace(
                                    /\s/g,
                                    ""
                                  )}`,
                                  cell.value.replace(/\s/g, ""),
                                  row.id
                                );
                              }}
                            >
                              {downloading[cell.id] ? (
                                <InlineLoading
                                  description=""
                                  iconDescription="Active loading indicator"
                                  status="active"
                                  successDelay={1500}
                                />
                              ) : (
                                "Preuzmi PDF"
                              )}
                            </CButton>
                          </TableCell>
                        );
                      } else if (cell.info.header === "Status") {
                        return (
                          <TableCell key={cell.id}>
                            {cell.value === "waiting" ? (
                              <WarningAltFilled32
                                style={{ width: "100%", height: "100%" }}
                                fill="#FD970F"
                              />
                            ) : (
                              <CheckmarkFilled32
                                style={{ width: "100%", height: "100%" }}
                                fill="#4DA851"
                              />
                            )}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={cell.id}>{cell.value}</TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      />
      <Pagination
        style={{ overflow: "hidden" }}
        backwardText="Prethodna stranica"
        disabled={false}
        forwardText="Sljedeća stranica"
        isLastPage={false}
        itemRangeText={function noRefCheck() {}}
        itemText={function noRefCheck() {}}
        itemsPerPageText="Broj stavki po stranici:"
        onChange={onPageChange}
        page={1}
        pageNumberText="Page Number"
        pageRangeText={function noRefCheck() {}}
        pageSize={10}
        pageSizes={[10, 20, 30, 40, 50]}
        pageText={function noRefCheck() {}}
        pagesUnknown={false}
        totalItems={filteredData.length}
      />
    </div>
  );
};

export default ClientTable;

const CButton = styled(Button)`
  padding: 10px;
  margin: auto;
  width: 160px;
  div[class="bx--loading bx--loading--small"] {
    svg {
      height: 2rem !important;
    }
  }
  div[class="bx--inline-loading__text"] {
    color: black;
  }
  div[class="bx--inline-loading__animation"] {
    margin: auto;
  }
`;
