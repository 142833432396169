import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from "@material-ui/core";
import "./Tablica.scss";
/* import TableHead from "@material-ui/core/TableHead"; */
/* import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; */
import style from "./TimesheetTable.module.scss";

import { getHolidays } from "./helperFunc";
import { sumTime } from "./timeFunc";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    maxWidth: "1857px",
    margin: "auto",
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(1),
    display: "flex",
    overflow: "hidden",
  },
  table: {
    minWidth: 1200,
    border: "1px solid black",
  },
  tableCell: {
    padding: "6px 1px 6px 1px",
    textAlign: "center",
    /* width: 20, */
    border: "1px solid black",
    borderCollapse: "inherit",
    width: 50,
  },
  smallRow: {
    fontSize: 13,
    height: 33,
  },
  tableRotate: {
    borderCollapse: "collapse",
  },
  td: {
    border: "1px solid black",
  },
  extraPadding: {
    padding: "4px !important",
    border: "1px solid black",
    borderLeft: "2px solid black",
    textAlign: "center",
    width: 59,
  },
}));

function timeFormat(obj) {
  const hours = obj.hours < 10 ? `0${obj.hours}` : `${obj.hours}`;
  const minutes = obj.minutes < 10 ? `0${obj.minutes}` : `${obj.minutes}`;
  if (obj.hours === 0 && obj.minutes === 0) {
    return null;
  } else {
    return `${hours}:${minutes}`;
  }
}

function sumTimes(days, name) {
  const filter = days.map((item) => {
    if (item[name] === null) {
      return null;
    } else {
      const split = item[name].split(":");
      return { hours: Number(split[0]), minutes: Number(split[1]) };
    }
  });
  return timeFormat(sumTime(filter));
}
function sumAllTimes(days) {
  const values = [];
  days.map((item) => {
    for (let key in item) {
      if (
        key !== "TerenskiRad" &&
        key !== "SatiPripravnosti" &&
        key !== "id" &&
        key !== "ID" &&
        key !== "SihtericaID" &&
        key !== "PocetakRada" &&
        key !== "ZavrsetakRada" &&
        key !== "SatiZastojFrom" &&
        key !== "SatiZastojTill" &&
        key !== "CreatedByIP" &&
        key !== "CreateTime" &&
        key !== "CreatedByUser" &&
        key !== "UkupnoDnevnoRV" &&
        key !== "alreadyAdded"
      ) {
        if (item[key] !== null) {
          const split = item[key].split(":");
          values.push({ hours: Number(split[0]), minutes: Number(split[1]) });
        }
      }
    }
    return null;
  });
  return timeFormat(sumTime(values));
}
const TimesheetTable = ({
  info,
  days,
  totalSum,
  addTimes,
  setHolidaysTotal,
  setTotalSum,
}) => {
  React.useEffect(() => {
    setTotalSum(sumAllTimes(days));
  }, [setTotalSum, days]);
  const getTime = React.useCallback((date) => {
    if (date === null || date === "NaN:NaN" || !isValidDate(date)) {
      return null;
    }
    if (typeof date === "string") {
      date = new Date(date);
    }
    let hours = date.getHours();
    let minutes = date.getMinutes();
    if (date.getHours() < 10) {
      hours = `0${date.getHours()}`;
    }
    if (date.getMinutes() < 10) {
      minutes = `0${date.getMinutes()}`;
    }
    if (hours < 1 && minutes < 0) {
      return null;
    } else {
      return `${hours}:${minutes}`;
    }
  }, []);

  const [sundays, setSundays] = React.useState([]);

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  React.useEffect(() => {
    console.log(info);
    getHolidays(info, setSundays, setHolidaysTotal);
  }, [info, setHolidaysTotal]);

  /*  React.useEffect(() => {
    setAllItemsSum(totalSums);
    setTotalSum(addTimes(totalSums));
    // check all useallcallback
  }, [addTimes, setTotalSum]); */

  /*   const handleChange = debounce((event, type, day, stateType) => {
    dispatch({
      type: type,
      id: day.id,
      value: event,
      stateOption: stateType,
      sundays: sundays
    });
  }, 500); */
  const allstyle = { fontWeight: 400, fontStyle: "normal" };
  const classes = useStyles();

  return (
    <div className={style.root}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 250, minWidth: 250 }}
          >
            <TableBody>
              <TableRow style={{ height: 66 }}>
                <TableCell border={1} component="th" scope="row" align="center">
                  OPIS
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Početak rada
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Završetak rada
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ height: 66 }}
                >
                  Sati zastoja bez krivnje radnika
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Ukupno dnevno radno vrijeme
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table className={`${classes.table} ${style.tableAuto}`} size="small">
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  colSpan="31"
                >
                  DANI U MJESECU
                </TableCell>
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="th"
                      className={classes.tableCell}
                      scope="row"
                      style={
                        sundays.includes(Number(day.id))
                          ? { color: "red" }
                          : null
                      }
                    >
                      {day.id}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p style={{ marginBottom: 0 }}>
                        {getTime(day.PocetakRada)}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p style={{ marginBottom: 0 }}>
                        {getTime(day.ZavrsetakRada)}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {getTime(day.SatiZastojFrom)}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {getTime(day.SatiZastojTill)}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {day.UkupnoDnevnoRV === "00:00"
                        ? null
                        : day.UkupnoDnevnoRV}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 62, minWidth: 62 }}
          >
            <TableBody>
              <TableRow style={{ height: 99 }}>
                <TableCell border={1} component="th" scope="row" align="center">
                  <p style={{ writingMode: "vertical-lr" }}>UKUPNO</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto" }}
                >
                  x
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto" }}
                >
                  x
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto" }}
                >
                  x
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto" }}
                >
                  {sumTimes(days, "UkupnoDnevnoRV")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Paper className={classes.paper}>
          <table className="tg">
            <thead>
              <tr>
                <th
                  className="tg-0lax"
                  colSpan="9"
                  style={{ verticalAlign: "middle" }}
                >
                  <span>Terenski rad</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tg-0lax" colSpan="9">
                  <span style={{ fontSize: 12 }}>
                    Sati pripravnosti, te sati rada po pozivu
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  className="tg-0lax vertical"
                  style={{ width: "unset !important" }}
                  rowSpan="16"
                >
                  <p>Vrijeme nenazočnosti na poslu</p>
                </td>
                <td
                  className="tg-0lax vertical"
                  style={{ width: "unset !important" }}
                  colSpan="2"
                  rowSpan="3"
                >
                  <p>Odmor</p>
                </td>
                <td
                  className="tg-0lax"
                  colSpan="6"
                  style={{ paddingBottom: 10 }}
                >
                  <span>Dnevni odmor</span>
                </td>
              </tr>
              <tr>
                <td className="tg-0lax" colSpan="6">
                  <span style={allstyle}>Tjedni odmor</span>
                </td>
              </tr>
              <tr>
                <td
                  className="tg-0lax"
                  colSpan="6"
                  style={{ paddingBottom: 10 }}
                >
                  <span style={allstyle}>Godišnji odmor</span>
                </td>
              </tr>
              <tr>
                <td className="tg-0lax" colSpan="8">
                  <span style={allstyle}>Plaćeni neradni dani i blagdani</span>
                </td>
              </tr>
              <tr>
                <td
                  className="tg-0lax"
                  colSpan="8"
                  style={{ paddingBottom: 7 }}
                >
                  <span style={{ fontSize: 13 }}>
                    Privremena nesposobnost za{" "}
                  </span>
                  rad <br></br>(bolovanje)
                </td>
              </tr>
              <tr>
                <td className="tg-0lax" colSpan="8">
                  <span style={allstyle}>Komplikacije u trudnoći</span>
                </td>
              </tr>
              <tr>
                <td className="tg-6b7u" colSpan="8">
                  Rodiljni dopust
                </td>
              </tr>
              <tr>
                <td className="tg-6b7u" colSpan="8">
                  Roditeljski dopust
                </td>
              </tr>
              <tr>
                <td className="tg-6b7u" colSpan="8">
                  Mirovanje radnog odnosa
                </td>
              </tr>
              <tr>
                <td className="tg-6b7u" colSpan="8" style={{ fontSize: 13 }}>
                  Korištenje drugih prava sukladno <br></br>drugim propisima
                </td>
              </tr>
              <tr>
                <td className="tg-6b7u" colSpan="8">
                  Sati plaćenog dopusta
                </td>
              </tr>
              <tr>
                <td className="tg-6b7u" colSpan="8">
                  Sati NEplaćenog dopusta
                </td>
              </tr>
              <tr>
                <td className="tg-6b7u" colSpan="8">
                  Izostanak na zahtjev radnika
                </td>
              </tr>
              <tr>
                <td className="tg-6b7u" colSpan="8">
                  Izostanak s posla bez odobrenja
                </td>
              </tr>
              <tr>
                <td className="tg-6b7u" colSpan="8">
                  Sati provedeni u štrajku
                </td>
              </tr>
              <tr>
                <td className="tg-0lax" colSpan="8">
                  Sati isključenja s rada (lockout)
                </td>
              </tr>
            </tbody>
          </table>
          <Table className={`${classes.table} ${style.tableAuto}`} size="small">
            <TableBody>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>
                        {day.TerenskiRad === "00:00" ? null : day.TerenskiRad}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 40 }}
                    >
                      <p>
                        {day.SatiPripravnosti === "00:00"
                          ? null
                          : day.SatiPripravnosti}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 41 }}
                    >
                      <p>
                        {day.DnevniOdmor === "00:00" ? null : day.DnevniOdmor}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 40 }}
                    >
                      <p>
                        {day.TjedniOdmor === "00:00" ? null : day.TjedniOdmor}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 41 }}
                    >
                      <p>
                        {day.GodisnjiOdmor === "00:00"
                          ? null
                          : day.GodisnjiOdmor}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 40 }}
                    >
                      <p>
                        {day.PlaceniNDBlagdani === "00:00"
                          ? null
                          : day.PlaceniNDBlagdani}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 59 }}
                    >
                      <p>{day.Bolovanje === "00:00" ? null : day.Bolovanje}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 40 }}
                    >
                      <p>
                        {day.KomplikacijeUTrudnoci === "00:00"
                          ? null
                          : day.KomplikacijeUTrudnoci}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 40 }}
                    >
                      <p>
                        {day.RodiljniDopust === "00:00"
                          ? null
                          : day.RodiljniDopust}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 40 }}
                    >
                      <p>
                        {day.RoditeljskiDopust === "00:00"
                          ? null
                          : day.RoditeljskiDopust}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 40 }}
                    >
                      <p>
                        {day.MirovanjeRadnogOdnosa === "00:00"
                          ? null
                          : day.MirovanjeRadnogOdnosa}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>

              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 57 }}
                    >
                      <p>
                        {day.KoristenjeDrugihPrava === "00:00"
                          ? null
                          : day.KoristenjeDrugihPrava}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 40 }}
                    >
                      <p>
                        {day.SatiPlacenogDopusta === "00:00"
                          ? null
                          : day.SatiPlacenogDopusta}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 40 }}
                    >
                      <p>
                        {day.SatiNeplacenogDopusta === "00:00"
                          ? null
                          : day.SatiNeplacenogDopusta}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 40 }}
                    >
                      <p>
                        {day.IzostanakNaZahtjev === "00:00"
                          ? null
                          : day.IzostanakNaZahtjev}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 40 }}
                    >
                      <p>
                        {day.IzostanakBezOdobrenja === "00:00"
                          ? null
                          : day.IzostanakBezOdobrenja}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 40 }}
                    >
                      <p>
                        {day.SatiUStrajku === "00:00" ? null : day.SatiUStrajku}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 40 }}
                    >
                      <p>
                        {day.SatiIskljucenjaSRada === "00:00"
                          ? null
                          : day.SatiIskljucenjaSRada}
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 62, minWidth: 62 }}
          >
            {/* change totalWork params to actual values */}
            <TableBody>
              <TableRow style={{}}>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumTimes(days, "TerenskiRad")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 40 }}
                >
                  {sumTimes(days, "SatiPripravnosti")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 41 }}
                >
                  {sumTimes(days, "DnevniOdmor")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 40 }}
                >
                  {sumTimes(days, "TjedniOdmor")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 41 }}
                >
                  {sumTimes(days, "GodisnjiOdmor")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 40 }}
                >
                  {sumTimes(days, "PlaceniNDBlagdani")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 59 }}
                >
                  {sumTimes(days, "Bolovanje")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 40 }}
                >
                  {sumTimes(days, "KomplikacijeUTrudnoci")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 40 }}
                >
                  {sumTimes(days, "RodiljniDopust")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 40 }}
                >
                  {sumTimes(days, "RoditeljskiDopust")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 40 }}
                >
                  {sumTimes(days, "MirovanjeRadnogOdnosa")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 57 }}
                >
                  {sumTimes(days, "KoristenjeDrugihPrava")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 40 }}
                >
                  {sumTimes(days, "SatiPlacenogDopusta")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 40 }}
                >
                  {sumTimes(days, "SatiNeplacenogDopusta")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 40 }}
                >
                  {sumTimes(days, "IzostanakNaZahtjev")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 40 }}
                >
                  {sumTimes(days, "IzostanakBezOdobrenja")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 40 }}
                >
                  {sumTimes(days, "SatiUStrajku")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 40 }}
                >
                  {sumTimes(days, "SatiIskljucenjaSRada")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Paper className={classes.paper} style={{ margin: "5px auto" }}>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 33, minWidth: 33, height: 100 }}
          >
            <TableBody>
              <TableRow>
                <TableCell
                  component="td"
                  scope="row"
                  align="center"
                  style={{ padding: 0 }}
                >
                  <p
                    style={{
                      writingMode: "vertical-rl",
                      transform: "rotate(180deg)",
                      margin: "auto",
                      height: 90,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    RAD NOĆU
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 216, minWidth: 216 }}
          >
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Redovan rad
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  className={classes.smallRow}
                >
                  Blagdanom i neradnim danom
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Prekovremeni sati rada
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table className={`${classes.table} ${style.tableAuto}`} size="small">
            <TableBody>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {day.NocniRedovanRad === "00:00"
                        ? null
                        : day.NocniRedovanRad}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {day.NocniBlagdanNeradni === "00:00"
                        ? null
                        : day.NocniBlagdanNeradni}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {day.NocniPrekovremeni === "00:00"
                        ? null
                        : day.NocniPrekovremeni}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 62, minWidth: 62 }}
          >
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumTimes(days, "NocniRedovanRad")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumTimes(days, "NocniBlagdanNeradni")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumTimes(days, "NocniPrekovremeni")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Paper className={classes.paper} style={{ margin: "5px auto" }}>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 33, minWidth: 33, height: 100 }}
          >
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ padding: 0 }}
                >
                  <p
                    style={{
                      writingMode: "vertical-rl",
                      transform: "rotate(180deg)",
                      margin: "auto",
                      height: 90,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    RAD DANJU
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 216, minWidth: 216 }}
          >
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Redovan rad
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  className={classes.smallRow}
                >
                  Blagdanom i neradnim danom
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Prekovremeni sati rada
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table className={`${classes.table} ${style.tableAuto}`} size="small">
            <TableBody>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {day.DanjiRedovanRad === "00:00"
                        ? null
                        : day.DanjiRedovanRad}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {day.DanjiBlagdanNeradni === "00:00"
                        ? null
                        : day.DanjiBlagdanNeradni}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {day.DanjiPrekovremeni === "00:00"
                        ? null
                        : day.DanjiPrekovremeni}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 62, minWidth: 62 }}
          >
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumTimes(days, "DanjiRedovanRad")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumTimes(days, "DanjiBlagdanNeradni")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumTimes(days, "DanjiPrekovremeni")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>

        <Table
          style={{
            width: 214,
            alignSelf: "flex-end",
            marginTop: "-5px",
            marginRight: 1,
          }}
        >
          <TableBody>
            <TableRow>
              <TableCell
                style={{ padding: 4, border: "1px solid black", width: 130 }}
              >
                UKUPNO:
              </TableCell>

              <TableCell className={classes.extraPadding}>{totalSum}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default React.memo(TimesheetTable);
