import React from "react";
import { useForm, FormContext } from "react-hook-form";
import { Route, useHistory } from "react-router-dom";
import { Grid, Row, Column, Loading } from "carbon-components-react";
import SihetericaList from "./SihtericaList";
import WorkTimesheet from "./WorkTimesheet";
import { initialDays } from "./constants/index";
import { CardTitle } from "../styles";

const daysReducer = (state, action) => {
  switch (action.type) {
    case "SET_CHOSEN":
      return state.map((day) => {
        if (day.id === action.id) {
          return { ...day, chosenValue: action.chosen };
        } else {
          return day;
        }
      });
    case "SET_DATA":
      return action.state;
    case "DELETE_VALUE":
      return state.map((day) => {
        if (day.id === action.id) {
          return { ...day, [action.stateOption]: null };
        } else {
          return day;
        }
      });
    case "SET_TIME":
      return state.map((day) => {
        if (Number(day.id) === action.id) {
          console.log(day, action.stateOption);
          return { ...day, ...action.stateOption };
        } else {
          return day;
        }
      });
    default:
      return state;
  }
};

const Evidencija = ({ currentUser }) => {
  let history = useHistory();
  const [days, dispatch] = React.useReducer(daysReducer, initialDays);
  const [date, setDate] = React.useState(new Date(new Date().setDate(1)));
  const [monthYear, setMonthYear] = React.useState(
    new Date(new Date().setDate(1))
  );
  const [currentItem, setCurrentItem] = React.useState(-1);

  const [disableForm, setDisableForm] = React.useState(false);
  const methods = useForm({
    defaultValues: {
      ImePrezime: "",
      Mjesec: date.getMonth(),
      Godina: date,
      ImePrezimeString: "",
    },
  });
  const [reload, setReload] = React.useState(false);

  const createNew = () => {
    setReload(true);
    setDisableForm(false);

    methods.reset({
      ImePrezime: "",
      Mjesec: new Date().getMonth(),
      Godina: new Date().getFullYear(),
      ImePrezimeString: "",
    });
    setCurrentItem(0);
    setDate(new Date(new Date().setDate(1)));
    setMonthYear(new Date(new Date().setDate(1)));
    dispatch({
      type: "SET_DATA",
      state: initialDays,
    });
    history.push("/evidencija/pregled");
    setTimeout(() => {
      setReload(false);
    }, 1000);
  };
  return (
    <div>
      <FormContext {...methods}>
        <Route exact path="/evidencija/pregled">
          {reload ? (
            <Loading
              style={{ margin: "auto" }}
              description="Active loading indicator"
              withOverlay={true}
            />
          ) : (
            <WorkTimesheet
              currentUser={currentUser}
              days={days}
              dispatch={dispatch}
              date={date}
              setDate={setDate}
              monthYear={monthYear}
              setMonthYear={setMonthYear}
              currentItem={currentItem}
              setCurrentItem={setCurrentItem}
              disableForm={disableForm}
              setDisableForm={setDisableForm}
              createNew={createNew}
              reload={reload}
              history={history}
            />
          )}
        </Route>
        <Route exact path="/evidencija">
          <Grid>
            <Row lg={6} xs={12}>
              <Column>
                <CardTitle maxwidth="320px" variant="h4">
                  Evidencija radnog vremena
                </CardTitle>
              </Column>
            </Row>
          </Grid>
          <SihetericaList
            currentUser={currentUser}
            days={days}
            dispatch={dispatch}
            date={date}
            setDate={setDate}
            currentItem={currentItem}
            setMonthYear={setMonthYear}
            setCurrentItem={setCurrentItem}
            setDisableForm={setDisableForm}
            initialDays={initialDays}
            createNew={createNew}
          />
        </Route>
      </FormContext>
    </div>
  );
};

export default Evidencija;
