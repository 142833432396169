import React from "react";
import { Grid, Row, DataTableSkeleton, Column } from "carbon-components-react";
import { useQuery } from "react-query";
import { Modal } from "./Modal";
import { handlePostRequest } from "../../helpers/handle-response";
import ReviewTable from "../ClientStatementsReview/ReviewTable";
import { CardTitle } from "../styles";

const AdminStatementsReview = ({ currentUser, isClient }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);
  const [picked, setPicked] = React.useState(0);
  const [refresh, setRefresh] = React.useState(0);

  const { data, isLoading } = useQuery(
    ["allPlace", refresh],
    async () => {
      const {
        data,
      } = await handlePostRequest(
        `https://apiaccountant.plc.hr/api/p?m=PlaceAllGets`,
        [{ user: "igor" }]
      );
      const formatted = JSON.parse(data);

      const formatData = formatted.sort((a, b) => {
        return new Date(b.request.PayDate) - new Date(a.request.PayDate);
      });
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );
  const { data: organizations } = useQuery(
    "organizations",
    async () => {
      const {
        data: orgData,
      } = await handlePostRequest(
        "https://apiaccountant.plc.hr/api/p?m=GetOrganizations",
        [{ user: "igor" }]
      );
      const formattedData = JSON.parse(orgData);
      return data.map((item) => {
        return { ...item, OrgName: formattedData[item.OrganizationID].Naziv };
      });
    },
    {
      enabled: data,
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );

  return (
    <>
      <Grid>
        <Row lg={6} xs={12}>
          <Column>
            <CardTitle maxwidth="300px" variant="h4">
              Pregled zahtjeva za plaće
            </CardTitle>
          </Column>
        </Row>
      </Grid>
      <Grid>
        <Row>
          {!isLoading ? (
            <ReviewTable
              currentUser={currentUser}
              data={data}
              organizations={organizations}
              setPicked={setPicked}
              handleOpen={handleOpen}
            />
          ) : (
            <div style={{ width: "100%" }}>
              <DataTableSkeleton showToolbar={false} showHeader={false} />
            </div>
          )}
        </Row>
      </Grid>
      {!isLoading && data && picked ? (
        <Modal
          isClient={currentUser && isClient}
          currentUser={currentUser}
          open={open}
          handleOpen={handleOpen}
          picked={picked}
          allItems={data}
          organizations={organizations}
          setRefresh={setRefresh}
        ></Modal>
      ) : null}
    </>
  );
};

export default AdminStatementsReview;
