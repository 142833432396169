import React from "react";
import "./Salaries.scss";
import { Grid } from "@material-ui/core";
import Box from "../uiHelpers/Box";

const Salaries = ({ history }) => {
  return (
    <>
      <Grid justify="center" alignItems="center" container spacing={5}>
        <Grid item xs={10} lg={3}>
          <Box
            title={"Nova plaća"}
            handleClick={() => {
              history.push("/place-nova");
            }}
          ></Box>
        </Grid>
        <Grid item xs={10} lg={3}>
          <Box
            title={"Pregled"}
            handleClick={() => {
              history.push("/place-pregled");
            }}
          ></Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Salaries;
