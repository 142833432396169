import React from "react";
import { useQuery } from "react-query";
import {
  Grid,
  Row,
  Column,
  InlineNotification,
  InlineLoading,
} from "carbon-components-react";
import { Button } from "@material-ui/core";
import { CardTitle } from "../styles";
import { handlePostRequest } from "../../helpers/handle-response";
import { b64toBlob } from "../../functions/helpers";
import { saveAs } from "file-saver";
import AdminTable from "./AdminTable";

const AdminAddFiles = ({ currentUser }) => {
  const [status, setStatus] = React.useState({ msg: "", state: "" });
  const [requestPending, setRequestPending] = React.useState(false);
  const [filesToUpload, setFilesToUpload] = React.useState({
    pdf: [],
  });
  const [downloading, setDownloading] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  //const [flag, setFlag] = React.useState(0);

  const { data, isLoading } = useQuery(
    "driving",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        "https://apiaccountant.plc.hr/api/loco?m=LocoVoznjaGetsAll",
        [{ user: "igor" }]
      );
      const formatData = JSON.parse(data);

      return Object.keys(formatData)
        .map((key) => {
          return formatData[key];
        })
        .filter((item) => {
          if (new Date(item.request.DatumObracuna) > new Date(2000, 2, 2)) {
            return item;
          }
          return null;
        });
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );

  const sendFile = (data) => {
    console.log(data);
    console.log(filesToUpload, data.ID, filesToUpload[data.ID]);
    if (!filesToUpload[data.ID]) {
      setStatus({ msg: "Niste dodali datoteku", state: "error" });
    } else {
      setSending({ ...sending, [data.ID]: true });
      const Files = new FormData();
      for (let prop in filesToUpload) {
        Files.append("files", filesToUpload[prop]);
      }
      handlePostRequest(
        `https://www.api.accountant.hr/users/uploadFiles`,
        [Files],
        currentUser.token
      ).then((res) => {
        if (res.data === true) {
          setStatus({ msg: "", state: "" });
          const LocoRequest = {
            OrganizationNaziv: data.request.OrganizationNaziv,
            OrganizationOIB: data.request.OrganizationOIB,
            Godina: data.request.Godina,
            Mjesec: data.request.Mjesec,
            RadnikID: data.request.RadnikID,
            RadnikImePrezime: data.request.RadnikImePrezime,
            DatumObracuna: data.request.DatumObracuna,
            VoziloMarka: data.request.VoziloMarka,
            VoziloRegistracija: data.request.VoziloRegistracija,
            MailSentRequest: data.request.MailSentRequest,
            UpdateTime: data.request.UpdateTime,
            CreatedByUser: data.request.CreatedByUser,
          };
          const LocoResponse = {
            File1Ref: filesToUpload[data.ID].name.replace(/\s/g, ""),
            MailSentResponse: 0,
            ResponseTime: new Date(),
            ResponseUser: "",
          };
          const LocoAck = {
            File1Ack: "",
            AckTime: new Date(),
            AckUser: "",
          };
          // construct the LocoVoznja object for request
          const LocoVoznja = {
            ID: data.ID,
            OrganizationID: currentUser.orgID,
            request: LocoRequest,
            response: LocoResponse,
            ack: LocoAck,
          };
          //send data to server if files are uploaded
          handlePostRequest(
            "https://apiaccountant.plc.hr/api/loco?m=LocoVoznjaUpdateResponse",
            [{ user: "igor", lv: LocoVoznja }]
          )
            .then((res) => {
              if (res.data === '"True"') {
                setStatus({
                  msg: "Poslano!",
                  state: "success",
                });
                setSending({ ...sending, [data.ID]: false });

                setTimeout(() => {
                  //setFlag(Math.random());
                  //Invalidate queries
                  setFilesToUpload({ pdf: [] });
                  setRequestPending(false);
                  setStatus({ msg: "", state: "" });
                }, 2000);
              }
            })
            .catch((err) => console.log(err));
        }
      });
    }
  };

  const getPDF = (reqID) => {
    setDownloading({ ...downloading, [reqID]: true });
    const fetchFullData = async (reqID) => {
      const result = await handlePostRequest(
        `https://apiaccountant.plc.hr/api/loco?m=LocoVoznjaGetByIdFull`,
        [{ user: "igor", reqID: reqID }]
      );
      console.log(result);
      if (result.data !== "There is no row at position -1.") {
        const data = JSON.parse(result.data);
        console.log(data);
        handlePostRequest(
          "https://pdf-api.accountant.hr/create-pdf",
          [
            {
              name: "driving",
              data,
            },
          ],
          {
            responseType: "arraybuffer",
          }
        )
          .then((res) => {
            const blob = b64toBlob(res.data, "application/pdf");
            //`Loko_voznja_${data.request.Mjesec}_${data.request.Godina}_${data.request.RadnikImePrezime}_${data.request.OrganizationNaziv}.pdf`;
            const name =
              "Loko_voznja" +
              "_" +
              data.request.Mjesec +
              "/" +
              data.request.Godina +
              "_" +
              "_" +
              data.request.OrganizationNaziv.replace(" ", "_") +
              "_.pdf";
            saveAs(blob, name);
            console.log(name, res, blob);
            setDownloading({ ...downloading, [reqID]: false });
          })
          .catch((e) => alert(e));
      }
    };
    fetchFullData(reqID);
  };
  return (
    <Grid style={{ maxWidth: 1250 }}>
      <Row>
        <Column>
          <CardTitle variant="h4">Putni nalozi</CardTitle>
        </Column>
      </Row>
      <Row>
        <Column align="center">
          {status.msg.length > 1 ? (
            <InlineNotification
              style={{ maxWidth: 400 }}
              lowContrast
              hideCloseButton={false}
              iconDescription="describes the close button"
              kind={status.state}
              notificationType="inline"
              onCloseButtonClick={() => setStatus({ msg: "", state: "" })}
              role="alert"
              statusIconDescription="describes the status icon"
              title={status.msg}
            />
          ) : null}
        </Column>
      </Row>
      <Grid helpercolor="white">
        {!isLoading && data ? (
          <AdminTable
            data={data}
            downloading={downloading}
            currentUser={currentUser}
            setDownloading={setDownloading}
            sendFile={sendFile}
            filesToUpload={filesToUpload}
            setFilesToUpload={setFilesToUpload}
            sending={sending}
            getPDF={getPDF}
          />
        ) : null}
      </Grid>
      <Row align="center" style={{ marginTop: 50, display: "none" }}>
        <Column>
          <Button disabled={requestPending} variant="contained" type="submit">
            {requestPending ? (
              <InlineLoading
                description="Slanje..."
                iconDescription="Active loading indicator"
                status="active"
                successDelay={1500}
              />
            ) : (
              "Pošalji"
            )}
          </Button>
        </Column>
      </Row>
    </Grid>
  );
};

export default AdminAddFiles;
