import styled from "styled-components";
import {
  Column,
  Accordion,
  Grid,
  Row,
  InlineLoading,
} from "carbon-components-react";
import { TextField, Button, Select } from "@material-ui/core";

export const Error = styled.p`
  margin: 5px 0;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: rgb(244, 67, 54);
`;
export const Overlay = styled.div`
  background-color: #eeeeee;
  display: ${(props) => props.display || "block"};
  position: fixed;
  max-width: 100%;
  width: 100%;
  height: 100%;
  top: ${(props) => props.top || 0};
  left: 0;
  z-index: 90000000;
`;
export const CardTitle = styled.div`
  padding: 15px;
  margin-right: 15px;
  margin-bottom: 35px;
  font-size: 22px;
  border-radius: 3px;
  background-color: #999;
  width: 100%;
  max-width: ${(props) => props.maxwidth || "200px"};
  text-align: center;
  color: white !important;
  background: linear-gradient(60deg, #66bb6a, #43a047);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(76, 175, 80, 0.4);
`;

export const CustomCardItem = styled.div`
  cursor: pointer;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0,
    1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
  background-color: #7282ad;
  color: white;
  &:hover {
    position: relative;
    z-index: 1;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

export const CustomColumn = styled(Column)`
  background-color: #334d7b;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgb(177, 177, 177);
  border-radius: 5px;
  margin-bottom: 5px !important;
  padding: 15px;
  color: white;
  .MuiIconButton-label {
    svg {
      fill: white;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[name="Bruto1placa"],
  input[name="Prijevoz"] {
    font-family: "IBM Plex Mono", "Menlo", "DejaVu Sans Mono",
      "Bitstream Vera Sans Mono", Courier, monospace;
  }

  .MuiFormLabel-root.Mui-focused {
    color: white;
  }

  label {
    color: white;
  }
  input {
    color: white;
  }
  & > div {
    padding: 0 10px;
  }
  @media (max-width: 770px) {
    margin: ${(props) => props.mediaMargin};
    & > div > div {
      margin: 10px 0;
    }
  }
  .MuiInputLabel-shrink {
    transform: translate(12px, 10px) scale(0.75) !important;
    padding-left: 0 !important;
  }
  .bx--fieldset {
    margin-bottom: 5px;
  }
`;

export const TimeWrapper = styled.div`
  background-color: #334d7b;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgb(177, 177, 177);
  border-radius: 5px;
  margin-bottom: 5px !important;
  padding: 15px;
  color: white;
  .MuiIconButton-label {
    left: unset;
    svg {
      fill: white;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  select {
    color: white;
    option {
      color: black;
    }
  }
  .MuiInputBase-formControl {
    svg {
      fill: white;
    }
  }
  .MuiFormLabel-root.Mui-focused {
    color: white;
  }
  label {
    left: unset;
    color: white;
  }
  input {
    color: white;
  }
  & > div {
    padding: 0 10px;
  }
  @media (max-width: 770px) {
    margin: ${(props) => props.mediaMargin};
    & > div > div {
      margin: 10px 0;
    }
  }
  .MuiInputLabel-shrink {
    transform: translate(12px, 10px) scale(0.75) !important;
  }
  .bx--fieldset {
    margin-bottom: 5px;
  }
`;
export const CustomInput = styled(TextField)`
  margin-top: $spacing-03;
  margin: 5px !important;
  width: 100%;
  .MuiInputAdornment-positionEnd {
    .MuiTypography-colorTextSecondary {
      color: white;
    }
  }
  .MuiFormLabel-root.Mui-disabled {
    color: unset;
  }
`;
export const Box = styled.div`
  color: rgba(0, 0, 0, 0.87);
  padding: 20px;
  width: 100%;
  border: 0;
  display: flex;
  font-size: 0.875rem;
  min-width: 0;
  word-wrap: break-word;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  margin-top: 30px;
  border-radius: 6px;
  margin-bottom: 30px;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.14);
  }
`;
export const CustomAccordion = styled(Accordion)`
  .bx--accordion__content {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .bx--accordion__heading:hover::before,
  .bx--accordion__heading:focus::before {
    content: "";
    height: calc(100% + 1px);
  }
`;
export const CustomGrid = styled(Grid)`
  background-color: #334d7b;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgb(177, 177, 177);
  border-radius: 5px;
  margin-bottom: 5px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: ${(props) => (props.margin ? props.margin : "5px")};
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #4b6da3 inset !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: white;
  }
  .MuiFormHelperText-root {
    color: ${(props) => props.helpercolor};
  }
  label {
    color: white;
  }
  input {
    color: white;
  }

  & > div {
    padding: 0 10px;
  }
  .MuiFormLabel-root.Mui-disabled {
    color: white;
  }
  .MuiIconButton-label {
    svg {
      fill: white;
    }
  }
  @media (max-width: 1050px) {
    & > div > div {
      margin-top: 10px;
    }
  }
`;
export const CustomRow = styled(Row)`
  background-color: #334d7b;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgb(177, 177, 177);
  border-radius: 5px;
  margin-bottom: ${(props) =>
    props.marginbottom ? props.marginbottom : "5px !important"};
  padding: 15px;
  color: white;
  .MuiIconButton-label {
    svg {
      fill: white;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[name="Bruto1placa"],
  input[name="Prijevoz"] {
    font-family: "IBM Plex Mono", "Menlo", "DejaVu Sans Mono",
      "Bitstream Vera Sans Mono", Courier, monospace;
  }

  .MuiFormLabel-root.Mui-focused {
    color: white;
  }

  label {
    color: white !important;
  }
  input {
    color: white;
  }
  & > div {
    padding: 0 10px;
  }
  @media (max-width: 770px) {
    margin: ${(props) => props.mediaMargin};
    & > div > div {
      margin-top: 10px 0;
    }
  }
  .MuiInputLabel-shrink {
    transform: translate(12px, 10px) scale(0.75) !important;
    padding-left: 0 !important;
  }
  .bx--fieldset {
    margin-bottom: 5px;
  }
`;
export const DownloadButton = styled(Button)`
  width: 100%;
  height: 100%;
  span {
    width: 100%;
    max-width: 175px;
    display: inline-block;
    text-transform: none !important;
    font-size: 13px;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    word-break: break-word !important;
  }
  div[class="bx--loading bx--loading--small"] {
    svg {
      height: 2rem !important;
    }
  }
  div[class="bx--inline-loading__text"] {
    color: black;
  }
`;
export const CustomSelect = styled(Select)`
  width: 100%;
  & > div {
    color: ${(props) => props.clr};
  }
  svg {
    fill: ${(props) => props.clr};
  }
`;

export const CInlineLoading = styled(InlineLoading)`
  .bx--inline-loading__text {
    color: ${(props) => props.spancolor};
  }
`;
