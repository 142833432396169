import React from "react";
import { useQuery } from "react-query";
import fileDownload from "js-file-download";
import { cloneDeep } from "lodash";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  DataTable,
  FileUploaderButton,
  Grid,
  Row,
  Column,
  InlineNotification,
  InlineLoading,
} from "carbon-components-react";
import { DownloadButton } from "./styles";
import { formatDate, formatNumber } from "../../functions/helpers";
import {
  handlePostRequest,
  handleGetRequest,
  handleFileUpload,
} from "../../helpers/handle-response";
import { formatAsTime, getHolidaysDownload } from "../Evidencija/helperFunc";
import { sumTime, timeFormat, getUTC } from "../Evidencija/timeFunc";
import { mjeseci, initialDays } from "../Evidencija/constants/index";
import { b64toBlob } from "../../functions/helpers";
import styled from "styled-components";

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
} = DataTable;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  btnWrapper: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonProgress: {
    color: "blue",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  "@media(max-width: 959px)": {
    root: {
      marginTop: 40,
    },
  },
});
function sumAllTimes(days) {
  const values = [];
  days.map((item) => {
    for (let key in item) {
      if (
        key !== "TerenskiRad" &&
        key !== "SatiPripravnosti" &&
        key !== "id" &&
        key !== "ID" &&
        key !== "SihtericaID" &&
        key !== "PocetakRada" &&
        key !== "ZavrsetakRada" &&
        key !== "SatiZastojFrom" &&
        key !== "SatiZastojTill" &&
        key !== "CreatedByIP" &&
        key !== "CreateTime" &&
        key !== "CreatedByUser" &&
        key !== "UkupnoDnevnoRV" &&
        key !== "alreadyAdded"
      ) {
        if (item[key] !== null) {
          const split = item[key].split(":");
          values.push({ hours: Number(split[0]), minutes: Number(split[1]) });
        }
      }
    }
    return null;
  });
  const sum = sumTime(values);
  return timeFormat(sum.hours, sum.minutes);
}
const getPDF = (
  days,
  values,
  totalSum,
  downloading,
  setDownloading,
  holidaysTotal,
  date,
  ID
) => {
  setDownloading({
    ...downloading,
    [ID]: true,
  });
  const daysCopy = cloneDeep(days);
  const filteredDays = daysCopy.map((item) => {
    for (let key in item) {
      if (
        key === "PocetakRada" ||
        key === "ZavrsetakRada" ||
        key === "SatiZastojaFrom" ||
        key === "SatiZastojaTill"
      ) {
        if (item[key]) {
          if (getUTC(item[key], date).getFullYear() === 2000) {
            item[key] = null;
          } else {
            item[key] = getUTC(item[key], date);
          }
        }
      }
    }
    return item;
  });
  handlePostRequest(`https://pdf-api.accountant.hr/create-pdf`, [
    {
      name: "workTimesheet",
      days: filteredDays,
      values,
      totalSum,
      holidaysTotal,
    },
  ])
    .then((response) => {
      setDownloading({ ...downloading, [ID]: false });
      fileDownload(
        b64toBlob(response.data),
        `${values.ImePrezimeString}_${
          mjeseci[values.Mjesec]
        }_${values.Godina.getFullYear()}.pdf`
      );
    })
    .catch((err) => console.log(err));
};
const getItems = (ID, downloading, setDownloading, downloadID) => {
  const getData = async () => {
    const result = await handlePostRequest(
      `https://apiaccountant.plc.hr/api/s?m=SihtericaGetByIdFull`,
      [{ user: "igor", reqID: Number(ID) }]
    );
    console.log(result.data);
    const parsed = JSON.parse(result.data);
    const daysCopy = cloneDeep(initialDays);

    parsed.items.map((item) => {
      const obj = {};
      const dayID = new Date(item.PocetakRada).getDate();
      obj.id = String(dayID);
      Object.keys(item).map((key) => {
        if (item[key] !== null && item[key].hasOwnProperty("h")) {
          obj[key] = formatAsTime(item[key]);
        } else if (
          typeof item[key] === typeof "" &&
          item[key].includes("-") &&
          !item[key].includes("2000")
        ) {
          obj[key] = new Date(item[key]);
        } else if (item[key] === null) {
          obj[key] = "00:00";
        } else {
          obj[key] = item[key];
        }
        return null;
      });

      daysCopy[dayID - 1] = obj;
      return null;
    });
    const date = new Date(parsed.Godina, parsed.Mjesec - 1, 5);
    const values = {
      Mjesec: parsed.Mjesec,
      Godina: date,
      ImePrezimeString: parsed.RadnikImePrezime,
      Tvrtka: parsed.OrganizationNaziv,
    };
    const totalSum = sumAllTimes(daysCopy);
    return { parsed, daysCopy, values, totalSum, date };
    //days, values, totalSum, holidaysTotal
  };
  getData().then(({ parsed, daysCopy, values, totalSum, date }) => {
    return getHolidaysDownload(date).then((data) => {
      getPDF(
        daysCopy,
        values,
        totalSum,
        downloading,
        setDownloading,
        data,
        date,
        downloadID
      );
    });
  });
};
const DialogTitleCustom = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

export const Modal = ({
  open,
  handleOpen,
  picked,
  allItems,
  organizations,
  currentUser,
  setRefresh,
}) => {
  const [filesToUpload, setFilesToUpload] = React.useState({
    0: [],
    1: [],
    2: [],
  });
  const [downloading, setDownloading] = React.useState();
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const [status, setStatus] = React.useState({ msg: "", state: "" });
  const [sihtericaID, setSihtericaID] = React.useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const downloadFiles = React.useCallback(
    (url, name, num, id) => {
      setDownloading({ ...downloading, [id]: true });
      handleGetRequest(url, currentUser.token, "blob")
        .then((res) => {
          fileDownload(res.data, name);
          setDownloading({ ...downloading, [id]: false });
        })
        .catch((err) => console.log(err));
    },
    [currentUser, downloading, setDownloading]
  );
  const { data, isLoading } = useQuery(
    "checkout",
    async () => {
      console.log(picked);
      const {
        data,
      } = await handlePostRequest(
        `https://apiaccountant.plc.hr/api/p?m=PlaceItemsGets`,
        [{ user: "igor", reqID: picked.ID }]
      );
      const formatted = JSON.parse(data);
      console.log(picked, formatted);
      const formatData = formatted.map((item) => {
        return {
          id: String(item.ID),
          Name: `${item.FirstName} ${item.LastName}`,
          Brutto: formatNumber(item.Brutto),
          Bonus: formatNumber(item.Bonus),
          Sihterica:
            item.Sihterica /* (
            <DownloadButton
              variant="contained"
              onClick={() =>
                downloadFiles(
                  `${
                    process.env.REACT_APP_HOST_URL
                  }:2099/private/${item.Sihterica.replace(/\s/g, "")}`,
                  item.Sihterica
                )
              }
            >
              {item.Sihterica.replace(/\s/g, "")}
            </DownloadButton>
          ), */,
        };
      });
      const ids = formatted
        .filter((item) => {
          if (isNaN(item.Sihterica)) {
            return false;
          } else {
            return true;
          }
        })
        .map((item) => {
          return Number(item.Sihterica);
        });
      setSihtericaID(ids);
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );

  const submit = () => {
    if (
      filesToUpload[0].length < 1 ||
      filesToUpload[1].length < 1 ||
      filesToUpload[2].length < 1
    ) {
      setStatus({
        msg: "Niste odabrali datoteke za prijenos",
        state: "error",
      });
    } else {
      setUploadLoading(true);
      const Files = new FormData();
      for (let prop in filesToUpload) {
        Files.append("files", filesToUpload[prop]);
      }
      const Response = {
        File1Ref: filesToUpload[0].name.replace(/\s/g, ""),
        File2Ref: filesToUpload[1].name.replace(/\s/g, ""),
        File3Ref: filesToUpload[2].name.replace(/\s/g, ""),
        MailSentResponse: 0,
        UpdateResponse: new Date(),
        UserResponse: "",
      };

      handleFileUpload(
        `https://www.api.accountant.hr/users/uploadFiles`,
        Files,
        currentUser.token
      ).then((res) => {
        if (res.data === true) {
          //send data to server if files are uploaded

          setUploadLoading(false);
          handlePostRequest(
            "https://apiaccountant.plc.hr/api/p?m=PlaceUpdateNewResponse",
            [
              {
                user: "igor",
                reqID: Number(picked.ID),
                response: JSON.stringify(Response),
                sitericaID: sihtericaID,
              },
            ]
          )
            .then((res) => {
              if (res.data === "True") {
                setStatus({ msg: "Datoteke prenesene!", state: "success" });
                setTimeout(() => {
                  handleOpen(false);
                  setRefresh(Math.random());
                  setStatus({ msg: "", state: "" });
                }, 1500);
              }
            })
            .catch((err) => console.log(err));
        }
      });
    }
  };
  return (
    <>
      {!isLoading ? (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleOpen}
          maxWidth="lg"
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitleCustom id="responsive-dialog-title" onClose={handleOpen}>
            {picked.OrgName}
          </DialogTitleCustom>
          <DialogContent>
            <h5>
              Obračun plaće za mjesec: {picked.request.PayMonth}/
              {picked.request.PayYear}
            </h5>
            <h5>Datum isplate: {formatDate(picked.request.PayDate)}</h5>
            <br />
            <DataTable
              filterRows={function noRefCheck() {}}
              headers={[
                {
                  header: "Name",
                  key: "Name",
                },
                {
                  header: "Brutto",
                  key: "Brutto",
                },
                {
                  header: "Bonus",
                  key: "Bonus",
                },
                {
                  header: "Šihterica",
                  key: "Sihterica",
                },
              ]}
              isSortable
              locale="en"
              rows={data}
              render={({
                rows,
                headers,
                getHeaderProps,
                getTableProps,
                getRowProps,
              }) => (
                <TableContainer style={{ minWidth: "unset" }}>
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <TableHeader {...getHeaderProps({ header })}>
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id} {...getRowProps({ row })}>
                          {row.cells.map((cell) => {
                            if (cell.id.includes("Sihterica")) {
                              return (
                                <TableCell key={cell.id}>
                                  {downloading && downloading[cell.id] ? (
                                    <CInlineLoading
                                      style={{ width: "unset" }}
                                      description={"Preuzimanje..."}
                                      spancolor="black"
                                      iconDescription="Active loading indicator"
                                      status={"active"}
                                      successDelay={1500}
                                    />
                                  ) : (
                                    <DownloadButton
                                      variant="contained"
                                      onClick={() =>
                                        isNaN(cell.value)
                                          ? downloadFiles(
                                              `https://www.api.accountant.hr/private/${cell.value.replace(
                                                /\s/g,
                                                ""
                                              )}`,
                                              cell.value,
                                              "",
                                              cell.id
                                            )
                                          : getItems(
                                              cell.value,
                                              downloading,
                                              setDownloading,
                                              cell.id
                                            )
                                      }
                                    >
                                      {isNaN(cell.value)
                                        ? cell.value.replace(/\s/g, "")
                                        : "Preuzmi šihtericu"}
                                    </DownloadButton>
                                  )}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={cell.id}>
                                  {cell.value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            />
            <Grid style={{ marginTop: 20, maxWidth: 750 }}>
              <Row>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  <FileUploaderButton
                    labelText="Isplatne liste"
                    name={"file1"}
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                    style={{ maxWidth: 150, backgroundColor: "#405E87" }}
                    onChange={(e) => {
                      e.persist();
                      setFilesToUpload({
                        ...filesToUpload,
                        0: e.target.files[0],
                      });
                    }}
                  />
                </Column>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  <FileUploaderButton
                    labelText="Obrasci IP1"
                    name={"file2"}
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                    style={{ maxWidth: 150, backgroundColor: "#405E87" }}
                    onChange={(e) => {
                      e.persist();
                      setFilesToUpload({
                        ...filesToUpload,
                        1: e.target.files[0],
                      });
                    }}
                  />
                </Column>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  <FileUploaderButton
                    labelText="Nalozi za plaćanje"
                    name={"file3"}
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                    style={{ maxWidth: 150, backgroundColor: "#405E87" }}
                    onChange={(e) => {
                      e.persist();
                      setFilesToUpload({
                        ...filesToUpload,
                        2: e.target.files[0],
                      });
                    }}
                  />
                </Column>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  {uploadLoading && (
                    <CircularProgress
                      size={36}
                      className={styles.buttonProgress}
                    />
                  )}
                </Column>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  <DownloadButton
                    color="primary"
                    variant="contained"
                    onClick={() => submit()}
                    disabled={uploadLoading}
                  >
                    Submit
                  </DownloadButton>
                </Column>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                  lg={12}
                >
                  {status.msg.length > 1 ? (
                    <InlineNotification
                      style={{ maxWidth: 400 }}
                      lowContrast
                      hideCloseButton={false}
                      iconDescription="describes the close button"
                      kind={status.state}
                      notificationType="inline"
                      onCloseButtonClick={() =>
                        setStatus({ msg: "", state: "" })
                      }
                      role="alert"
                      statusIconDescription="describes the status icon"
                      title={status.msg}
                    />
                  ) : null}
                </Column>
              </Row>
            </Grid>
          </DialogContent>
          <DialogActions>
            <DownloadButton
              variant="contained"
              onClick={handleOpen}
              color="primary"
            >
              Zatvori
            </DownloadButton>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};
const CInlineLoading = styled(InlineLoading)`
  margin: auto;
  .bx--inline-loading__text {
    font-size: 20px;
    color: ${(props) => props.spancolor};
  }
`;
