import React from "react";
import "./Home.scss";
import { Grid, Row, Column as CColumn } from "carbon-components-react";
import {
  PlaceButtons,
  PrijavaButtons,
  OdjavaButtons,
  PDV,
  OtherInquiries,
  DrivingButtons,
  TerenskiButtons,
} from "./Buttons";
import Box from "../uiHelpers/Box";
import Box2 from "../uiHelpers/Box2";
import styled from "styled-components";
/* const getFile = url => {
  axios
    .get(url, {
      headers: {
        Authorization:
          "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXNlcm5hbWUiOiJkb21pbmlrIiwiZW1haWwiOiJkb21pbmlrQHBhcnBhci5ociIsInBhc3N3b3JkIjoiJDJiJDEyJG9COHBnWTl6aFJCMmkwYXJoRFlKRi4wQTVPb0RYTHAzTWhUTXh0ZEF6bXcvNlJtc1k3N3ZLIiwiZ2VuZGVyIjoibWFsZSIsImNvbXBhbnlOYW1lIjoiVGVzdCIsIm9yZ0lEIjoxMjM0LCJuYW1lIjoiRG9taW5payIsImxhc3ROYW1lIjoiVmxhc2ljIiwiaXNDbGllbnQiOmZhbHNlLCJoYXNQbGFjZSI6dHJ1ZSwiaGFzUHJpamF2YSI6dHJ1ZSwiY3JlYXRlZCI6IjIwMjAtMDMtMTNUMTA6MTQ6NTEuMDAwWiIsImlhdCI6MTU4NDA5NDkwMywiZXhwIjoxNTg0MDk2MzQzfQ.GqwTkQ3rR5VDjxSf8jS-KTJbo1BNfV6XkHGbjMamtb4"
      }
    })
    .then(res => {
      console.log(res.data);
      const blob = new Blob([res.data], { type: "text/plain" });
      saveAs(blob, `test.txt`);
    })
    .catch(err => console.log(err));
};
 */

//ADD OTHER BUTTONS
const Home = ({ history, currentUser, size }) => {
  return (
    <>
      <Grid>
        {currentUser && currentUser.CompanyOIB !== 1886578177 ? (
          <>
            <CustomRow justify="center">
              <Column lg={5} md={4}>
                <Box2
                  title={"Plaće"}
                  buttons={
                    <PlaceButtons
                      size={size}
                      currentUser={currentUser}
                      history={history}
                    ></PlaceButtons>
                  }
                />
              </Column>
              <Column lg={5} md={4}>
                <Box2
                  title={"Prijava zaposlenika"}
                  buttons={
                    <PrijavaButtons
                      size={size}
                      currentUser={currentUser}
                      history={history}
                    ></PrijavaButtons>
                  }
                />
              </Column>
            </CustomRow>
            <CustomRow>
              <Column lg={5} md={4}>
                <Box2
                  title={"Odjava zaposlenika"}
                  buttons={
                    <OdjavaButtons
                      size={size}
                      currentUser={currentUser}
                      history={history}
                    ></OdjavaButtons>
                  }
                />
              </Column>
              <Column lg={5} md={4}>
                <Box2
                  title={"PDV / Davanja"}
                  buttons={
                    <PDV
                      history={history}
                      size={size}
                      currentUser={currentUser}
                    ></PDV>
                  }
                />
              </Column>
            </CustomRow>
            <CustomRow>
              <Column lg={5} md={4}>
                <Box2
                  title={"Putni nalozi"}
                  buttons={
                    <DrivingButtons
                      size={size}
                      currentUser={currentUser}
                      history={history}
                    ></DrivingButtons>
                  }
                />
              </Column>
              <Column lg={5} md={4}>
                <Box2
                  title={"Ostali upiti"}
                  buttons={
                    <OtherInquiries
                      size={size}
                      history={history}
                      currentUser={currentUser}
                    ></OtherInquiries>
                  }
                />
              </Column>
            </CustomRow>
          </>
        ) : (
          <>
            <CustomRow justify="center">
              <Column lg={5} md={4}>
                <Box2
                  title={"Terenski Dodatak"}
                  buttons={
                    <TerenskiButtons
                      size={size}
                      currentUser={currentUser}
                      history={history}
                    ></TerenskiButtons>
                  }
                />
              </Column>
            </CustomRow>
          </>
        )}
      </Grid>
    </>
  );
};

export default Home;

const CustomRow = styled(Row)`
  justify-content: center;
`;
const Column = styled(CColumn)`
  margin-top: 30px;
  margin-bottom: 30px;
  padding-right: 2rem;
  padding-left: 2rem;
`;
