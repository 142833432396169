import React from "react";
import { useQuery, ReactQueryConfigProvider } from "react-query";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  FileUploaderButton,
  Grid,
  Row,
  Column,
  InlineNotification,
} from "carbon-components-react";
import { DownloadButton } from "./styles";
import { CustomColumn, CustomInput } from "../styles";
import { formatDate, formatValue } from "../../functions/helpers";
import { Fields } from "../../helpers/variables";
import { handlePostRequest } from "../../helpers/handle-response";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    top: 30,
  },
  btnWrapper: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonProgress: {
    color: "blue",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  "@media(max-width: 959px)": {
    root: {
      marginTop: 40,
    },
  },
});
const DialogTitleCustom = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

export const Modal = ({
  open,
  handleOpen,
  picked,
  currentUser,
  invalidate,
}) => {
  //const [data, setData] = React.useState([]);

  const [filesToUpload, setFilesToUpload] = React.useState({
    0: [],
    1: [],
    2: [],
  });
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const [status, setStatus] = React.useState({ msg: "", state: "" });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const queryConfig = { queries: { refetchOnWindowFocus: false } };

  const { data, isFetching } = useQuery(
    "radnikQuery",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        "https://apiaccountant.plc.hr/api/r?m=RadniciGetById",
        [{ user: "igor", reqID: picked.ID }]
      );
      const formatted = JSON.parse(data);

      return formatted.info;
    },

    {
      enabled: picked,
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            //authenticationService.logout();
          }
          return true;
        }
      },
    }
  );
  const submit = () => {
    if (
      filesToUpload[0].length < 1 ||
      filesToUpload[1].length < 1 ||
      filesToUpload[2].length < 1
    ) {
      setStatus({
        msg: "Niste odabrali datoteke za prijenos",
        state: "error",
      });
    } else {
      setUploadLoading(true);
      const Files = new FormData();
      for (let prop in filesToUpload) {
        Files.append("files", filesToUpload[prop]);
      }
      const Response = {
        File1Ref: filesToUpload[0].name.replace(/\s/g, ""),
        File2Ref: filesToUpload[1].name.replace(/\s/g, ""),
        File3Ref: filesToUpload[2].name.replace(/\s/g, ""),
        MailSentResponse: 0,
        UpdateResponse: new Date(),
        UserResponse: "",
      };

      handlePostRequest(
        `https://www.api.accountant.hr/users/uploadFiles`,
        [Files],
        currentUser.token
      ).then((res) => {
        if (res.data === true) {
          //send data to server if files are uploaded

          setUploadLoading(false);
          handlePostRequest(
            "https://apiaccountant.plc.hr/api/r?m=RadniciUpdateResponse",
            [
              {
                user: "igor",
                reqID: Number(picked.ID),
                response: JSON.stringify(Response),
              },
            ]
          )
            .then((res) => {
              if (res.data === "True") {
                setStatus({ msg: "Datoteke prenesene!", state: "success" });
                setTimeout(() => {
                  setStatus({ msg: "", state: "" });
                  handleOpen(false);
                  invalidate("RadniciAllGets");
                  // GET DATA AGAIN
                }, 2500);
              }
            })
            .catch((err) => console.log(err));
        }
      });
    }
  };

  return (
    <>
      {!isFetching && data ? (
        <ReactQueryConfigProvider config={queryConfig}>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleOpen}
            maxWidth="lg"
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitleCustom
              id="responsive-dialog-title"
              onClose={handleOpen}
            >
              {picked.OrgName}
            </DialogTitleCustom>
            <DialogContent>
              <h5>Zahtjev za prijavu radnika: {picked.Radnik}</h5>
              <h5>
                Datum početka rada: {formatDate(picked.info.DatumPocetkaRada)}
              </h5>
              <br />

              <Grid style={{ marginTop: 20, maxWidth: 750 }}>
                <Row style={{ justifyContent: "space-around" }}>
                  {/* First Dataset starts here */}
                  <CustomColumn md={12} lg={12} sm={12}>
                    <h4>Osnovni podaci o radniku</h4>
                    <Grid>
                      <Row>
                        {Object.keys(data).map((key, index) => {
                          if (
                            key === "RadnikID" ||
                            key === "UpdateTime" ||
                            key === "UpdateUser"
                          ) {
                          } else {
                            if (key === "IBANzasticeni") {
                              return (
                                <React.Fragment key={index}>
                                  <Column sm={6} lg={6}>
                                    <CustomInput
                                      autoComplete="off"
                                      label={Fields[key]}
                                      disabled
                                      type="text"
                                      variant="filled"
                                      defaultValue={formatValue(key, data[key])}
                                    />
                                  </Column>
                                  <Column sm={6} lg={12}>
                                    <br />
                                    <h4>Podaci o radnom mjestu</h4>
                                  </Column>
                                </React.Fragment>
                              );
                            } else {
                              return (
                                <Column sm={6} lg={6} key={index}>
                                  <CustomInput
                                    autoComplete="off"
                                    label={Fields[key]}
                                    disabled
                                    type="text"
                                    variant="filled"
                                    defaultValue={formatValue(key, data[key])}
                                  />
                                </Column>
                              );
                            }
                          }
                          return null;
                        })}
                      </Row>
                    </Grid>
                  </CustomColumn>
                </Row>

                <Row>
                  <Column
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px 0",
                    }}
                    lg={12}
                  >
                    <FileUploaderButton
                      labelText="Privremena prijava MO"
                      name={"file1"}
                      className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                      style={{ maxWidth: 190, backgroundColor: "#405E87" }}
                      onChange={(e) => {
                        e.persist();
                        setFilesToUpload({
                          ...filesToUpload,
                          0: e.target.files[0],
                        });
                      }}
                    />
                  </Column>
                  <Column
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px 0",
                    }}
                    lg={12}
                  >
                    <FileUploaderButton
                      labelText="Konačna prijava MO"
                      name={"file2"}
                      className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                      style={{ maxWidth: 190, backgroundColor: "#405E87" }}
                      onChange={(e) => {
                        e.persist();
                        setFilesToUpload({
                          ...filesToUpload,
                          1: e.target.files[0],
                        });
                      }}
                    />
                  </Column>
                  <Column
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px 0",
                    }}
                    lg={12}
                  >
                    <FileUploaderButton
                      labelText="Konačna prijava ZO"
                      name={"file3"}
                      className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary customStyle"
                      style={{ maxWidth: 190, backgroundColor: "#405E87" }}
                      onChange={(e) => {
                        e.persist();
                        setFilesToUpload({
                          ...filesToUpload,
                          2: e.target.files[0],
                        });
                      }}
                    />
                  </Column>
                  <Column
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px 0",
                    }}
                    lg={12}
                  >
                    {uploadLoading && (
                      <CircularProgress
                        size={36}
                        className={styles.buttonProgress}
                      />
                    )}
                    {status.msg.length > 1 ? (
                      <InlineNotification
                        style={{ maxWidth: 400 }}
                        lowContrast
                        hideCloseButton={false}
                        iconDescription="Zatvorite poruku"
                        kind={status.state}
                        notificationType="inline"
                        onCloseButtonClick={() =>
                          setStatus({ msg: "", state: "" })
                        }
                        role="alert"
                        statusIconDescription="Niste dodali sve datoteke"
                        title={status.msg}
                      />
                    ) : null}
                  </Column>
                  <Column
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px 0",
                    }}
                    lg={12}
                  >
                    <DownloadButton
                      color="primary"
                      variant="contained"
                      onClick={() => submit()}
                      disabled={uploadLoading}
                    >
                      Submit
                    </DownloadButton>
                  </Column>
                </Row>
              </Grid>
            </DialogContent>
            <DialogActions>
              <DownloadButton
                variant="contained"
                onClick={handleOpen}
                color="primary"
              >
                Zatvori
              </DownloadButton>
            </DialogActions>
          </Dialog>
        </ReactQueryConfigProvider>
      ) : null}
    </>
  );
};
